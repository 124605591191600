import { FC, useEffect, useState } from "react";
import { styled } from "styletron-react";

import { Modal, ModalBody } from "./components/Modal";
import { DownloadCSV } from "./components/DownloadCSV";
import Table from "./components/Table";
import { useLogin } from "./useLogin";
import { getTaxonomyProducts } from "api/taxonomies";

interface TaxonomyProductsProps {
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  taxonomy: string;
}

const Header = styled(`h3`, {
  color: `#015597`,
  padding: `20px 10px 10px 10px`,
  margin: `20px`,
});

export const TaxonomyProducts: FC<TaxonomyProductsProps> = ({ setModalState, taxonomy }) => {
  const credential = useLogin();
  const [products, setProducts] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await getTaxonomyProducts(credential, taxonomy);
        response.products.forEach((product: Record<string, any>) => {
          if (!product.Materials) {
            return;
          }
          const materials = product.Materials as Record<string, any>[];
          product.Materials = materials.map((material) => `${material.Name}/${material.Percentage}`).join(";");
        });

        setProducts(response.products);
      } catch (e) {
        console.error(e);
      }
    };
    getProducts();
  }, [credential, taxonomy]);

  const formatCell = (key: string, value: any) => {
    switch (key) {
      case "URL":
        return value ? (
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          "N/A"
        );
      default:
        return value ? String(value) : "N/A";
    }
  };

  return (
    <Modal
      onClose={() => setModalState(false)}
      closeable
      isOpen={true}
      animate
      autoFocus
      size={"responsive"}
      role={"dialog"}
    >
      <Header>{taxonomy}</Header>
      {products.length !== 0 && (
        <DownloadCSV data={products} fileName={`products_${taxonomy.replaceAll(" > ", "|")}`} displayType="button" />
      )}
      <ModalBody>
        <Table
          data={products}
          omitColumns={["ID", "Taxonomy"]}
          cellFormatter={formatCell}
          emptyMessage="No products"
          filterColumn="Name"
        />
      </ModalBody>
    </Modal>
  );
};
