import type { MaterialCompositionResponse, MaterialCompositionItem } from "api/cms/types";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLogin } from "useLogin";
import { getMaterialComposition } from "api/cms/material_compositions";
import { Card } from "components/Card";
import {
  DetailsContainer,
  DetailField,
  DetailLabel,
  DetailValue,
  DetailValueLink,
  DetailValueList,
} from "../cms.styled";
import { HomeButton } from "components/HomeButton";
import { camelCaseToTitleCase } from "stringFormatters";
import { nullToNotAvailable } from "../cms.utils";

export const MaterialCompositionDetails = () => {
  const credential = useLogin();
  const [materialComposition, setMaterialComposition] = useState<MaterialCompositionResponse>();
  const navigate = useNavigate();
  const { materialCompositionID } = useParams();

  const getValueElement = (key: string, value: any) => {
    if (key === "taxonomyID") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValueLink
          onClick={() => {
            navigate(`/cms/taxonomies/${value}`);
          }}
        >
          {value}
        </DetailValueLink>
      );
    } else if (key === "items") {
      return (
        <DetailValueList>
          {value?.map((item: MaterialCompositionItem) => {
            return (
              <DetailValue key={item.material.name}>
                {item.material.name} - {item.percentage}%
              </DetailValue>
            );
          })}
        </DetailValueList>
      );
    }
    return <DetailValue>{nullToNotAvailable(value)}</DetailValue>;
  };

  useEffect(() => {
    const main = async () => {
      if (!materialCompositionID) {
        return;
      }
      try {
        const response = await getMaterialComposition(credential, materialCompositionID);
        setMaterialComposition(response);
      } catch (error) {
        console.error(error);
      }
    };
    main();
  }, [credential, materialCompositionID]);

  return (
    <Card>
      <HomeButton text="CMS Home" navigateTo="/cms" />
      <DetailsContainer>
        <h1>Material Composition Details</h1>
        {materialComposition &&
          Object.entries(materialComposition).map(([label, value]) => {
            return (
              <DetailField key={label}>
                <DetailLabel>{camelCaseToTitleCase(label)}:</DetailLabel>
                {getValueElement(label, value)}
              </DetailField>
            );
          })}
      </DetailsContainer>
    </Card>
  );
};
