import type {
  Organization,
  Users,
  CreateUserRequest,
  RegisterOrganizationRequest,
  RegisterOrganizationResponse,
} from "./types";

import { makeCopilotRequest } from "./common";
import { getConfig } from "config";

export const getOrganization = async (credentials: string, organizationId: string): Promise<Organization> => {
  const response = await makeCopilotRequest(`organization/${organizationId}`, "GET", credentials);
  return response as Organization;
};

export const getOrganizations = async (credentials: string): Promise<Organization[]> => {
  const response = await makeCopilotRequest("GetOrganizationsV2", "GET", credentials);
  return response as Organization[];
};

export const getOrganizationsProductScope = async (credentials: string): Promise<Organization[]> => {
  const response = await makeCopilotRequest("GetOrganizationsProductScopeV2", "GET", credentials);
  return response as Organization[];
};

export const getOrganizationUsers = async (credentials: string, organizationId: string): Promise<Users> => {
  const response = await makeCopilotRequest(`organization/${organizationId}/users`, "GET", credentials);
  return response as Users;
};

export const deleteOrganizationUser = async (credentials: string, organizationId: string, userId: string) => {
  return await makeCopilotRequest(`organization/${organizationId}/user/${userId}`, "DELETE", credentials);
};

export const addOrganizationUser = async (
  credentials: string,
  organizationId: string,
  data: CreateUserRequest
): Promise<any | undefined> => {
  return await makeCopilotRequest(`organization/${organizationId}/users`, "POST", credentials, data);
};

export const getDashboardURL = async (apiKey: string): Promise<string> => {
  const { API_URL } = getConfig();
  const response = await fetch(`${API_URL}/GetDashboardUrl`, {
    method: "GET",
    headers: {
      "X-Api-Key": apiKey,
    },
  });
  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }
  const data = await response.json();
  return data.dashboardUrl;
};

export const registerOrganization = async (
  credentials: string,
  formData: RegisterOrganizationRequest
): Promise<RegisterOrganizationResponse> => {
  const response = await makeCopilotRequest("RegisterOrganization", "PUT", credentials, formData);
  return response as RegisterOrganizationResponse;
};

export const getOrganizationList = async (credentials: string): Promise<Organization[]> => {
  const response = await makeCopilotRequest("GetOrganizationsList?platform=climateclick-copilot", "GET", credentials);
  return response as Organization[];
};
