import type { FootprintResponse } from "api/cms/types";
import type { Product } from "api/cms/types";

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogin } from "useLogin";
import { getFootprint } from "api/cms/footprint";
import { Card } from "components/Card";
import { DetailsContainer, DetailField, DetailLabel, DetailValue, DetailValueLink } from "../cms.styled";
import { HomeButton } from "components/HomeButton";
import { camelCaseToTitleCase } from "stringFormatters";
import { nullToNotAvailable } from "../cms.utils";

export const ProductDetails = () => {
  const credential = useLogin();
  const [product, setProduct] = useState<Product>();
  const [footprint, setFootprint] = useState<FootprintResponse>();
  const location = useLocation();
  const navigate = useNavigate();

  const getValueElement = (key: string, value: any) => {
    if (key === "taxonomyID") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValueLink
          onClick={() => {
            navigate(`/cms/taxonomies/${value}`);
          }}
        >
          {value}
        </DetailValueLink>
      );
    }

    if (key === "importDate" || key === "approvedAt") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return <DetailValue>{new Date(value).toLocaleString()}</DetailValue>;
    }

    if (key === "taxonomy") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValue>
          <ul>
            {Object.entries(value).map(([k, v]) => {
              if (k === "id") {
                return (
                  <li key={k}>
                    <DetailValueLink
                      onClick={() => {
                        navigate(`/cms/taxonomies/${v}`);
                      }}
                    >
                      {v}
                    </DetailValueLink>
                  </li>
                );
              } else {
                return (
                  <li key={k}>
                    <strong>
                      {nullToNotAvailable(k)} {": "}
                    </strong>
                    {nullToNotAvailable(v)}
                  </li>
                );
              }
            })}
          </ul>
        </DetailValue>
      );
    }

    if (key === "validatedProduct") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValue>
          <ul>
            {Object.entries(value).map(([k, v]) => {
              return (
                <li key={k}>
                  <strong>
                    {nullToNotAvailable(k)} {": "}
                  </strong>
                  {getValueElement(k, v)}
                </li>
              );
            })}
          </ul>
        </DetailValue>
      );
    }

    if (key === "category") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      if (value > 0) {
        return nullToNotAvailable(value);
      }
      return (
        <DetailValue>
          <ul>
            {Object.entries(value).map(([k, v]) => {
              return (
                <li key={k}>
                  <strong>
                    {nullToNotAvailable(k)} {": "}
                  </strong>
                  {getValueElement(k, v)}
                </li>
              );
            })}
          </ul>
        </DetailValue>
      );
    }

    if (key === "materialComposition") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValue>
          <ul>
            {Object.entries(value).map(([k, v]) => {
              return (
                <li key={k}>
                  <strong>
                    {nullToNotAvailable(k)} {": "}
                  </strong>
                  {getValueElement(k, v)}
                </li>
              );
            })}
          </ul>
        </DetailValue>
      );
    }

    if (key === "items") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValue>
          <ul>
            {Object.values(value).map((v) => {
              return getValueElement("material", v);
            })}
          </ul>
        </DetailValue>
      );
    }

    if (key === "material") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }

      return (
        <DetailValue>
          <ul>
            {Object.entries(value).map(([k, v]) => {
              return (
                <li key={k}>
                  <strong>
                    {nullToNotAvailable(k)} {": "}
                  </strong>
                  {getValueElement(k, v)}
                </li>
              );
            })}
          </ul>
        </DetailValue>
      );
    }

    if (key === "emissionFactors") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }

      return (
        <DetailValue>
          <ul>
            {Object.entries(value).map(([k, v]) => {
              return (
                <li key={k}>
                  <strong>
                    {nullToNotAvailable(k)} {": "}
                  </strong>
                  {getValueElement(k, v)}
                </li>
              );
            })}
          </ul>
        </DetailValue>
      );
    }

    return nullToNotAvailable(value);
  };

  useEffect(() => {
    setProduct(location.state.product);
    const main = async () => {
      if (!product) {
        return;
      }
      try {
        const response = await getFootprint(credential, product.organizationID, product.customerProductID);
        setFootprint(response);
      } catch (error) {
        console.error(error);
      }
    };
    main();
  }, [product]);

  return (
    <Card>
      <HomeButton text="Products Home" navigateTo="/cms/customerProducts" />
      <DetailsContainer>
        <h1>Product Details</h1>
        {product &&
          Object.entries(product).map(([label, value]) => {
            return (
              <DetailField key={label}>
                <DetailLabel>{camelCaseToTitleCase(label)}:</DetailLabel>
                {getValueElement(label, value)}
              </DetailField>
            );
          })}
      </DetailsContainer>
      <DetailsContainer>
        <h1>Footprint Calculation</h1>
        {footprint &&
          Object.entries(footprint).map(([label, value]) => {
            return (
              <DetailField key={label}>
                <DetailLabel>{camelCaseToTitleCase(label)}:</DetailLabel>
                {getValueElement(label, value)}
              </DetailField>
            );
          })}
      </DetailsContainer>
    </Card>
  );
};
