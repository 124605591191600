import { styled } from "styletron-react";

export const SectorialScopeFrom = styled("form", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
  marginLeft: "20px",
});

export const FormField = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "600px",
});

export const FormFieldLabel = styled("label", {
  textAlign: "right",
  paddingRight: "10px",
});

export const FormFieldError = styled("div", {
  position: "absolute",
  color: "red",
  fontSize: "14px",
  top: "35px",
});
