import { styled } from "styletron-react";

export const InvoicingForm = styled("form", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  gap: "12px",
});

export const FormField = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const FormFieldLabel = styled("label", {
  textAlign: "right",
  paddingRight: "10px",
});

export const FormFieldInputContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
});

export const FormFieldError = styled("div", {
  position: "absolute",
  color: "red",
  fontSize: "14px",
  top: "35px",
});
