import type { MaterialCompositionResponse } from "./types";

import { makeCopilotRequest } from "api/common";

export const getMaterialCompositions = async (
  credentials: string,
  taxonomyID: string
): Promise<MaterialCompositionResponse[] | undefined> => {
  const limit = 200;
  const response = await makeCopilotRequest(
    `cms/MaterialCompositions?taxonomyID=${taxonomyID}&limit=${limit}`,
    "GET",
    credentials
  );
  return response as MaterialCompositionResponse[];
};

export const getMaterialComposition = async (
  credentials: string,
  id: string
): Promise<MaterialCompositionResponse | undefined> => {
  const response = await makeCopilotRequest(`cms/MaterialCompositions/${id}`, "GET", credentials);
  return response as MaterialCompositionResponse;
};
