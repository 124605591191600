import { styled } from "styletron-react";
import { useNavigate } from "react-router-dom";

const Button = styled("div", {
  position: `absolute`,
  top: `5px`,
  left: `5px`,
  zIndex: 100,
  color: `#999`,
  fontSize: `16px`,
  fontWeight: 600,
  cursor: `pointer`,
  ":hover": {
    color: `#015597`,
  },
});

export const HomeButton = ({ text = "Home", navigateTo = "/" }: { text?: string; navigateTo?: string }) => {
  const navigate = useNavigate();
  return <Button onClick={() => navigate(navigateTo)}>{text}</Button>;
};
