import { FC, CSSProperties } from "react";
import { Spinner } from "baseui/spinner";

type loadingSize = "small" | "medium" | "large" | string;

interface LoadingProps {
  size?: loadingSize;
  text?: string;
}

export const Loading: FC<LoadingProps> = ({ size, text }) => {
  const LoadingSize: CSSProperties = {};
  if (size === "small") {
    LoadingSize.height = "10vh";
  } else if (size === "medium") {
    LoadingSize.height = "25vh";
  } else if (size === "large") {
    LoadingSize.height = "100vh";
  } else if (size) {
    LoadingSize.height = size;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        ...LoadingSize,
      }}
    >
      {text && <h2>{text}</h2>}
      <Spinner />
    </div>
  );
};
