import type { Product } from "api/cms/types";
import type { FC } from "react";

import { ProductExportLink } from "./product.styled";

const convertToCSV = (data: Product[]): string => {
  if (data.length === 0) {
    return "";
  }

  const headers = [
    "Product Name",
    "Brand",
    "User Category",
    "Taxonomy Name",
    "Weight",
    "Price",
    "Currency",
    "Accuracy Level",
    "Approved Accuracy Level",
    "Approved At",
    "Taxonomy ID",
    "Taxonomy Full Name",
    "Taxonomy Level",
    "Taxonomy Emission Factor",
    "Category ID",
    "Category Name",
    "Category EF",
    "Category Source",
    "Category Source ID",
    "Category Country Code",
    "Category Region",
    "Vp ID",
    "Vp Name",
    "Vp Brand",
    "Vp EF",
    "Vp Source",
    "Vp SourceID",
    "MComp ID",
    "MComp Calculated EF",
    "MComp Min Weight",
    "MComp Max Weight",
    "MComp Medium Weight",
    "MComp Total Package EF",
    "MComp Transport To Warehouse",
    "#1 Name",
    "#1 Group Name",
    "#1 Percentage",
    "#1 EF",
    "#1 Source",
    "#1 SourceID",
    "#2 Name",
    "#2 Group Name",
    "#2 Percentage",
    "#2 EF",
    "#2 Source",
    "#2 SourceID",
    "#3 Name",
    "#3 Group Name",
    "#3 Percentage",
    "#3 EF",
    "#3 Source",
    "#3 SourceID",
  ];

  const rows = data.map((row) => [
    row.name,
    row.brand,
    row.userCategory,
    row.taxonomyName,
    row.weightGrams,
    row.unitPriceInCents,
    row.currency,
    row.accuracyLevel,
    row.approvedAccuracyLevel,
    row.approvedAt,
    row.taxonomy.id,
    row.taxonomy.fullName,
    row.taxonomy.level,
    row.taxonomy.conservativeEF,
    row.category?.id,
    row.category?.name,
    row.category?.emissionFactor,
    row.category?.source,
    row.category?.sourceID,
    row.category?.countryCode,
    row.category?.region,
    row.validatedProduct?.id,
    row.validatedProduct?.name,
    row.validatedProduct?.brand,
    row.validatedProduct?.emissionFactor,
    row.validatedProduct?.source,
    row.validatedProduct?.sourceID,
    row.materialComposition?.id,
    row.materialComposition?.calculatedEF,
    row.materialComposition?.minWeight,
    row.materialComposition?.maxWeight,
    row.materialComposition?.medianWeight,
    row.materialComposition?.totalPackageEF,
    row.materialComposition?.transportToWarehouse,
    row.materialComposition?.items.map((item) =>
      [
        item.material.name,
        item.material.groupName,
        item.percentage,
        item.material.emissionFactor,
        item.material.source,
        item.material.sourceID,
      ].join(";")
    ),
  ]);

  return [headers, ...rows].map((row) => row.flat().join(";")).join("\n");
};

export interface DownloadCSVProps {
  data: Product[];
}

export const ProductsExport: FC<DownloadCSVProps> = ({ data }) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  return (
    <ProductExportLink href={url} download={`productsExport.csv`}>
      Download CSV
    </ProductExportLink>
  );
};
