import {
  CredentialResponse,
  GoogleLogin,
} from "@react-oauth/google";
import { FC, createContext, useState } from "react";

export const LoginContext = createContext<{
  credential: string | undefined;
}>({
  credential: undefined,
});

export const LoginProvider: FC<any> = ({ children }) => {
  const [credential, setCredential] = useState<string>();

  const onSuccess = (credentialResponse: CredentialResponse) => {
    setCredential(credentialResponse.credential);
  };

  const onError = () => {
    alert(`Something went wrong. Please try again or contact us.`);
  };

  if (!credential) {
    return (
      <div>
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onError}
          auto_select={true}
          useOneTap={true}
        />
      </div>
    );
  }

  return (
    <LoginContext.Provider value={{ credential }}>
      {children}
    </LoginContext.Provider>
  );
};
