import { forwardRef, InputHTMLAttributes } from "react";
import { styled } from "styletron-react";

const RadioLabel = styled(`label`, {
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-around`,
  gap: `10px`,
  fontSize: `13px`,
});

const RadioInput = styled(`input`, {
  position: `absolute`,
  width: 0,
  height: 0,
  appearance: `none`,
  ":focus + div": {
    boxShadow: `inset 0px 0px 4px rgba(0,0,0,0.25)`,
  },
  ":not(:checked) + div > div": {
    display: `none`,
  },
});

const RadioContainer = styled(`div`, {
  borderRadius: `10px`,
  borderStyle: `solid`,
  borderWidth: `1px`,
  borderColor: `#D0D0D0`,
  width: `20px`,
  height: `20px`,
});

const RadioDot = styled(`div`, {
  margin: `4px`,
  borderRadius: `5px`,
  width: `10px`,
  height: `10px`,
  background: `#015597`,
});

export const RadioButton = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  return (
    <RadioLabel>
      {props.title}
      <RadioInput {...props} type="radio" ref={ref} />
      <RadioContainer>
        <RadioDot />
      </RadioContainer>
    </RadioLabel>
  );
});

RadioButton.displayName = "RadioButton";
