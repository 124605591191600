import { Modal as BaseModal, ModalProps as BaseModalProps } from "baseui/modal";
import { FC } from "react";
import { styled, StyleObject } from "styletron-react";

type ModalProps = BaseModalProps;

export const Modal: FC<ModalProps> = (props: ModalProps) => {
  return (
    <BaseModal
      {...props}
      overrides={{
        ...props.overrides,
        Dialog: {
          style: {
            padding: "40px",
            border: "2px solid #ccc",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "350px",
            ...props.overrides?.Dialog?.style,
          },
        },
      }}
    />
  );
};

interface ModalContentProps {
  children: React.ReactNode;
  style?: StyleObject;
}

export const ModalFooter: FC<ModalContentProps> = ({ children, style }) => {
  const Footer = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    ...style,
  });
  return <Footer>{children}</Footer>;
};

export const ModalBody: FC<ModalContentProps> = ({ children, style }) => {
  const Body = styled("div", {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "20px",
    ...style,
  });
  return <Body>{children}</Body>;
};

export const ModalHeader = styled(`h2`, {
  color: `#015597`,
});
