import type {
  TaxonomyResponse,
  TaxonomyWithEFResponse,
  ProductMinMaxWeightsResponse,
  Product,
  ProductCountResponse,
} from "./types";

import { makeCopilotRequest } from "api/common";

export const updateTaxonomyWeight = async (credentials: string, taxonomyID: string, field: string, value: number) => {
  await makeCopilotRequest(`cms/Taxonomies/${taxonomyID}`, "PATCH", credentials, { [field]: value });
};

export const getTaxonomies = async (credentials: string, name?: string): Promise<TaxonomyWithEFResponse[]> => {
  const query = name && name.length ? `?query=${name}` : "";
  const response = await makeCopilotRequest(`cms/Taxonomies${query}`, "GET", credentials);
  return response as TaxonomyWithEFResponse[];
};

export const getTaxonomy = async (credentials: string, taxonomyID: string): Promise<TaxonomyResponse> => {
  const response = await makeCopilotRequest(`cms/Taxonomies/${taxonomyID}`, "GET", credentials);
  return response as TaxonomyResponse;
};

export const getTaxonomyProductsMinMaxWeight = async (
  credentials: string,
  taxonomyID: string
): Promise<ProductMinMaxWeightsResponse> => {
  const response = await makeCopilotRequest(`cms/Taxonomies/${taxonomyID}/Products/MinMaxWeight`, "GET", credentials);
  return response as ProductMinMaxWeightsResponse;
};

export const getCustomerProducts = async (
  credentials: string,
  taxonomyID: string,
  offset?: number,
  limit?: number
): Promise<Product[]> => {
  offset = offset || 0;
  limit = limit || 10;
  const response = await makeCopilotRequest(
    `cms/Taxonomies/${taxonomyID}/Products?offset=${offset}&limit=${limit}`,
    "GET",
    credentials
  );

  return response as Product[];
};

export const getCustomerProductCount = async (
  credentials: string,
  taxonomyID: string
): Promise<ProductCountResponse> => {
  const response = await makeCopilotRequest(`cms/Taxonomies/${taxonomyID}/ProductCount`, "GET", credentials);

  return response as ProductCountResponse;
};
