export const taxonomies = [
  "Animals & Pet Supplies",
  "Animals & Pet Supplies > Live Animals",
  "Animals & Pet Supplies > Pet Supplies",
  "Animals & Pet Supplies > Pet Supplies > Pet Food",
  "Animals & Pet Supplies > Pet Supplies > Bird Supplies",
  "Animals & Pet Supplies > Pet Supplies > Cat Supplies",
  "Animals & Pet Supplies > Pet Supplies > Dog Supplies",
  "Animals & Pet Supplies > Pet Supplies > Fish Supplies",
  "Animals & Pet Supplies > Pet Supplies > Pet Agility Equipment",
  "Animals & Pet Supplies > Pet Supplies > Pet Apparel Hangers",
  "Animals & Pet Supplies > Pet Supplies > Pet Bed Accessories",
  "Animals & Pet Supplies > Pet Supplies > Pet Bells & Charms",
  "Animals & Pet Supplies > Pet Supplies > Pet Biometric Monitors",
  "Animals & Pet Supplies > Pet Supplies > Pet Bowl Mats",
  "Animals & Pet Supplies > Pet Supplies > Pet Bowl Stands",
  "Animals & Pet Supplies > Pet Supplies > Pet Bowls, Feeders & Waterers",
  "Animals & Pet Supplies > Pet Supplies > Pet Carrier & Crate Accessories",
  "Animals & Pet Supplies > Pet Supplies > Pet Carriers & Crates",
  "Animals & Pet Supplies > Pet Supplies > Pet Collars & Harnesses",
  "Animals & Pet Supplies > Pet Supplies > Pet Containment Systems",
  "Animals & Pet Supplies > Pet Supplies > Pet Door Accessories",
  "Animals & Pet Supplies > Pet Supplies > Pet Doors",
  "Animals & Pet Supplies > Pet Supplies > Pet Eye Drops & Lubricants",
  "Animals & Pet Supplies > Pet Supplies > Pet First Aid & Emergency Kits",
  "Animals & Pet Supplies > Pet Supplies > Pet Flea & Tick Control",
  "Animals & Pet Supplies > Pet Supplies > Pet Food Containers",
  "Animals & Pet Supplies > Pet Supplies > Pet Food Scoops",
  "Animals & Pet Supplies > Pet Supplies > Pet Grooming Supplies",
  "Animals & Pet Supplies > Pet Supplies > Pet Heating Pad Accessories",
  "Animals & Pet Supplies > Pet Supplies > Pet Heating Pads",
  "Animals & Pet Supplies > Pet Supplies > Pet ID Tags",
  "Animals & Pet Supplies > Pet Supplies > Pet Leash Extensions",
  "Animals & Pet Supplies > Pet Supplies > Pet Leashes",
  "Animals & Pet Supplies > Pet Supplies > Pet Medical Collars",
  "Animals & Pet Supplies > Pet Supplies > Pet Medical Tape & Bandages",
  "Animals & Pet Supplies > Pet Supplies > Pet Medicine",
  "Animals & Pet Supplies > Pet Supplies > Pet Muzzles",
  "Animals & Pet Supplies > Pet Supplies > Pet Oral Care Supplies",
  "Animals & Pet Supplies > Pet Supplies > Pet Playpens",
  "Animals & Pet Supplies > Pet Supplies > Pet Steps & Ramps",
  "Animals & Pet Supplies > Pet Supplies > Pet Strollers",
  "Animals & Pet Supplies > Pet Supplies > Pet Sunscreen",
  "Animals & Pet Supplies > Pet Supplies > Pet Training Aids",
  "Animals & Pet Supplies > Pet Supplies > Pet Vitamins & Supplements",
  "Animals & Pet Supplies > Pet Supplies > Pet Waste Bag Dispensers & Holders",
  "Animals & Pet Supplies > Pet Supplies > Pet Waste Bags",
  "Animals & Pet Supplies > Pet Supplies > Pet Waste Disposal Systems & Tools",
  "Animals & Pet Supplies > Pet Supplies > Reptile & Amphibian Supplies",
  "Animals & Pet Supplies > Pet Supplies > Small Animal Supplies",
  "Animals & Pet Supplies > Pet Supplies > Vehicle Pet Barriers",
  "Apparel & Accessories",
  "Apparel & Accessories > Clothing",
  "Apparel & Accessories > Clothing > Activewear",
  "Apparel & Accessories > Clothing > Baby & Toddler Clothing",
  "Apparel & Accessories > Clothing > Dresses",
  "Apparel & Accessories > Clothing > One-Pieces",
  "Apparel & Accessories > Clothing > Outerwear",
  "Apparel & Accessories > Clothing > Outfit Sets",
  "Apparel & Accessories > Clothing > Pants",
  "Apparel & Accessories > Clothing > Shirts & Tops",
  "Apparel & Accessories > Clothing > Shorts",
  "Apparel & Accessories > Clothing > Skirts",
  "Apparel & Accessories > Clothing > Skorts",
  "Apparel & Accessories > Clothing > Sleepwear & Loungewear",
  "Apparel & Accessories > Clothing > Suits",
  "Apparel & Accessories > Clothing > Swimwear",
  "Apparel & Accessories > Clothing > Traditional & Ceremonial Clothing",
  "Apparel & Accessories > Clothing > Underwear & Socks",
  "Apparel & Accessories > Clothing > Uniforms",
  "Apparel & Accessories > Clothing > Wedding & Bridal Party Dresses",
  "Apparel & Accessories > Clothing Accessories",
  "Apparel & Accessories > Clothing Accessories > Arm Warmers & Sleeves",
  "Apparel & Accessories > Clothing Accessories > Baby & Toddler Clothing Accessories",
  "Apparel & Accessories > Clothing Accessories > Balaclavas",
  "Apparel & Accessories > Clothing Accessories > Bandanas & Headties",
  "Apparel & Accessories > Clothing Accessories > Belt Buckles",
  "Apparel & Accessories > Clothing Accessories > Belts",
  "Apparel & Accessories > Clothing Accessories > Bridal Accessories",
  "Apparel & Accessories > Clothing Accessories > Button Studs",
  "Apparel & Accessories > Clothing Accessories > Collar Stays",
  "Apparel & Accessories > Clothing Accessories > Cufflinks",
  "Apparel & Accessories > Clothing Accessories > Decorative Fans",
  "Apparel & Accessories > Clothing Accessories > Earmuffs",
  "Apparel & Accessories > Clothing Accessories > Gloves & Mittens",
  "Apparel & Accessories > Clothing Accessories > Hair Accessories",
  "Apparel & Accessories > Clothing Accessories > Hand Muffs",
  "Apparel & Accessories > Clothing Accessories > Handkerchiefs",
  "Apparel & Accessories > Clothing Accessories > Hats",
  "Apparel & Accessories > Clothing Accessories > Headwear",
  "Apparel & Accessories > Clothing Accessories > Leg Warmers",
  "Apparel & Accessories > Clothing Accessories > Leis",
  "Apparel & Accessories > Clothing Accessories > Maternity Belts & Support Bands",
  "Apparel & Accessories > Clothing Accessories > Neck Gaiters",
  "Apparel & Accessories > Clothing Accessories > Neckties",
  "Apparel & Accessories > Clothing Accessories > Pinback Buttons",
  "Apparel & Accessories > Clothing Accessories > Sashes",
  "Apparel & Accessories > Clothing Accessories > Scarves & Shawls",
  "Apparel & Accessories > Clothing Accessories > Sunglasses",
  "Apparel & Accessories > Clothing Accessories > Suspenders",
  "Apparel & Accessories > Clothing Accessories > Tie Clips",
  "Apparel & Accessories > Clothing Accessories > Traditional Clothing Accessories",
  "Apparel & Accessories > Clothing Accessories > Wristbands",
  "Apparel & Accessories > Costumes & Accessories",
  "Apparel & Accessories > Costumes & Accessories > Costume Accessories",
  "Apparel & Accessories > Costumes & Accessories > Costume Shoes",
  "Apparel & Accessories > Costumes & Accessories > Costumes",
  "Apparel & Accessories > Costumes & Accessories > Masks",
  "Apparel & Accessories > Handbag & Wallet Accessories",
  "Apparel & Accessories > Handbag & Wallet Accessories > Checkbook Covers",
  "Apparel & Accessories > Handbag & Wallet Accessories > Keychains",
  "Apparel & Accessories > Handbag & Wallet Accessories > Lanyards",
  "Apparel & Accessories > Handbag & Wallet Accessories > Wallet Chains",
  "Apparel & Accessories > Handbags, Wallets & Cases",
  "Apparel & Accessories > Handbags, Wallets & Cases > Badge & Pass Holders",
  "Apparel & Accessories > Handbags, Wallets & Cases > Business Card Cases",
  "Apparel & Accessories > Handbags, Wallets & Cases > Handbags",
  "Apparel & Accessories > Handbags, Wallets & Cases > Wallets & Money Clips",
  "Apparel & Accessories > Jewelry",
  "Apparel & Accessories > Jewelry > Anklets",
  "Apparel & Accessories > Jewelry > Body Jewelry",
  "Apparel & Accessories > Jewelry > Bracelets",
  "Apparel & Accessories > Jewelry > Brooches & Lapel Pins",
  "Apparel & Accessories > Jewelry > Charms & Pendants",
  "Apparel & Accessories > Jewelry > Earrings",
  "Apparel & Accessories > Jewelry > Jewelry Sets",
  "Apparel & Accessories > Jewelry > Necklaces",
  "Apparel & Accessories > Jewelry > Rings",
  "Apparel & Accessories > Jewelry > Watch Accessories",
  "Apparel & Accessories > Jewelry > Watches",
  "Apparel & Accessories > Shoe Accessories",
  "Apparel & Accessories > Shoe Accessories > Boot Liners",
  "Apparel & Accessories > Shoe Accessories > Gaiters",
  "Apparel & Accessories > Shoe Accessories > Shoe Covers",
  "Apparel & Accessories > Shoe Accessories > Shoelaces",
  "Apparel & Accessories > Shoe Accessories > Spurs",
  "Apparel & Accessories > Shoes",
  "Arts & Entertainment",
  "Arts & Entertainment > Event Tickets",
  "Arts & Entertainment > Hobbies & Creative Arts",
  "Arts & Entertainment > Hobbies & Creative Arts > Arts & Crafts",
  "Arts & Entertainment > Hobbies & Creative Arts > Collectibles",
  "Arts & Entertainment > Hobbies & Creative Arts > Homebrewing & Winemaking Supplies",
  "Arts & Entertainment > Hobbies & Creative Arts > Juggling",
  "Arts & Entertainment > Hobbies & Creative Arts > Magic & Novelties",
  "Arts & Entertainment > Hobbies & Creative Arts > Model Making",
  "Arts & Entertainment > Hobbies & Creative Arts > Musical Instrument & Orchestra Accessories",
  "Arts & Entertainment > Hobbies & Creative Arts > Musical Instruments",
  "Arts & Entertainment > Party & Celebration",
  "Arts & Entertainment > Party & Celebration > Gift Giving",
  "Arts & Entertainment > Party & Celebration > Party Supplies",
  "Arts & Entertainment > Party & Celebration > Special Effects",
  "Arts & Entertainment > Party & Celebration > Trophies & Awards",
  "Baby & Toddler",
  "Baby & Toddler > Baby Bathing",
  "Baby & Toddler > Baby Bathing > Baby Bathtubs & Bath Seats",
  "Baby & Toddler > Baby Bathing > Shower Visors",
  "Baby & Toddler > Baby Gift Sets",
  "Baby & Toddler > Baby Health",
  "Baby & Toddler > Baby Health > Baby Health & Grooming Kits",
  "Baby & Toddler > Baby Health > Nasal Aspirators",
  "Baby & Toddler > Baby Health > Pacifier Clips & Holders",
  "Baby & Toddler > Baby Health > Pacifier Wipes",
  "Baby & Toddler > Baby Health > Pacifiers & Teethers",
  "Baby & Toddler > Baby Safety",
  "Baby & Toddler > Baby Safety > Baby & Pet Gate Accessories",
  "Baby & Toddler > Baby Safety > Baby & Pet Gates",
  "Baby & Toddler > Baby Safety > Baby Monitors",
  "Baby & Toddler > Baby Safety > Baby Safety Harnesses & Leashes",
  "Baby & Toddler > Baby Safety > Baby Safety Locks & Guards",
  "Baby & Toddler > Baby Safety > Baby Safety Rails",
  "Baby & Toddler > Baby Toys & Activity Equipment",
  "Baby & Toddler > Baby Toys & Activity Equipment > Alphabet Toys",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Activity Toys",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Bouncers & Rockers",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Jumpers & Swings",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Mobile Accessories",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Mobiles",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Soothers",
  "Baby & Toddler > Baby Toys & Activity Equipment > Baby Walkers & Entertainers",
  "Baby & Toddler > Baby Toys & Activity Equipment > Play Mats & Gyms",
  "Baby & Toddler > Baby Toys & Activity Equipment > Play Yards",
  "Baby & Toddler > Baby Toys & Activity Equipment > Push & Pull Toys",
  "Baby & Toddler > Baby Toys & Activity Equipment > Rattles",
  "Baby & Toddler > Baby Toys & Activity Equipment > Sorting & Stacking Toys",
  "Baby & Toddler > Baby Transport",
  "Baby & Toddler > Baby Transport > Baby & Toddler Car Seats",
  "Baby & Toddler > Baby Transport > Baby Carriers",
  "Baby & Toddler > Baby Transport > Baby Strollers",
  "Baby & Toddler > Baby Transport Accessories",
  "Baby & Toddler > Baby Transport Accessories > Baby & Toddler Car Seat Accessories",
  "Baby & Toddler > Baby Transport Accessories > Baby Carrier Accessories",
  "Baby & Toddler > Baby Transport Accessories > Baby Stroller Accessories",
  "Baby & Toddler > Baby Transport Accessories > Baby Transport Liners & Sacks",
  "Baby & Toddler > Baby Transport Accessories > Shopping Cart & High Chair Covers",
  "Baby & Toddler > Diapering",
  "Baby & Toddler > Diapering > Baby Wipe Dispensers & Warmers",
  "Baby & Toddler > Diapering > Baby Wipes",
  "Baby & Toddler > Diapering > Changing Mat & Tray Covers",
  "Baby & Toddler > Diapering > Changing Mats & Trays",
  "Baby & Toddler > Diapering > Diaper Kits",
  "Baby & Toddler > Diapering > Diaper Liners",
  "Baby & Toddler > Diapering > Diaper Organizers",
  "Baby & Toddler > Diapering > Diaper Pail Accessories",
  "Baby & Toddler > Diapering > Diaper Pails",
  "Baby & Toddler > Diapering > Diaper Rash Treatments",
  "Baby & Toddler > Diapering > Diaper Wet Bags",
  "Baby & Toddler > Diapering > Diapers",
  "Baby & Toddler > Nursing & Feeding",
  "Baby & Toddler > Nursing & Feeding > Baby & Toddler Food",
  "Baby & Toddler > Nursing & Feeding > Baby Bottle Nipples & Liners",
  "Baby & Toddler > Nursing & Feeding > Baby Bottles",
  "Baby & Toddler > Nursing & Feeding > Baby Care Timers",
  "Baby & Toddler > Nursing & Feeding > Bibs",
  "Baby & Toddler > Nursing & Feeding > Bottle Warmers & Sterilizers",
  "Baby & Toddler > Nursing & Feeding > Breast Milk Storage Containers",
  "Baby & Toddler > Nursing & Feeding > Breast Pump Accessories",
  "Baby & Toddler > Nursing & Feeding > Breast Pumps",
  "Baby & Toddler > Nursing & Feeding > Burp Cloths",
  "Baby & Toddler > Nursing & Feeding > Nursing Covers",
  "Baby & Toddler > Nursing & Feeding > Nursing Pads & Shields",
  "Baby & Toddler > Nursing & Feeding > Nursing Pillow Covers",
  "Baby & Toddler > Nursing & Feeding > Nursing Pillows",
  "Baby & Toddler > Nursing & Feeding > Sippy Cups",
  "Baby & Toddler > Potty Training",
  "Baby & Toddler > Potty Training > Potty Seats",
  "Baby & Toddler > Potty Training > Potty Training Kits",
  "Baby & Toddler > Swaddling & Receiving Blankets",
  "Baby & Toddler > Swaddling & Receiving Blankets > Receiving Blankets",
  "Baby & Toddler > Swaddling & Receiving Blankets > Swaddling Blankets",
  "Business & Industrial",
  "Business & Industrial > Advertising & Marketing",
  "Business & Industrial > Advertising & Marketing > Brochures",
  "Business & Industrial > Advertising & Marketing > Trade Show Counters",
  "Business & Industrial > Advertising & Marketing > Trade Show Displays",
  "Business & Industrial > Agriculture",
  "Business & Industrial > Agriculture > Animal Husbandry",
  "Business & Industrial > Automation Control Components",
  "Business & Industrial > Automation Control Components > Programmable Logic Controllers",
  "Business & Industrial > Automation Control Components > Variable Frequency & Adjustable Speed Drives",
  "Business & Industrial > Construction",
  "Business & Industrial > Construction > Surveying",
  "Business & Industrial > Construction > Traffic Cones & Barrels",
  "Business & Industrial > Dentistry",
  "Business & Industrial > Dentistry > Dental Cement",
  "Business & Industrial > Dentistry > Dental Tools",
  "Business & Industrial > Dentistry > Prophy Paste",
  "Business & Industrial > Film & Television",
  "Business & Industrial > Finance & Insurance",
  "Business & Industrial > Finance & Insurance > Bullion",
  "Business & Industrial > Food Service",
  "Business & Industrial > Food Service > Bakery Boxes",
  "Business & Industrial > Food Service > Bus Tubs",
  "Business & Industrial > Food Service > Check Presenters",
  "Business & Industrial > Food Service > Concession Food Containers",
  "Business & Industrial > Food Service > Disposable Lids",
  "Business & Industrial > Food Service > Disposable Serveware",
  "Business & Industrial > Food Service > Disposable Tableware",
  "Business & Industrial > Food Service > Food Service Baskets",
  "Business & Industrial > Food Service > Food Service Carts",
  "Business & Industrial > Food Service > Food Washers & Dryers",
  "Business & Industrial > Food Service > Hot Dog Rollers",
  "Business & Industrial > Food Service > Ice Bins",
  "Business & Industrial > Food Service > Plate & Dish Warmers",
  "Business & Industrial > Food Service > Sneeze Guards",
  "Business & Industrial > Food Service > Take-Out Containers",
  "Business & Industrial > Food Service > Tilt Skillets",
  "Business & Industrial > Food Service > Vending Machines",
  "Business & Industrial > Forestry & Logging",
  "Business & Industrial > Hairdressing & Cosmetology",
  "Business & Industrial > Hairdressing & Cosmetology > Hairdressing Capes & Neck Covers",
  "Business & Industrial > Hairdressing & Cosmetology > Pedicure Chairs",
  "Business & Industrial > Hairdressing & Cosmetology > Salon Chairs",
  "Business & Industrial > Heavy Machinery",
  "Business & Industrial > Heavy Machinery > Chippers",
  "Business & Industrial > Hotel & Hospitality",
  "Business & Industrial > Industrial Storage",
  "Business & Industrial > Industrial Storage > Industrial Cabinets",
  "Business & Industrial > Industrial Storage > Industrial Shelving",
  "Business & Industrial > Industrial Storage > Shipping Containers",
  "Business & Industrial > Industrial Storage > Wire Partitions, Enclosures & Doors",
  "Business & Industrial > Industrial Storage Accessories",
  "Business & Industrial > Janitorial Carts & Caddies",
  "Business & Industrial > Law Enforcement",
  "Business & Industrial > Law Enforcement > Cuffs",
  "Business & Industrial > Law Enforcement > Metal Detectors",
  "Business & Industrial > Manufacturing",
  "Business & Industrial > Material Handling",
  "Business & Industrial > Material Handling > Conveyors",
  "Business & Industrial > Material Handling > Lifts & Hoists",
  "Business & Industrial > Material Handling > Pallets & Loading Platforms",
  "Business & Industrial > Medical",
  "Business & Industrial > Medical > Hospital Curtains",
  "Business & Industrial > Medical > Hospital Gowns",
  "Business & Industrial > Medical > Medical Bedding",
  "Business & Industrial > Medical > Medical Equipment",
  "Business & Industrial > Medical > Medical Furniture",
  "Business & Industrial > Medical > Medical Instruments",
  "Business & Industrial > Medical > Medical Supplies",
  "Business & Industrial > Medical > Medical Teaching Equipment",
  "Business & Industrial > Medical > Scrub Caps",
  "Business & Industrial > Medical > Scrubs",
  "Business & Industrial > Medical > Surgical Gowns",
  "Business & Industrial > Mining & Quarrying",
  "Business & Industrial > Piercing & Tattooing",
  "Business & Industrial > Piercing & Tattooing > Piercing Supplies",
  "Business & Industrial > Piercing & Tattooing > Tattooing Supplies",
  "Business & Industrial > Retail",
  "Business & Industrial > Retail > Clothing Display Racks",
  "Business & Industrial > Retail > Display Mannequins",
  "Business & Industrial > Retail > Mannequin Parts",
  "Business & Industrial > Retail > Money Handling",
  "Business & Industrial > Retail > Paper & Plastic Shopping Bags",
  "Business & Industrial > Retail > Pricing Guns",
  "Business & Industrial > Retail > Retail Display Cases",
  "Business & Industrial > Retail > Retail Display Props & Models",
  "Business & Industrial > Science & Laboratory",
  "Business & Industrial > Science & Laboratory > Biochemicals",
  "Business & Industrial > Science & Laboratory > Dissection Kits",
  "Business & Industrial > Science & Laboratory > Laboratory Chemicals",
  "Business & Industrial > Science & Laboratory > Laboratory Equipment",
  "Business & Industrial > Science & Laboratory > Laboratory Specimens",
  "Business & Industrial > Science & Laboratory > Laboratory Supplies",
  "Business & Industrial > Signage",
  "Business & Industrial > Signage > Business Hour Signs",
  "Business & Industrial > Signage > Digital Signs",
  "Business & Industrial > Signage > Electric Signs",
  "Business & Industrial > Signage > Emergency & Exit Signs",
  "Business & Industrial > Signage > Facility Identification Signs",
  "Business & Industrial > Signage > Open & Closed Signs",
  "Business & Industrial > Signage > Parking Signs & Permits",
  "Business & Industrial > Signage > Policy Signs",
  "Business & Industrial > Signage > Retail & Sale Signs",
  "Business & Industrial > Signage > Road & Traffic Signs",
  "Business & Industrial > Signage > Safety & Warning Signs",
  "Business & Industrial > Signage > Security Signs",
  "Business & Industrial > Signage > Sidewalk & Yard Signs",
  "Business & Industrial > Work Safety Protective Gear",
  "Business & Industrial > Work Safety Protective Gear > Bullet Proof Vests",
  "Business & Industrial > Work Safety Protective Gear > Gas Mask & Respirator Accessories",
  "Business & Industrial > Work Safety Protective Gear > Hardhats",
  "Business & Industrial > Work Safety Protective Gear > Hazardous Material Suits",
  "Business & Industrial > Work Safety Protective Gear > Protective Aprons",
  "Business & Industrial > Work Safety Protective Gear > Protective Eyewear",
  "Business & Industrial > Work Safety Protective Gear > Protective Masks",
  "Business & Industrial > Work Safety Protective Gear > Safety Gloves",
  "Business & Industrial > Work Safety Protective Gear > Safety Knee Pads",
  "Business & Industrial > Work Safety Protective Gear > Welding Helmets",
  "Business & Industrial > Work Safety Protective Gear > Work Safety Harnesses",
  "Business & Industrial > Work Safety Protective Gear > Work Safety Tethers",
  "Cameras & Optics",
  "Cameras & Optics > Camera & Optic Accessories",
  "Cameras & Optics > Camera & Optic Accessories > Camera & Optic Replacement Cables",
  "Cameras & Optics > Camera & Optic Accessories > Camera & Video Camera Lenses",
  "Cameras & Optics > Camera & Optic Accessories > Camera Lens Accessories",
  "Cameras & Optics > Camera & Optic Accessories > Camera Parts & Accessories",
  "Cameras & Optics > Camera & Optic Accessories > Optic Accessories",
  "Cameras & Optics > Camera & Optic Accessories > Tripod & Monopod Accessories",
  "Cameras & Optics > Camera & Optic Accessories > Tripods & Monopods",
  "Cameras & Optics > Cameras",
  "Cameras & Optics > Cameras > Borescopes",
  "Cameras & Optics > Cameras > Digital Cameras",
  "Cameras & Optics > Cameras > Disposable Cameras",
  "Cameras & Optics > Cameras > Film Cameras",
  "Cameras & Optics > Cameras > Surveillance Cameras",
  "Cameras & Optics > Cameras > Trail Cameras",
  "Cameras & Optics > Cameras > Video Cameras",
  "Cameras & Optics > Cameras > Webcams",
  "Cameras & Optics > Optics",
  "Cameras & Optics > Optics > Binoculars",
  "Cameras & Optics > Optics > Monoculars",
  "Cameras & Optics > Optics > Rangefinders",
  "Cameras & Optics > Optics > Scopes",
  "Cameras & Optics > Photography",
  "Cameras & Optics > Photography > Darkroom",
  "Cameras & Optics > Photography > Lighting & Studio",
  "Cameras & Optics > Photography > Photo Mounting Supplies",
  "Cameras & Optics > Photography > Photo Negative & Slide Storage",
  "Electronics",
  "Electronics > Household Appliances",
  "Electronics > Household Appliances > Climate Control Appliances",
  "Electronics > Household Appliances > Floor & Carpet Dryers",
  "Electronics > Household Appliances > Floor & Steam Cleaners",
  "Electronics > Household Appliances > Floor Polishers & Buffers",
  "Electronics > Household Appliances > Futon Dryers",
  "Electronics > Household Appliances > Garage Door Keypads & Remotes",
  "Electronics > Household Appliances > Garage Door Openers",
  "Electronics > Household Appliances > Laundry Appliances",
  "Electronics > Household Appliances > Ultrasonic Cleaners",
  "Electronics > Household Appliances > Vacuums",
  "Electronics > Household Appliances > Wallpaper Steamers",
  "Electronics > Household Appliances > Water Heaters",
  "Electronics > Lighting",
  "Electronics > Lighting > Emergency Lighting",
  "Electronics > Lighting > Floating & Submersible Lights",
  "Electronics > Lighting > Flood & Spot Lights",
  "Electronics > Lighting > In-Ground Lights",
  "Electronics > Lighting > Lamps",
  "Electronics > Lighting > Landscape Pathway Lighting",
  "Electronics > Lighting > Light Bulbs",
  "Electronics > Lighting > Light Ropes & Strings",
  "Electronics > Lighting > Lighting Fixtures",
  "Electronics > Lighting > Night Lights & Ambient Lighting",
  "Electronics > Lighting > Picture Lights",
  "Electronics > Lighting > Tiki Torches & Oil Lamps",
  "Electronics > Lighting > Track Lighting",
  "Electronics > Lighting Accessories",
  "Electronics > Lighting Accessories > Lamp Post Bases",
  "Electronics > Lighting Accessories > Lamp Post Mounts",
  "Electronics > Lighting Accessories > Lamp Shades",
  "Electronics > Lighting Accessories > Lighting Timers",
  "Electronics > Lighting Accessories > Oil Lamp Fuel",
  "Electronics > Arcade Equipment",
  "Electronics > Arcade Equipment > Basketball Arcade Games",
  "Electronics > Arcade Equipment > Pinball Machine Accessories",
  "Electronics > Arcade Equipment > Pinball Machines",
  "Electronics > Arcade Equipment > Skee-Ball Machines",
  "Electronics > Arcade Equipment > Video Game Arcade Cabinet Accessories",
  "Electronics > Arcade Equipment > Video Game Arcade Cabinets",
  "Electronics > Audio",
  "Electronics > Audio > Audio Accessories",
  "Electronics > Audio > Audio Components",
  "Electronics > Audio > Audio Components > Headphones & Headsets",
  "Electronics > Audio > Audio Players & Recorders",
  "Electronics > Audio > Bullhorns",
  "Electronics > Audio > DJ & Specialty Audio",
  "Electronics > Audio > Public Address Systems",
  "Electronics > Audio > Stage Equipment",
  "Electronics > Circuit Boards & Components",
  "Electronics > Circuit Boards & Components > Circuit Board Accessories",
  "Electronics > Circuit Boards & Components > Circuit Decoders & Encoders",
  "Electronics > Circuit Boards & Components > Circuit Prototyping",
  "Electronics > Circuit Boards & Components > Electronic Filters",
  "Electronics > Circuit Boards & Components > Passive Circuit Components",
  "Electronics > Circuit Boards & Components > Printed Circuit Boards",
  "Electronics > Circuit Boards & Components > Semiconductors",
  "Electronics > Communications",
  "Electronics > Communications > Answering Machines",
  "Electronics > Communications > Caller IDs",
  "Electronics > Communications > Communication Radio Accessories",
  "Electronics > Communications > Communication Radios",
  "Electronics > Communications > Intercom Accessories",
  "Electronics > Communications > Intercoms",
  "Electronics > Communications > Pagers",
  "Electronics > Communications > Telephony",
  "Electronics > Communications > Telephony > Mobile Phone Accessories",
  "Electronics > Communications > Telephony > Mobile Phones",
  "Electronics > Communications > Video Conferencing",
  "Electronics > Components",
  "Electronics > Components > Accelerometers",
  "Electronics > Components > Converters",
  "Electronics > Components > Electronics Component Connectors",
  "Electronics > Components > Modulators",
  "Electronics > Components > Splitters",
  "Electronics > Computers",
  "Electronics > Computers > Barebone Computers",
  "Electronics > Computers > Computer Servers",
  "Electronics > Computers > Desktop Computers",
  "Electronics > Computers > Handheld Devices",
  "Electronics > Computers > Interactive Kiosks",
  "Electronics > Computers > Laptops",
  "Electronics > Computers > Smart Glasses",
  "Electronics > Computers > Tablet Computers",
  "Electronics > Computers > Thin & Zero Clients",
  "Electronics > Computers > Touch Table Computers",
  "Electronics > Electronics Accessories",
  "Electronics > Electronics Accessories > Adapters",
  "Electronics > Electronics Accessories > Antenna Accessories",
  "Electronics > Electronics Accessories > Antennas",
  "Electronics > Electronics Accessories > Audio & Video Splitters & Switches",
  "Electronics > Electronics Accessories > Blank Media",
  "Electronics > Electronics Accessories > Cable Management",
  "Electronics > Electronics Accessories > Cables",
  "Electronics > Electronics Accessories > Computer Accessories",
  "Electronics > Electronics Accessories > Computer Components",
  "Electronics > Electronics Accessories > Electronics Cleaners",
  "Electronics > Electronics Accessories > Electronics Films & Shields",
  "Electronics > Electronics Accessories > Memory",
  "Electronics > Electronics Accessories > Memory Accessories",
  "Electronics > Electronics Accessories > Mobile Phone & Tablet Tripods & Monopods",
  "Electronics > Electronics Accessories > Power",
  "Electronics > Electronics Accessories > Remote Controls",
  "Electronics > Electronics Accessories > Signal Boosters",
  "Electronics > Electronics Accessories > Signal Jammers",
  "Electronics > GPS Accessories",
  "Electronics > GPS Accessories > GPS Cases",
  "Electronics > GPS Accessories > GPS Mounts",
  "Electronics > GPS Navigation Systems",
  "Electronics > GPS Tracking Devices",
  "Electronics > Marine Electronics",
  "Electronics > Marine Electronics > Fish Finders",
  "Electronics > Marine Electronics > Marine Audio & Video Receivers",
  "Electronics > Marine Electronics > Marine Chartplotters & GPS",
  "Electronics > Marine Electronics > Marine Radar",
  "Electronics > Marine Electronics > Marine Radios",
  "Electronics > Marine Electronics > Marine Speakers",
  "Electronics > Networking",
  "Electronics > Networking > Bridges & Routers",
  "Electronics > Networking > Concentrators & Multiplexers",
  "Electronics > Networking > Hubs & Switches",
  "Electronics > Networking > Modem Accessories",
  "Electronics > Networking > Modems",
  "Electronics > Networking > Network Cards & Adapters",
  "Electronics > Networking > Network Security & Firewall Devices",
  "Electronics > Networking > Power Over Ethernet Adapters",
  "Electronics > Networking > Print Servers",
  "Electronics > Networking > Repeaters & Transceivers",
  "Electronics > Print, Copy, Scan & Fax",
  "Electronics > Print, Copy, Scan & Fax > 3D Printer Accessories",
  "Electronics > Print, Copy, Scan & Fax > 3D Printers",
  "Electronics > Print, Copy, Scan & Fax > Printer, Copier & Fax Machine Accessories",
  "Electronics > Print, Copy, Scan & Fax > Printers, Copiers & Fax Machines",
  "Electronics > Print, Copy, Scan & Fax > Scanner Accessories",
  "Electronics > Print, Copy, Scan & Fax > Scanners",
  "Electronics > Radar Detectors",
  "Electronics > Speed Radars",
  "Electronics > Toll Collection Devices",
  "Electronics > Video",
  "Electronics > Video > Computer Monitors",
  "Electronics > Video > Projectors",
  "Electronics > Video > Satellite & Cable TV",
  "Electronics > Video > Televisions",
  "Electronics > Video > Video Accessories",
  "Electronics > Video > Video Editing Hardware & Production Equipment",
  "Electronics > Video > Video Multiplexers",
  "Electronics > Video > Video Players & Recorders",
  "Electronics > Video > Video Servers",
  "Electronics > Video > Video Transmitters",
  "Electronics > Video Game Console Accessories",
  "Electronics > Video Game Console Accessories > Home Game Console Accessories",
  "Electronics > Video Game Console Accessories > Portable Game Console Accessories",
  "Electronics > Video Game Consoles",
  "Food, Beverages & Tobacco",
  "Food, Beverages & Tobacco > Beverages",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Beer",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Bitters",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Cocktail Mixes",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Cocktail Mixes > Frozen Cocktail Mixes",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Cocktail Mixes > Shelf-stable Cocktail Mixes",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Flavored Alcoholic Beverages",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Hard Cider",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Absinthe",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Brandy",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Gin",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Liqueurs",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Rum",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Shochu & Soju",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Shochu & Soju > Shochu",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Shochu & Soju > Soju",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Tequila",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Vodka",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Liquor & Spirits > Whiskey",
  "Food, Beverages & Tobacco > Beverages > Alcoholic Beverages > Wine",
  "Food, Beverages & Tobacco > Beverages > Buttermilk",
  "Food, Beverages & Tobacco > Beverages > Coffee",
  "Food, Beverages & Tobacco > Beverages > Eggnog",
  "Food, Beverages & Tobacco > Beverages > Fruit Flavored Drinks",
  "Food, Beverages & Tobacco > Beverages > Hot Chocolate",
  "Food, Beverages & Tobacco > Beverages > Juice",
  "Food, Beverages & Tobacco > Beverages > Milk",
  "Food, Beverages & Tobacco > Beverages > Non-Dairy Milk",
  "Food, Beverages & Tobacco > Beverages > Powdered Beverage Mixes",
  "Food, Beverages & Tobacco > Beverages > Soda",
  "Food, Beverages & Tobacco > Beverages > Sports & Energy Drinks",
  "Food, Beverages & Tobacco > Beverages > Tea & Infusions",
  "Food, Beverages & Tobacco > Beverages > Vinegar Drinks",
  "Food, Beverages & Tobacco > Beverages > Water",
  "Food, Beverages & Tobacco > Beverages > Water > Carbonated Water",
  "Food, Beverages & Tobacco > Beverages > Water > Carbonated Water > Flavored Carbonated Water",
  "Food, Beverages & Tobacco > Beverages > Water > Carbonated Water > Unflavored Carbonated Water",
  "Food, Beverages & Tobacco > Beverages > Water > Distilled Water",
  "Food, Beverages & Tobacco > Beverages > Water > Flat Mineral Water",
  "Food, Beverages & Tobacco > Beverages > Water > Spring Water",
  "Food, Beverages & Tobacco > Food Items",
  "Food, Beverages & Tobacco > Food Items > Bakery",
  "Food, Beverages & Tobacco > Food Items > Bakery > Bagels",
  "Food, Beverages & Tobacco > Food Items > Bakery > Bakery Assortments",
  "Food, Beverages & Tobacco > Food Items > Bakery > Breads & Buns",
  "Food, Beverages & Tobacco > Food Items > Bakery > Cakes & Dessert Bars",
  "Food, Beverages & Tobacco > Food Items > Bakery > Coffee Cakes",
  "Food, Beverages & Tobacco > Food Items > Bakery > Cookies",
  "Food, Beverages & Tobacco > Food Items > Bakery > Cupcakes",
  "Food, Beverages & Tobacco > Food Items > Bakery > Donuts",
  "Food, Beverages & Tobacco > Food Items > Bakery > Fudge",
  "Food, Beverages & Tobacco > Food Items > Bakery > Ice Cream Cones",
  "Food, Beverages & Tobacco > Food Items > Bakery > Muffins",
  "Food, Beverages & Tobacco > Food Items > Bakery > Pastries & Scones",
  "Food, Beverages & Tobacco > Food Items > Bakery > Pies & Tarts",
  "Food, Beverages & Tobacco > Food Items > Bakery > Taco Shells & Tostadas",
  "Food, Beverages & Tobacco > Food Items > Bakery > Tortillas & Wraps",
  "Food, Beverages & Tobacco > Food Items > Candied & Chocolate Covered Fruit",
  "Food, Beverages & Tobacco > Food Items > Candy & Chocolate",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Cocktail Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Curry Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Dessert Toppings",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Dessert Toppings > Fruit Toppings",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Dessert Toppings > Ice Cream Syrup",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Fish Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Gravy",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Honey",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Horseradish Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Hot Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Ketchup",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Marinades & Grilling Sauces",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Mayonnaise",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Mustard",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Olives & Capers",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Pasta Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Pickled Fruits & Vegetables",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Pizza Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Relish & Chutney",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Salad Dressing",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Satay Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Soy Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Sweet and Sour Sauces",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Syrup",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Tahini",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Tartar Sauce",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > White & Cream Sauces",
  "Food, Beverages & Tobacco > Food Items > Condiments & Sauces > Worcestershire Sauce",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Baking Chips",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Baking Chocolate",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Baking Flavors & Extracts",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Baking Mixes",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Baking Powder",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Baking Soda",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Batter & Coating Mixes",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Bean Paste",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Bread Crumbs",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Canned & Dry Milk",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Cookie Decorating Kits",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Cooking Oils",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Cooking Starch",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Cooking Wine",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Corn Syrup",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Dough",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Dough > Bread & Pastry Dough",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Dough > Cookie & Brownie Dough",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Dough > Pie Crusts",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Edible Baking Decorations",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Egg Replacers",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Floss Sugar",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Flour",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Food Coloring",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Frosting & Icing",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Lemon & Lime Juice",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Marshmallows",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Meal",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Molasses",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Pie & Pastry Fillings",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Shortening & Lard",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Starter Cultures",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Sugar & Sweeteners",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Tapioca Pearls",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Tomato Paste",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Unflavored Gelatin",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Vinegar",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Waffle & Pancake Mixes",
  "Food, Beverages & Tobacco > Food Items > Cooking & Baking Ingredients > Yeast",
  "Food, Beverages & Tobacco > Food Items > Dairy Products",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Butter & Margarine",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Cheese",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Coffee Creamer",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Cottage Cheese",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Cream",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Sour Cream",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Whipped Cream",
  "Food, Beverages & Tobacco > Food Items > Dairy Products > Yogurt",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Apple Butter",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Cheese Dips & Spreads",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Cream Cheese",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Guacamole",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Hummus",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Jams & Jellies",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Nut Butters",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Salsa",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Tapenade",
  "Food, Beverages & Tobacco > Food Items > Dips & Spreads > Vegetable Dip",
  "Food, Beverages & Tobacco > Food Items > Food Gift Baskets",
  "Food, Beverages & Tobacco > Food Items > Frozen Desserts & Novelties",
  "Food, Beverages & Tobacco > Food Items > Frozen Desserts & Novelties > Ice Cream & Frozen Yogurt",
  "Food, Beverages & Tobacco > Food Items > Frozen Desserts & Novelties > Ice Cream Novelties",
  "Food, Beverages & Tobacco > Food Items > Frozen Desserts & Novelties > Ice Pops",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Canned & Jarred Fruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Canned & Jarred Vegetables",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Canned & Prepared Beans",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Dried Fruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Dried Vegetables",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Dry Beans",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Apples",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Atemoyas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Avocados",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Babacos",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Bananas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Berries",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Breadfruit",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Cactus Pears",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Cherimoyas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Grapefruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Kumquats",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Lemons",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Limequats",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Limes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Oranges",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Citrus Fruits > Tangelos",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Coconuts",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Dates",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Feijoas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Figs",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Fruit Mixes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Grapes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Guavas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Homely Fruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Kiwis",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Longan",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Loquats",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Lychees",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Madroño",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Mamey",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Mangosteens",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Melons",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Papayas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Passion Fruit",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Pears",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Persimmons",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Physalis",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Pineapples",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Pitahayas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Pomegranates",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Quince",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Rambutans",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Sapodillo",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Sapote",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Soursops",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Starfruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits > Apricots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits > Cherries",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits > Mangoes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits > Peaches & Nectarines",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits > Plumcots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Stone Fruits > Plums",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Sugar Apples",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Fruits > Tamarindo",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Arracachas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Artichokes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Asparagus",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Beans",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Beets",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Borage",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Broccoli",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Brussel Sprouts",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Cabbage",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Cactus Leaves",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Cardoon",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Carrots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Cauliflower",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Celery",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Celery Roots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Corn",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Cucumbers",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Eggplants",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Fennel Bulbs",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Fiddlehead Ferns",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Gai Choy",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Gai Lan",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Garlic",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Ginger Root",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Gobo Root",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Arugula",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Beet Greens",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Bok Choy",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Chard",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Chicory",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Choy Sum",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Kale",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Lettuce",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > On Choy",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Salad Mixes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Spinach",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Greens > Yu Choy",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Horseradish Root",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Jicama",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Kohlrabi",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Leeks",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Lotus Roots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Malangas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Mushrooms",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Okra",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Onions",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Parsley Roots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Parsnips",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Peas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Peppers",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Potatoes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Radishes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Rhubarb",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Shallots",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Sprouts",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Squashes & Gourds",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Sugar Cane",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Sunchokes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Sweet Potatoes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Tamarillos",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Taro Root",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Tomatoes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Turnips & Rutabagas",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Vegetable Mixes",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Water Chestnuts",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Watercress",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Wheatgrass",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Yams",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fresh & Frozen Vegetables > Yuca Root",
  "Food, Beverages & Tobacco > Food Items > Fruits & Vegetables > Fruit Sauces",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Amaranth",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Barley",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Buckwheat",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Cereal & Granola",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Couscous",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Millet",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Oats, Grits & Hot Cereal",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Quinoa",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Rice",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Rye",
  "Food, Beverages & Tobacco > Food Items > Grains, Rice & Cereal > Wheat",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Eggs",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Eggs > Egg Whites",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Eggs > Liquid & Frozen Eggs",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Eggs > Prepared Eggs",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Eggs > Whole Eggs",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Meat",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Meat > Canned Meats",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Meat > Fresh & Frozen Meats",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Meat > Lunch & Deli Meats",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Seafood",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Seafood > Canned Seafood",
  "Food, Beverages & Tobacco > Food Items > Meat, Seafood & Eggs > Seafood > Fresh & Frozen Seafood",
  "Food, Beverages & Tobacco > Food Items > Nuts & Seeds",
  "Food, Beverages & Tobacco > Food Items > Pasta & Noodles",
  "Food, Beverages & Tobacco > Food Items > Prepared Foods",
  "Food, Beverages & Tobacco > Food Items > Prepared Foods > Prepared Appetizers & Side Dishes",
  "Food, Beverages & Tobacco > Food Items > Prepared Foods > Prepared Meals & Entrées",
  "Food, Beverages & Tobacco > Food Items > Seasonings & Spices",
  "Food, Beverages & Tobacco > Food Items > Seasonings & Spices > Herbs & Spices",
  "Food, Beverages & Tobacco > Food Items > Seasonings & Spices > MSG",
  "Food, Beverages & Tobacco > Food Items > Seasonings & Spices > Pepper",
  "Food, Beverages & Tobacco > Food Items > Seasonings & Spices > Salt",
  "Food, Beverages & Tobacco > Food Items > Snack Foods",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Breadsticks",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Cereal & Granola Bars",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Cereal & Granola Bars > Cereal Bars",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Cereal & Granola Bars > Granola Bars",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Cheese Puffs",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Chips",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Crackers",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Croutons",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Fruit Snacks",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Jerky",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Popcorn",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Pork Rinds",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Pretzels",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Pudding & Gelatin Snacks",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Puffed Rice Cakes",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Salad Toppings",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Sesame Sticks",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Snack Cakes",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Sticky Rice Cakes",
  "Food, Beverages & Tobacco > Food Items > Snack Foods > Trail & Snack Mixes",
  "Food, Beverages & Tobacco > Food Items > Soups & Broths",
  "Food, Beverages & Tobacco > Food Items > Tofu, Soy & Vegetarian Products",
  "Food, Beverages & Tobacco > Food Items > Tofu, Soy & Vegetarian Products > Cheese Alternatives",
  "Food, Beverages & Tobacco > Food Items > Tofu, Soy & Vegetarian Products > Meat Alternatives",
  "Food, Beverages & Tobacco > Food Items > Tofu, Soy & Vegetarian Products > Seitan",
  "Food, Beverages & Tobacco > Food Items > Tofu, Soy & Vegetarian Products > Tempeh",
  "Food, Beverages & Tobacco > Food Items > Tofu, Soy & Vegetarian Products > Tofu",
  "Food, Beverages & Tobacco > Tobacco Products",
  "Food, Beverages & Tobacco > Tobacco Products > Chewing Tobacco",
  "Food, Beverages & Tobacco > Tobacco Products > Cigarettes",
  "Food, Beverages & Tobacco > Tobacco Products > Cigars",
  "Food, Beverages & Tobacco > Tobacco Products > Loose Tobacco",
  "Food, Beverages & Tobacco > Tobacco Products > Smoking Pipes",
  "Food, Beverages & Tobacco > Tobacco Products > Vaporizers & Electronic Cigarettes",
  "Food, Beverages & Tobacco > Tobacco Products > Vaporizers & Electronic Cigarettes > Electronic Cigarettes",
  "Food, Beverages & Tobacco > Tobacco Products > Vaporizers & Electronic Cigarettes > Vaporizers",
  "Furniture",
  "Furniture > Baby & Toddler Furniture",
  "Furniture > Baby & Toddler Furniture > Baby & Toddler Furniture Sets",
  "Furniture > Baby & Toddler Furniture > Bassinet & Cradle Accessories",
  "Furniture > Baby & Toddler Furniture > Bassinets & Cradles",
  "Furniture > Baby & Toddler Furniture > Changing Tables",
  "Furniture > Baby & Toddler Furniture > Crib & Toddler Bed Accessories",
  "Furniture > Baby & Toddler Furniture > Cribs & Toddler Beds",
  "Furniture > Baby & Toddler Furniture > High Chair & Booster Seat Accessories",
  "Furniture > Baby & Toddler Furniture > High Chairs & Booster Seats",
  "Furniture > Beds & Accessories",
  "Furniture > Beds & Accessories > Bed & Bed Frame Accessories",
  "Furniture > Beds & Accessories > Beds & Bed Frames",
  "Furniture > Beds & Accessories > Headboards & Footboards",
  "Furniture > Beds & Accessories > Mattress Foundations",
  "Furniture > Beds & Accessories > Mattresses",
  "Furniture > Benches",
  "Furniture > Benches > Kitchen & Dining Benches",
  "Furniture > Benches > Storage & Entryway Benches",
  "Furniture > Benches > Vanity Benches",
  "Furniture > Cabinets & Storage",
  "Furniture > Cabinets & Storage > Armoires & Wardrobes",
  "Furniture > Cabinets & Storage > Buffets & Sideboards",
  "Furniture > Cabinets & Storage > China Cabinets & Hutches",
  "Furniture > Cabinets & Storage > Dressers",
  "Furniture > Cabinets & Storage > File Cabinets",
  "Furniture > Cabinets & Storage > Ironing Centers",
  "Furniture > Cabinets & Storage > Kitchen Cabinets",
  "Furniture > Cabinets & Storage > Magazine Racks",
  "Furniture > Cabinets & Storage > Media Storage Cabinets & Racks",
  "Furniture > Cabinets & Storage > Storage Cabinets & Lockers",
  "Furniture > Cabinets & Storage > Storage Chests",
  "Furniture > Cabinets & Storage > Vanities",
  "Furniture > Cabinets & Storage > Wine & Liquor Cabinets",
  "Furniture > Cabinets & Storage > Wine Racks",
  "Furniture > Carts & Islands",
  "Furniture > Carts & Islands > Kitchen & Dining Carts",
  "Furniture > Carts & Islands > Kitchen Islands",
  "Furniture > Chair Accessories",
  "Furniture > Chair Accessories > Hanging Chair Replacement Parts",
  "Furniture > Chairs",
  "Furniture > Chairs > Arm Chairs, Recliners & Sleeper Chairs",
  "Furniture > Chairs > Bean Bag Chairs",
  "Furniture > Chairs > Chaises",
  "Furniture > Chairs > Electric Massaging Chairs",
  "Furniture > Chairs > Floor Chairs",
  "Furniture > Chairs > Folding Chairs & Stools",
  "Furniture > Chairs > Gaming Chairs",
  "Furniture > Chairs > Hanging Chairs",
  "Furniture > Chairs > Kitchen & Dining Room Chairs",
  "Furniture > Chairs > Rocking Chairs",
  "Furniture > Chairs > Slipper Chairs",
  "Furniture > Chairs > Table & Bar Stools",
  "Furniture > Entertainment Centers & TV Stands",
  "Furniture > Furniture Sets",
  "Furniture > Furniture Sets > Bathroom Furniture Sets",
  "Furniture > Furniture Sets > Bedroom Furniture Sets",
  "Furniture > Furniture Sets > Kitchen & Dining Furniture Sets",
  "Furniture > Furniture Sets > Living Room Furniture Sets",
  "Furniture > Futon Frames",
  "Furniture > Futon Pads",
  "Furniture > Futons",
  "Furniture > Office Furniture",
  "Furniture > Office Furniture > Desks",
  "Furniture > Office Furniture > Office Chairs",
  "Furniture > Office Furniture > Office Furniture Sets",
  "Furniture > Office Furniture > Workspace Tables",
  "Furniture > Office Furniture > Workstations & Cubicles",
  "Furniture > Office Furniture Accessories",
  "Furniture > Office Furniture Accessories > Desk Parts & Accessories",
  "Furniture > Office Furniture Accessories > Office Chair Accessories",
  "Furniture > Office Furniture Accessories > Workstation & Cubicle Accessories",
  "Furniture > Ottomans",
  "Furniture > Outdoor Furniture",
  "Furniture > Outdoor Furniture > Outdoor Beds",
  "Furniture > Outdoor Furniture > Outdoor Furniture Sets",
  "Furniture > Outdoor Furniture > Outdoor Ottomans",
  "Furniture > Outdoor Furniture > Outdoor Seating",
  "Furniture > Outdoor Furniture > Outdoor Storage Boxes",
  "Furniture > Outdoor Furniture > Outdoor Tables",
  "Furniture > Outdoor Furniture Accessories",
  "Furniture > Outdoor Furniture Accessories > Outdoor Furniture Covers",
  "Furniture > Room Divider Accessories",
  "Furniture > Room Dividers",
  "Furniture > Shelving",
  "Furniture > Shelving > Bookcases & Standing Shelves",
  "Furniture > Shelving > Wall Shelves & Ledges",
  "Furniture > Shelving Accessories",
  "Furniture > Shelving Accessories > Replacement Shelves",
  "Furniture > Sofa Accessories",
  "Furniture > Sofa Accessories > Chair & Sofa Supports",
  "Furniture > Sofa Accessories > Sectional Sofa Units",
  "Furniture > Sofas",
  "Furniture > Table Accessories",
  "Furniture > Table Accessories > Table Legs",
  "Furniture > Table Accessories > Table Tops",
  "Furniture > Tables",
  "Furniture > Tables > Accent Tables",
  "Furniture > Tables > Activity Tables",
  "Furniture > Tables > Folding Tables",
  "Furniture > Tables > Kitchen & Dining Room Tables",
  "Furniture > Tables > Kotatsu",
  "Furniture > Tables > Nightstands",
  "Furniture > Tables > Poker & Game Tables",
  "Furniture > Tables > Sewing Machine Tables",
  "Hardware",
  "Hardware > Building Consumables",
  "Hardware > Building Consumables > Chemicals",
  "Hardware > Building Consumables > Hardware Glue & Adhesives",
  "Hardware > Building Consumables > Hardware Tape",
  "Hardware > Building Consumables > Lubricants",
  "Hardware > Building Consumables > Masonry Consumables",
  "Hardware > Building Consumables > Painting Consumables",
  "Hardware > Building Consumables > Plumbing Primer",
  "Hardware > Building Consumables > Protective Coatings & Sealants",
  "Hardware > Building Consumables > Solder & Flux",
  "Hardware > Building Consumables > Solvents, Strippers & Thinners",
  "Hardware > Building Consumables > Wall Patching Compounds & Plaster",
  "Hardware > Building Materials",
  "Hardware > Building Materials > Countertops",
  "Hardware > Building Materials > Door Hardware",
  "Hardware > Building Materials > Doors",
  "Hardware > Building Materials > Drywall",
  "Hardware > Building Materials > Flooring & Carpet",
  "Hardware > Building Materials > Glass",
  "Hardware > Building Materials > Handrails & Railing Systems",
  "Hardware > Building Materials > Hatches",
  "Hardware > Building Materials > Insulation",
  "Hardware > Building Materials > Lumber & Sheet Stock",
  "Hardware > Building Materials > Molding",
  "Hardware > Building Materials > Rebar & Remesh",
  "Hardware > Building Materials > Roofing",
  "Hardware > Building Materials > Shutters",
  "Hardware > Building Materials > Siding",
  "Hardware > Building Materials > Sound Dampening Panels & Foam",
  "Hardware > Building Materials > Staircases",
  "Hardware > Building Materials > Wall & Ceiling Tile",
  "Hardware > Building Materials > Wall Paneling",
  "Hardware > Building Materials > Weather Stripping & Weatherization Supplies",
  "Hardware > Building Materials > Window Hardware",
  "Hardware > Building Materials > Windows",
  "Hardware > Fencing & Barriers",
  "Hardware > Fencing & Barriers > Fence & Gate Accessories",
  "Hardware > Fencing & Barriers > Fence Panels",
  "Hardware > Fencing & Barriers > Fence Pickets",
  "Hardware > Fencing & Barriers > Fence Posts & Rails",
  "Hardware > Fencing & Barriers > Garden Borders & Edging",
  "Hardware > Fencing & Barriers > Gates",
  "Hardware > Fencing & Barriers > Lattice",
  "Hardware > Fencing & Barriers > Safety & Crowd Control Barriers",
  "Hardware > Fuel",
  "Hardware > Fuel > Home Heating Oil",
  "Hardware > Fuel > Kerosene",
  "Hardware > Fuel > Propane",
  "Hardware > Fuel Containers & Tanks",
  "Hardware > Hardware Accessories",
  "Hardware > Hardware Accessories > Brackets & Reinforcement Braces",
  "Hardware > Hardware Accessories > Cabinet Hardware",
  "Hardware > Hardware Accessories > Casters",
  "Hardware > Hardware Accessories > Chain, Wire & Rope",
  "Hardware > Hardware Accessories > Coils",
  "Hardware > Hardware Accessories > Concrete Molds",
  "Hardware > Hardware Accessories > Dowel Pins & Rods",
  "Hardware > Hardware Accessories > Drawer Slides",
  "Hardware > Hardware Accessories > Drop Cloths",
  "Hardware > Hardware Accessories > Filters & Screens",
  "Hardware > Hardware Accessories > Flagging & Caution Tape",
  "Hardware > Hardware Accessories > Gas Hoses",
  "Hardware > Hardware Accessories > Ground Spikes",
  "Hardware > Hardware Accessories > Hardware Fasteners",
  "Hardware > Hardware Accessories > Hinges",
  "Hardware > Hardware Accessories > Hooks, Buckles & Fasteners",
  "Hardware > Hardware Accessories > Lubrication Hoses",
  "Hardware > Hardware Accessories > Metal Casting Molds",
  "Hardware > Hardware Accessories > Moving & Soundproofing Blankets & Covers",
  "Hardware > Hardware Accessories > Pneumatic Hoses",
  "Hardware > Hardware Accessories > Post Base Plates",
  "Hardware > Hardware Accessories > Springs",
  "Hardware > Hardware Accessories > Tarps",
  "Hardware > Hardware Accessories > Tool Storage & Organization",
  "Hardware > Hardware Accessories > Wall Jacks & Braces",
  "Hardware > Hardware Pumps",
  "Hardware > Hardware Pumps > Home Appliance Pumps",
  "Hardware > Hardware Pumps > Pool, Fountain & Pond Pumps",
  "Hardware > Hardware Pumps > Sprinkler, Booster & Irrigation System Pumps",
  "Hardware > Hardware Pumps > Sump, Sewage & Effluent Pumps",
  "Hardware > Hardware Pumps > Utility Pumps",
  "Hardware > Hardware Pumps > Well Pumps & Systems",
  "Hardware > Heating, Ventilation & Air Conditioning",
  "Hardware > Heating, Ventilation & Air Conditioning > Air & Filter Dryers",
  "Hardware > Heating, Ventilation & Air Conditioning > Air Ducts",
  "Hardware > Heating, Ventilation & Air Conditioning > HVAC Controls",
  "Hardware > Heating, Ventilation & Air Conditioning > Vents & Flues",
  "Hardware > Locks & Keys",
  "Hardware > Locks & Keys > Key Blanks",
  "Hardware > Locks & Keys > Key Caps",
  "Hardware > Locks & Keys > Key Card Entry Systems",
  "Hardware > Locks & Keys > Locks & Latches",
  "Hardware > Plumbing",
  "Hardware > Plumbing > Plumbing Fittings & Supports",
  "Hardware > Plumbing > Plumbing Fixture Hardware & Parts",
  "Hardware > Plumbing > Plumbing Fixtures",
  "Hardware > Plumbing > Plumbing Hoses & Supply Lines",
  "Hardware > Plumbing > Plumbing Pipes",
  "Hardware > Plumbing > Plumbing Repair Kits",
  "Hardware > Plumbing > Water Dispensing & Filtration",
  "Hardware > Plumbing > Water Levelers",
  "Hardware > Plumbing > Water Timers",
  "Hardware > Plumbing > Well Supplies",
  "Hardware > Power & Electrical Supplies",
  "Hardware > Power & Electrical Supplies > Armatures, Rotors & Stators",
  "Hardware > Power & Electrical Supplies > Ballasts & Starters",
  "Hardware > Power & Electrical Supplies > Carbon Brushes",
  "Hardware > Power & Electrical Supplies > Circuit Breaker Panels",
  "Hardware > Power & Electrical Supplies > Conduit & Housings",
  "Hardware > Power & Electrical Supplies > Electrical Motors",
  "Hardware > Power & Electrical Supplies > Electrical Mount Boxes & Brackets",
  "Hardware > Power & Electrical Supplies > Electrical Plug Caps",
  "Hardware > Power & Electrical Supplies > Electrical Switches",
  "Hardware > Power & Electrical Supplies > Electrical Wires & Cable",
  "Hardware > Power & Electrical Supplies > Extension Cord Accessories",
  "Hardware > Power & Electrical Supplies > Extension Cords",
  "Hardware > Power & Electrical Supplies > Generator Accessories",
  "Hardware > Power & Electrical Supplies > Generators",
  "Hardware > Power & Electrical Supplies > Home Automation Kits",
  "Hardware > Power & Electrical Supplies > Phone & Data Jacks",
  "Hardware > Power & Electrical Supplies > Power Converters",
  "Hardware > Power & Electrical Supplies > Power Inlets",
  "Hardware > Power & Electrical Supplies > Power Inverters",
  "Hardware > Power & Electrical Supplies > Power Outlets & Sockets",
  "Hardware > Power & Electrical Supplies > Solar Energy Kits",
  "Hardware > Power & Electrical Supplies > Solar Panels",
  "Hardware > Power & Electrical Supplies > Voltage Transformers & Regulators",
  "Hardware > Power & Electrical Supplies > Wall Plates & Covers",
  "Hardware > Power & Electrical Supplies > Wall Socket Controls & Sensors",
  "Hardware > Power & Electrical Supplies > Wire Caps & Nuts",
  "Hardware > Power & Electrical Supplies > Wire Terminals & Connectors",
  "Hardware > Small Engines",
  "Hardware > Storage Tanks",
  "Hardware > Tool Accessories",
  "Hardware > Tool Accessories > Abrasive Blaster Accessories",
  "Hardware > Tool Accessories > Axe Accessories",
  "Hardware > Tool Accessories > Cutter Accessories",
  "Hardware > Tool Accessories > Drill & Screwdriver Accessories",
  "Hardware > Tool Accessories > Driver Accessories",
  "Hardware > Tool Accessories > Flashlight Accessories",
  "Hardware > Tool Accessories > Grinder Accessories",
  "Hardware > Tool Accessories > Hammer Accessories",
  "Hardware > Tool Accessories > Industrial Staples",
  "Hardware > Tool Accessories > Jigs",
  "Hardware > Tool Accessories > Magnetizers & Demagnetizers",
  "Hardware > Tool Accessories > Mattock & Pickaxe Accessories",
  "Hardware > Tool Accessories > Measuring Tool & Sensor Accessories",
  "Hardware > Tool Accessories > Mixing Tool Paddles",
  "Hardware > Tool Accessories > Paint Tool Accessories",
  "Hardware > Tool Accessories > Power Tool Batteries",
  "Hardware > Tool Accessories > Power Tool Chargers",
  "Hardware > Tool Accessories > Router Accessories",
  "Hardware > Tool Accessories > Sanding Accessories",
  "Hardware > Tool Accessories > Saw Accessories",
  "Hardware > Tool Accessories > Shaper Accessories",
  "Hardware > Tool Accessories > Soldering Iron Accessories",
  "Hardware > Tool Accessories > Tool Blades",
  "Hardware > Tool Accessories > Tool Handle Wedges",
  "Hardware > Tool Accessories > Tool Safety Tethers",
  "Hardware > Tool Accessories > Tool Sockets",
  "Hardware > Tool Accessories > Tool Stands",
  "Hardware > Tool Accessories > Wedge Tools",
  "Hardware > Tool Accessories > Welding Accessories",
  "Hardware > Tools",
  "Hardware > Tools > Abrasive Blasters",
  "Hardware > Tools > Anvils",
  "Hardware > Tools > Axes",
  "Hardware > Tools > Carpentry Jointers",
  "Hardware > Tools > Carving Chisels & Gouges",
  "Hardware > Tools > Caulking Tools",
  "Hardware > Tools > Chimney Brushes",
  "Hardware > Tools > Compactors",
  "Hardware > Tools > Compressors",
  "Hardware > Tools > Concrete Brooms",
  "Hardware > Tools > Cutters",
  "Hardware > Tools > Deburrers",
  "Hardware > Tools > Dollies & Hand Trucks",
  "Hardware > Tools > Drills",
  "Hardware > Tools > Electrician Fish Tape",
  "Hardware > Tools > Flashlights & Headlamps",
  "Hardware > Tools > Grease Guns",
  "Hardware > Tools > Grinders",
  "Hardware > Tools > Grips",
  "Hardware > Tools > Hammers",
  "Hardware > Tools > Handheld Power Mixers",
  "Hardware > Tools > Hardware Torches",
  "Hardware > Tools > Heat Guns",
  "Hardware > Tools > Impact Wrenches & Drivers",
  "Hardware > Tools > Industrial Vibrators",
  "Hardware > Tools > Inspection Mirrors",
  "Hardware > Tools > Ladders & Scaffolding",
  "Hardware > Tools > Lathes",
  "Hardware > Tools > Light Bulb Changers",
  "Hardware > Tools > Lighters & Matches",
  "Hardware > Tools > Log Splitters",
  "Hardware > Tools > Magnetic Sweepers",
  "Hardware > Tools > Marking Tools",
  "Hardware > Tools > Masonry Tools",
  "Hardware > Tools > Mattocks & Pickaxes",
  "Hardware > Tools > Measuring Tools & Sensors",
  "Hardware > Tools > Milling Machines",
  "Hardware > Tools > Multifunction Power Tools",
  "Hardware > Tools > Nail Pullers",
  "Hardware > Tools > Nailers & Staplers",
  "Hardware > Tools > Oil Filter Drains",
  "Hardware > Tools > Paint Tools",
  "Hardware > Tools > Pickup Tools",
  "Hardware > Tools > Pipe & Bar Benders",
  "Hardware > Tools > Pipe & Tube Cleaners",
  "Hardware > Tools > Pipe Brushes",
  "Hardware > Tools > Planers",
  "Hardware > Tools > Planes",
  "Hardware > Tools > Pliers",
  "Hardware > Tools > Plungers",
  "Hardware > Tools > Polishers & Buffers",
  "Hardware > Tools > Post Hole Diggers",
  "Hardware > Tools > Pry Bars",
  "Hardware > Tools > Punches & Awls",
  "Hardware > Tools > Putty Knives & Scrapers",
  "Hardware > Tools > Reamers",
  "Hardware > Tools > Riveting Tools",
  "Hardware > Tools > Routing Tools",
  "Hardware > Tools > Sanders",
  "Hardware > Tools > Sanding Blocks",
  "Hardware > Tools > Saw Horses",
  "Hardware > Tools > Saws",
  "Hardware > Tools > Screwdrivers",
  "Hardware > Tools > Shapers",
  "Hardware > Tools > Sharpeners",
  "Hardware > Tools > Socket Drivers",
  "Hardware > Tools > Soldering Irons",
  "Hardware > Tools > Tap Reseaters",
  "Hardware > Tools > Taps & Dies",
  "Hardware > Tools > Threading Machines",
  "Hardware > Tools > Tool Clamps & Vises",
  "Hardware > Tools > Tool Files",
  "Hardware > Tools > Tool Keys",
  "Hardware > Tools > Tool Knives",
  "Hardware > Tools > Tool Sets",
  "Hardware > Tools > Welding Guns & Plasma Cutters",
  "Hardware > Tools > Wire & Cable Hand Tools",
  "Hardware > Tools > Work Lights",
  "Hardware > Tools > Wrenches",
  "Health & Beauty",
  "Health & Beauty > Health Care",
  "Health & Beauty > Health Care > Acupuncture",
  "Health & Beauty > Health Care > Bed Pans",
  "Health & Beauty > Health Care > Biometric Monitor Accessories",
  "Health & Beauty > Health Care > Biometric Monitors",
  "Health & Beauty > Health Care > Condoms",
  "Health & Beauty > Health Care > Conductivity Gels & Lotions",
  "Health & Beauty > Health Care > Contraceptive Cases",
  "Health & Beauty > Health Care > First Aid",
  "Health & Beauty > Health Care > Fitness & Nutrition",
  "Health & Beauty > Health Care > Hearing Aids",
  "Health & Beauty > Health Care > Incontinence Aids",
  "Health & Beauty > Health Care > Light Therapy Lamps",
  "Health & Beauty > Health Care > Medical Alarm Systems",
  "Health & Beauty > Health Care > Medical Identification Tags & Jewelry",
  "Health & Beauty > Health Care > Medical Tests",
  "Health & Beauty > Health Care > Medicine & Drugs",
  "Health & Beauty > Health Care > Mobility & Accessibility",
  "Health & Beauty > Health Care > Occupational & Physical Therapy Equipment",
  "Health & Beauty > Health Care > Pillboxes",
  "Health & Beauty > Health Care > Respiratory Care",
  "Health & Beauty > Health Care > Specimen Cups",
  "Health & Beauty > Health Care > Spermicides",
  "Health & Beauty > Health Care > Stump Shrinkers",
  "Health & Beauty > Health Care > Supports & Braces",
  "Health & Beauty > Health Care > Surgical Lubricants",
  "Health & Beauty > Jewelry Cleaning & Care",
  "Health & Beauty > Jewelry Cleaning & Care > Jewelry Cleaning Solutions & Polishes",
  "Health & Beauty > Jewelry Cleaning & Care > Jewelry Cleaning Tools",
  "Health & Beauty > Jewelry Cleaning & Care > Jewelry Holders",
  "Health & Beauty > Jewelry Cleaning & Care > Jewelry Steam Cleaners",
  "Health & Beauty > Jewelry Cleaning & Care > Watch Repair Kits",
  "Health & Beauty > Personal Care",
  "Health & Beauty > Personal Care > Back Care",
  "Health & Beauty > Personal Care > Cosmetics",
  "Health & Beauty > Personal Care > Cotton Balls",
  "Health & Beauty > Personal Care > Cotton Swabs",
  "Health & Beauty > Personal Care > Deodorant & Anti-Perspirant",
  "Health & Beauty > Personal Care > Ear Care",
  "Health & Beauty > Personal Care > Enema Kits & Supplies",
  "Health & Beauty > Personal Care > Feminine Sanitary Supplies",
  "Health & Beauty > Personal Care > Foot Care",
  "Health & Beauty > Personal Care > Hair Care",
  "Health & Beauty > Personal Care > Massage & Relaxation",
  "Health & Beauty > Personal Care > Oral Care",
  "Health & Beauty > Personal Care > Personal Lubricants",
  "Health & Beauty > Personal Care > Shaving & Grooming",
  "Health & Beauty > Personal Care > Sleeping Aids",
  "Health & Beauty > Personal Care > Spray Tanning Tents",
  "Health & Beauty > Personal Care > Tanning Beds",
  "Health & Beauty > Personal Care > Tweezers",
  "Health & Beauty > Personal Care > Vision Care",
  "Home & Garden",
  "Home & Garden > Bathroom Accessories",
  "Home & Garden > Bathroom Accessories > Bath Caddies",
  "Home & Garden > Bathroom Accessories > Bath Mats & Rugs",
  "Home & Garden > Bathroom Accessories > Bath Pillows",
  "Home & Garden > Bathroom Accessories > Bathroom Accessory Mounts",
  "Home & Garden > Bathroom Accessories > Bathroom Accessory Sets",
  "Home & Garden > Bathroom Accessories > Facial Tissue Holders",
  "Home & Garden > Bathroom Accessories > Hand Dryer Accessories",
  "Home & Garden > Bathroom Accessories > Hand Dryers",
  "Home & Garden > Bathroom Accessories > Medicine Cabinets",
  "Home & Garden > Bathroom Accessories > Robe Hooks",
  "Home & Garden > Bathroom Accessories > Safety Grab Bars",
  "Home & Garden > Bathroom Accessories > Shower Curtain Rings",
  "Home & Garden > Bathroom Accessories > Shower Curtains",
  "Home & Garden > Bathroom Accessories > Shower Rods",
  "Home & Garden > Bathroom Accessories > Soap & Lotion Dispensers",
  "Home & Garden > Bathroom Accessories > Soap Dishes & Holders",
  "Home & Garden > Bathroom Accessories > Toilet Brush Replacement Heads",
  "Home & Garden > Bathroom Accessories > Toilet Brushes & Holders",
  "Home & Garden > Bathroom Accessories > Toilet Paper Holders",
  "Home & Garden > Bathroom Accessories > Toothbrush Holders",
  "Home & Garden > Bathroom Accessories > Towel Racks & Holders",
  "Home & Garden > Business & Home Security",
  "Home & Garden > Business & Home Security > Dummy Surveillance Cameras",
  "Home & Garden > Business & Home Security > Home Alarm Systems",
  "Home & Garden > Business & Home Security > Motion Sensors",
  "Home & Garden > Business & Home Security > Safety & Security Mirrors",
  "Home & Garden > Business & Home Security > Security Lights",
  "Home & Garden > Business & Home Security > Security Monitors & Recorders",
  "Home & Garden > Business & Home Security > Security Safe Accessories",
  "Home & Garden > Business & Home Security > Security Safes",
  "Home & Garden > Business & Home Security > Security System Sensors",
  "Home & Garden > Decor",
  "Home & Garden > Decor > Address Signs",
  "Home & Garden > Decor > Artificial Flora",
  "Home & Garden > Decor > Artificial Food",
  "Home & Garden > Decor > Artwork",
  "Home & Garden > Decor > Backrest Pillows",
  "Home & Garden > Decor > Baskets",
  "Home & Garden > Decor > Bird & Wildlife Feeder Accessories",
  "Home & Garden > Decor > Bird & Wildlife Feeders",
  "Home & Garden > Decor > Bird & Wildlife House Accessories",
  "Home & Garden > Decor > Bird & Wildlife Houses",
  "Home & Garden > Decor > Bird Baths",
  "Home & Garden > Decor > Bookends",
  "Home & Garden > Decor > Cardboard Cutouts",
  "Home & Garden > Decor > Chair & Sofa Cushions",
  "Home & Garden > Decor > Clock Parts",
  "Home & Garden > Decor > Clocks",
  "Home & Garden > Decor > Coat & Hat Racks",
  "Home & Garden > Decor > Decorative Bells",
  "Home & Garden > Decor > Decorative Bottles",
  "Home & Garden > Decor > Decorative Bowls",
  "Home & Garden > Decor > Decorative Jars",
  "Home & Garden > Decor > Decorative Plaques",
  "Home & Garden > Decor > Decorative Plates",
  "Home & Garden > Decor > Decorative Trays",
  "Home & Garden > Decor > Door Mats",
  "Home & Garden > Decor > Dreamcatchers",
  "Home & Garden > Decor > Dried Flowers",
  "Home & Garden > Decor > Ecospheres",
  "Home & Garden > Decor > Figurines",
  "Home & Garden > Decor > Finials",
  "Home & Garden > Decor > Flag & Windsock Accessories",
  "Home & Garden > Decor > Flags & Windsocks",
  "Home & Garden > Decor > Flameless Candles",
  "Home & Garden > Decor > Fountains & Ponds",
  "Home & Garden > Decor > Garden & Stepping Stones",
  "Home & Garden > Decor > Growth Charts",
  "Home & Garden > Decor > Home Decor Decals",
  "Home & Garden > Decor > Home Fragrance Accessories",
  "Home & Garden > Decor > Home Fragrances",
  "Home & Garden > Decor > Hourglasses",
  "Home & Garden > Decor > House Numbers & Letters",
  "Home & Garden > Decor > Lawn Ornaments & Garden Sculptures",
  "Home & Garden > Decor > Mail Slots",
  "Home & Garden > Decor > Mailbox Accessories",
  "Home & Garden > Decor > Mailboxes",
  "Home & Garden > Decor > Mirrors",
  "Home & Garden > Decor > Music Boxes",
  "Home & Garden > Decor > Napkin Rings",
  "Home & Garden > Decor > Novelty Signs",
  "Home & Garden > Decor > Ottoman Cushions",
  "Home & Garden > Decor > Picture Frames",
  "Home & Garden > Decor > Piggy Banks & Money Jars",
  "Home & Garden > Decor > Rain Chains",
  "Home & Garden > Decor > Rain Gauges",
  "Home & Garden > Decor > Refrigerator Magnets",
  "Home & Garden > Decor > Rugs",
  "Home & Garden > Decor > Seasonal & Holiday Decorations",
  "Home & Garden > Decor > Shadow Boxes",
  "Home & Garden > Decor > Slipcovers",
  "Home & Garden > Decor > Snow Globes",
  "Home & Garden > Decor > Suncatchers",
  "Home & Garden > Decor > Sundials",
  "Home & Garden > Decor > Throw Pillows",
  "Home & Garden > Decor > Trunks",
  "Home & Garden > Decor > Vase Fillers & Table Scatters",
  "Home & Garden > Decor > Vases",
  "Home & Garden > Decor > Wallpaper",
  "Home & Garden > Decor > Weather Vanes & Roof Decor",
  "Home & Garden > Decor > Wind Chimes",
  "Home & Garden > Decor > Wind Wheels & Spinners",
  "Home & Garden > Decor > Window Magnets",
  "Home & Garden > Decor > Window Treatment Accessories",
  "Home & Garden > Decor > Window Treatments",
  "Home & Garden > Decor > World Globes",
  "Home & Garden > Decor > Wreaths & Garlands",
  "Home & Garden > Emergency Preparedness",
  "Home & Garden > Emergency Preparedness > Earthquake Alarms",
  "Home & Garden > Emergency Preparedness > Emergency Blankets",
  "Home & Garden > Emergency Preparedness > Emergency Food Kits",
  "Home & Garden > Emergency Preparedness > Emergency Tools & Kits",
  "Home & Garden > Emergency Preparedness > Furniture Anchors",
  "Home & Garden > Fireplace & Wood Stove Accessories",
  "Home & Garden > Fireplace & Wood Stove Accessories > Bellows",
  "Home & Garden > Fireplace & Wood Stove Accessories > Fireplace & Wood Stove Grates",
  "Home & Garden > Fireplace & Wood Stove Accessories > Fireplace Andirons",
  "Home & Garden > Fireplace & Wood Stove Accessories > Fireplace Reflectors",
  "Home & Garden > Fireplace & Wood Stove Accessories > Fireplace Screens",
  "Home & Garden > Fireplace & Wood Stove Accessories > Fireplace Tools",
  "Home & Garden > Fireplace & Wood Stove Accessories > Firewood & Fuel",
  "Home & Garden > Fireplace & Wood Stove Accessories > Hearth Pads",
  "Home & Garden > Fireplace & Wood Stove Accessories > Log Rack & Carrier Accessories",
  "Home & Garden > Fireplace & Wood Stove Accessories > Log Racks & Carriers",
  "Home & Garden > Fireplace & Wood Stove Accessories > Wood Stove Fans & Blowers",
  "Home & Garden > Fireplaces",
  "Home & Garden > Flood, Fire & Gas Safety",
  "Home & Garden > Flood, Fire & Gas Safety > Fire Alarm Control Panels",
  "Home & Garden > Flood, Fire & Gas Safety > Fire Alarms",
  "Home & Garden > Flood, Fire & Gas Safety > Fire Extinguisher & Equipment Storage",
  "Home & Garden > Flood, Fire & Gas Safety > Fire Extinguishers",
  "Home & Garden > Flood, Fire & Gas Safety > Fire Sprinklers",
  "Home & Garden > Flood, Fire & Gas Safety > Heat Detectors",
  "Home & Garden > Flood, Fire & Gas Safety > Smoke & Carbon Monoxide Detectors",
  "Home & Garden > Flood, Fire & Gas Safety > Water & Flood Detectors",
  "Home & Garden > Household Appliance Accessories",
  "Home & Garden > Household Appliance Accessories > Air Conditioner Accessories",
  "Home & Garden > Household Appliance Accessories > Air Purifier Accessories",
  "Home & Garden > Household Appliance Accessories > Dehumidifier Accessories",
  "Home & Garden > Household Appliance Accessories > Fan Accessories",
  "Home & Garden > Household Appliance Accessories > Floor & Steam Cleaner Accessories",
  "Home & Garden > Household Appliance Accessories > Furnace & Boiler Accessories",
  "Home & Garden > Household Appliance Accessories > Heating Radiator Accessories",
  "Home & Garden > Household Appliance Accessories > Humidifier Accessories",
  "Home & Garden > Household Appliance Accessories > Laundry Appliance Accessories",
  "Home & Garden > Household Appliance Accessories > Patio Heater Accessories",
  "Home & Garden > Household Appliance Accessories > Vacuum Accessories",
  "Home & Garden > Household Appliance Accessories > Water Heater Accessories",
  "Home & Garden > Household Appliances",
  "Home & Garden > Household Appliances > Climate Control Appliances",
  "Home & Garden > Household Appliances > Floor & Carpet Dryers",
  "Home & Garden > Household Appliances > Floor & Steam Cleaners",
  "Home & Garden > Household Appliances > Floor Polishers & Buffers",
  "Home & Garden > Household Appliances > Futon Dryers",
  "Home & Garden > Household Appliances > Garage Door Keypads & Remotes",
  "Home & Garden > Household Appliances > Garage Door Openers",
  "Home & Garden > Household Appliances > Laundry Appliances",
  "Home & Garden > Household Appliances > Ultrasonic Cleaners",
  "Home & Garden > Household Appliances > Vacuums",
  "Home & Garden > Household Appliances > Wallpaper Steamers",
  "Home & Garden > Household Appliances > Water Heaters",
  "Home & Garden > Household Supplies",
  "Home & Garden > Household Supplies > Drawer & Shelf Liners",
  "Home & Garden > Household Supplies > Floor Protection Films & Runners",
  "Home & Garden > Household Supplies > Furniture Floor Protectors",
  "Home & Garden > Household Supplies > Garage Floor Mats",
  "Home & Garden > Household Supplies > Garbage Bags",
  "Home & Garden > Household Supplies > Household Cleaning Supplies",
  "Home & Garden > Household Supplies > Household Paper Products",
  "Home & Garden > Household Supplies > Household Thermometers",
  "Home & Garden > Household Supplies > Laundry Supplies",
  "Home & Garden > Household Supplies > Moisture Absorbers",
  "Home & Garden > Household Supplies > Pest Control",
  "Home & Garden > Household Supplies > Rug Pads",
  "Home & Garden > Household Supplies > Shoe Care & Tools",
  "Home & Garden > Household Supplies > Stair Treads",
  "Home & Garden > Household Supplies > Storage & Organization",
  "Home & Garden > Household Supplies > Trash Compactor Accessories",
  "Home & Garden > Household Supplies > Waste Containment",
  "Home & Garden > Household Supplies > Waste Containment Accessories",
  "Home & Garden > Kitchen & Dining",
  "Home & Garden > Kitchen & Dining > Barware",
  "Home & Garden > Kitchen & Dining > Cookware & Bakeware",
  "Home & Garden > Kitchen & Dining > Food & Beverage Carriers",
  "Home & Garden > Kitchen & Dining > Food Storage",
  "Home & Garden > Kitchen & Dining > Food Storage Accessories",
  "Home & Garden > Kitchen & Dining > Kitchen Appliance Accessories",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Beverage Warmers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Breadmakers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Chocolate Tempering Machines",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines > Drip Coffee Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines > Electric & Stovetop Espresso Pots",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines > Espresso Machines",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines > French Presses",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines > Percolators",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Coffee Makers & Espresso Machines > Vacuum Coffee Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Cooktops",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Cotton Candy Machines",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Deep Fryers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Deli Slicers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Dishwashers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Electric Griddles & Grills",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Electric Kettles",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Electric Skillets & Woks",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Fondue Pots & Sets",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers > Egg Cookers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers > Food Steamers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers > Rice Cookers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers > Slow Cookers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers > Thermal Cookers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Cookers & Steamers > Water Ovens",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Dehydrators",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Grinders & Mills",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Mixers & Blenders",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Smokers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Warmers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Warmers > Chafing Dishes",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Warmers > Food Heat Lamps",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Warmers > Rice Keepers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Food Warmers > Steam Tables",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Freezers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Frozen Drink Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Garbage Disposals",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Gas Griddles",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Hot Drink Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Hot Plates",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Ice Cream Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Ice Crushers & Shavers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Ice Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Juicers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Knife Sharpeners",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Microwave Ovens",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Milk Frothers & Steamers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Mochi Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Outdoor Grills",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Ovens",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Pasta Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Popcorn Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Portable Cooking Stoves",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Range Hoods",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Ranges",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Refrigerators",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Roaster Ovens & Rotisseries",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Soda Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Soy Milk Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Tea Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Countertop & Toaster Ovens",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Donut Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Muffin & Cupcake Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Pizza Makers & Ovens",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Pizzelle Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Pretzel Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Sandwich Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Toasters",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Tortilla & Flatbread Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Toasters & Grills > Waffle Irons",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Trash Compactors",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Vacuum Sealers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Water Coolers",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Water Filters",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Wine Fridges",
  "Home & Garden > Kitchen & Dining > Kitchen Appliances > Yogurt Makers",
  "Home & Garden > Kitchen & Dining > Kitchen Tools & Utensils",
  "Home & Garden > Kitchen & Dining > Prefabricated Kitchens & Kitchenettes",
  "Home & Garden > Kitchen & Dining > Tableware",
  "Home & Garden > Lawn & Garden",
  "Home & Garden > Lawn & Garden > Gardening",
  "Home & Garden > Lawn & Garden > Outdoor Living",
  "Home & Garden > Lawn & Garden > Outdoor Power Equipment",
  "Home & Garden > Lawn & Garden > Outdoor Power Equipment Accessories",
  "Home & Garden > Lawn & Garden > Snow Removal",
  "Home & Garden > Lawn & Garden > Watering & Irrigation",
  "Home & Garden > Lighting",
  "Home & Garden > Lighting > Emergency Lighting",
  "Home & Garden > Lighting > Floating & Submersible Lights",
  "Home & Garden > Lighting > Flood & Spot Lights",
  "Home & Garden > Lighting > In-Ground Lights",
  "Home & Garden > Lighting > Lamps",
  "Home & Garden > Lighting > Landscape Pathway Lighting",
  "Home & Garden > Lighting > Light Bulbs",
  "Home & Garden > Lighting > Light Ropes & Strings",
  "Home & Garden > Lighting > Lighting Fixtures",
  "Home & Garden > Lighting > Night Lights & Ambient Lighting",
  "Home & Garden > Lighting > Picture Lights",
  "Home & Garden > Lighting > Tiki Torches & Oil Lamps",
  "Home & Garden > Lighting > Track Lighting",
  "Home & Garden > Lighting Accessories",
  "Home & Garden > Lighting Accessories > Lamp Post Bases",
  "Home & Garden > Lighting Accessories > Lamp Post Mounts",
  "Home & Garden > Lighting Accessories > Lamp Shades",
  "Home & Garden > Lighting Accessories > Lighting Timers",
  "Home & Garden > Lighting Accessories > Oil Lamp Fuel",
  "Home & Garden > Linens & Bedding",
  "Home & Garden > Linens & Bedding > Bedding",
  "Home & Garden > Linens & Bedding > Kitchen Linens Sets",
  "Home & Garden > Linens & Bedding > Table Linens",
  "Home & Garden > Linens & Bedding > Towels",
  "Home & Garden > Parasols & Rain Umbrellas",
  "Home & Garden > Plants",
  "Home & Garden > Plants > Aquatic Plants",
  "Home & Garden > Plants > Flowers",
  "Home & Garden > Plants > Indoor & Outdoor Plants",
  "Home & Garden > Plants > Plant & Herb Growing Kits",
  "Home & Garden > Plants > Seeds",
  "Home & Garden > Plants > Trees",
  "Home & Garden > Pool & Spa",
  "Home & Garden > Pool & Spa > Pool & Spa Accessories",
  "Home & Garden > Pool & Spa > Sauna Accessories",
  "Home & Garden > Pool & Spa > Saunas",
  "Home & Garden > Pool & Spa > Spas",
  "Home & Garden > Pool & Spa > Swimming Pools",
  "Home & Garden > Smoking Accessories",
  "Home & Garden > Smoking Accessories > Ashtrays",
  "Home & Garden > Smoking Accessories > Cigar Cases",
  "Home & Garden > Smoking Accessories > Cigar Cutters & Punches",
  "Home & Garden > Smoking Accessories > Cigarette Cases",
  "Home & Garden > Smoking Accessories > Cigarette Holders",
  "Home & Garden > Smoking Accessories > Humidor Accessories",
  "Home & Garden > Smoking Accessories > Humidors",
  "Home & Garden > Umbrella Sleeves & Cases",
  "Home & Garden > Wood Stoves",
  "Luggage & Bags",
  "Luggage & Bags > Backpacks",
  "Luggage & Bags > Briefcases",
  "Luggage & Bags > Cosmetic & Toiletry Bags",
  "Luggage & Bags > Diaper Bags",
  "Luggage & Bags > Dry Boxes",
  "Luggage & Bags > Duffel Bags",
  "Luggage & Bags > Fanny Packs",
  "Luggage & Bags > Garment Bags",
  "Luggage & Bags > Luggage Accessories",
  "Luggage & Bags > Luggage Accessories > Dry Box Liners & Inserts",
  "Luggage & Bags > Luggage Accessories > Luggage Covers",
  "Luggage & Bags > Luggage Accessories > Luggage Racks & Stands",
  "Luggage & Bags > Luggage Accessories > Luggage Straps",
  "Luggage & Bags > Luggage Accessories > Luggage Tags",
  "Luggage & Bags > Luggage Accessories > Packing Organizers",
  "Luggage & Bags > Luggage Accessories > Travel Bottles & Containers",
  "Luggage & Bags > Luggage Accessories > Travel Pouches",
  "Luggage & Bags > Messenger Bags",
  "Luggage & Bags > Shopping Totes",
  "Luggage & Bags > Suitcases",
  "Luggage & Bags > Train Cases",
  "Mature",
  "Mature > Erotic",
  "Mature > Erotic > Erotic Books",
  "Mature > Erotic > Erotic Clothing",
  "Mature > Erotic > Erotic DVDs & Videos",
  "Mature > Erotic > Erotic Food & Edibles",
  "Mature > Erotic > Erotic Games",
  "Mature > Erotic > Erotic Magazines",
  "Mature > Erotic > Pole Dancing Kits",
  "Mature > Erotic > Sex Toys",
  "Mature > Weapons",
  "Mature > Weapons > Brass Knuckles",
  "Mature > Weapons > Clubs & Batons",
  "Mature > Weapons > Combat Knives",
  "Mature > Weapons > Gun Care & Accessories",
  "Mature > Weapons > Guns",
  "Mature > Weapons > Mace & Pepper Spray",
  "Mature > Weapons > Nunchucks",
  "Mature > Weapons > Spears",
  "Mature > Weapons > Staff & Stick Weapons",
  "Mature > Weapons > Stun Guns & Tasers",
  "Mature > Weapons > Swords",
  "Mature > Weapons > Throwing Stars",
  "Mature > Weapons > Whips",
  "Media",
  "Media > Books",
  "Media > Books > Audiobooks",
  "Media > Books > E-books",
  "Media > Books > Print Books",
  "Media > Carpentry & Woodworking Project Plans",
  "Media > DVDs & Videos",
  "Media > DVDs & Videos > Film & Television DVDs",
  "Media > DVDs & Videos > Film & Television Digital Downloads",
  "Media > DVDs & Videos > Film & Television VHS Tapes",
  "Media > Magazines & Newspapers",
  "Media > Magazines & Newspapers > Magazines",
  "Media > Magazines & Newspapers > Newspapers",
  "Media > Music & Sound Recordings",
  "Media > Music & Sound Recordings > Digital Music Downloads",
  "Media > Music & Sound Recordings > Music CDs",
  "Media > Music & Sound Recordings > Music Cassette Tapes",
  "Media > Music & Sound Recordings > Records & LPs",
  "Media > Music & Sound Recordings > Spoken Word & Field Recordings",
  "Media > Product Manuals",
  "Media > Product Manuals > Camera & Optics Manuals",
  "Media > Product Manuals > Electronics Manuals",
  "Media > Product Manuals > Exercise & Fitness Equipment Manuals",
  "Media > Product Manuals > Household Appliance Manuals",
  "Media > Product Manuals > Kitchen Appliance Manuals",
  "Media > Product Manuals > Model & Toys Manuals",
  "Media > Product Manuals > Office Supply Manuals",
  "Media > Product Manuals > Power Tool & Equipment Manuals",
  "Media > Product Manuals > Vehicle Service Manuals",
  "Media > Sheet Music",
  "Office Supplies",
  "Office Supplies > Book Accessories",
  "Office Supplies > Book Accessories > Book Covers",
  "Office Supplies > Book Accessories > Book Lights",
  "Office Supplies > Book Accessories > Book Stands & Rests",
  "Office Supplies > Book Accessories > Bookmarks",
  "Office Supplies > Desk Pads & Blotters",
  "Office Supplies > Filing & Organization",
  "Office Supplies > Filing & Organization > Address Books",
  "Office Supplies > Filing & Organization > Binding Supplies",
  "Office Supplies > Filing & Organization > Business Card Books",
  "Office Supplies > Filing & Organization > Business Card Stands",
  "Office Supplies > Filing & Organization > CD/DVD Cases & Organizers",
  "Office Supplies > Filing & Organization > Calendars, Organizers & Planners",
  "Office Supplies > Filing & Organization > Card Files",
  "Office Supplies > Filing & Organization > Card Sleeves",
  "Office Supplies > Filing & Organization > Cash Boxes",
  "Office Supplies > Filing & Organization > Desk Organizers",
  "Office Supplies > Filing & Organization > File Boxes",
  "Office Supplies > Filing & Organization > File Folders",
  "Office Supplies > Filing & Organization > Folders & Report Covers",
  "Office Supplies > Filing & Organization > Greeting Card Organizers",
  "Office Supplies > Filing & Organization > Mail Sorters",
  "Office Supplies > Filing & Organization > Pen & Pencil Cases",
  "Office Supplies > Filing & Organization > Portfolios & Padfolios",
  "Office Supplies > Filing & Organization > Recipe Card Boxes",
  "Office Supplies > General Office Supplies",
  "Office Supplies > General Office Supplies > Brass Fasteners",
  "Office Supplies > General Office Supplies > Correction Fluids, Pens & Tapes",
  "Office Supplies > General Office Supplies > Erasers",
  "Office Supplies > General Office Supplies > Labels & Tags",
  "Office Supplies > General Office Supplies > Laminating Film, Pouches & Sheets",
  "Office Supplies > General Office Supplies > Mounting Putty",
  "Office Supplies > General Office Supplies > Office Tape",
  "Office Supplies > General Office Supplies > Paper Clips & Clamps",
  "Office Supplies > General Office Supplies > Paper Products",
  "Office Supplies > General Office Supplies > Rubber Bands",
  "Office Supplies > General Office Supplies > Staples",
  "Office Supplies > General Office Supplies > Tacks & Pushpins",
  "Office Supplies > Impulse Sealers",
  "Office Supplies > Lap Desks",
  "Office Supplies > Name Plates",
  "Office Supplies > Office & Chair Mats",
  "Office Supplies > Office & Chair Mats > Anti-Fatigue Mats",
  "Office Supplies > Office & Chair Mats > Chair Mats",
  "Office Supplies > Office & Chair Mats > Office Mats",
  "Office Supplies > Office Carts",
  "Office Supplies > Office Carts > AV Carts",
  "Office Supplies > Office Carts > Book Carts",
  "Office Supplies > Office Carts > File Carts",
  "Office Supplies > Office Carts > Mail Carts",
  "Office Supplies > Office Carts > Utility Carts",
  "Office Supplies > Office Equipment",
  "Office Supplies > Office Equipment > Calculator Accessories",
  "Office Supplies > Office Equipment > Calculators",
  "Office Supplies > Office Equipment > Electronic Dictionaries & Translators",
  "Office Supplies > Office Equipment > Label Makers",
  "Office Supplies > Office Equipment > Laminators",
  "Office Supplies > Office Equipment > Office Shredders",
  "Office Supplies > Office Equipment > Postage Meters",
  "Office Supplies > Office Equipment > Time & Attendance Clocks",
  "Office Supplies > Office Equipment > Transcribers & Dictation Systems",
  "Office Supplies > Office Equipment > Typewriters",
  "Office Supplies > Office Instruments",
  "Office Supplies > Office Instruments > Call Bells",
  "Office Supplies > Office Instruments > Clipboards",
  "Office Supplies > Office Instruments > Letter Openers",
  "Office Supplies > Office Instruments > Magnifiers",
  "Office Supplies > Office Instruments > Office Rubber Stamps",
  "Office Supplies > Office Instruments > Pencil Sharpeners",
  "Office Supplies > Office Instruments > Staple Removers",
  "Office Supplies > Office Instruments > Staplers",
  "Office Supplies > Office Instruments > Tape Dispensers",
  "Office Supplies > Office Instruments > Writing & Drawing Instrument Accessories",
  "Office Supplies > Office Instruments > Writing & Drawing Instruments",
  "Office Supplies > Paper Handling",
  "Office Supplies > Paper Handling > Fingertip Grips",
  "Office Supplies > Paper Handling > Hole Punches",
  "Office Supplies > Paper Handling > Paper Folding Machines",
  "Office Supplies > Paper Handling > Paper Joggers",
  "Office Supplies > Paper Handling > Paperweights",
  "Office Supplies > Paper Handling > Pencil Boards",
  "Office Supplies > Presentation Supplies",
  "Office Supplies > Presentation Supplies > Chalkboards",
  "Office Supplies > Presentation Supplies > Display Boards",
  "Office Supplies > Presentation Supplies > Document Cameras",
  "Office Supplies > Presentation Supplies > Dry-Erase Boards",
  "Office Supplies > Presentation Supplies > Easel Pads",
  "Office Supplies > Presentation Supplies > Easels",
  "Office Supplies > Presentation Supplies > Laser Pointers",
  "Office Supplies > Presentation Supplies > Lecterns",
  "Office Supplies > Presentation Supplies > Transparencies",
  "Office Supplies > Presentation Supplies > Wireless Presenters",
  "Office Supplies > Shipping Supplies",
  "Office Supplies > Shipping Supplies > Moving & Shipping Boxes",
  "Office Supplies > Shipping Supplies > Packing Materials",
  "Office Supplies > Shipping Supplies > Packing Tape",
  "Religious & Ceremonial",
  "Religious & Ceremonial > Memorial Ceremony Supplies",
  "Religious & Ceremonial > Memorial Ceremony Supplies > Memorial Urns",
  "Religious & Ceremonial > Religious Items",
  "Religious & Ceremonial > Religious Items > Prayer Beads",
  "Religious & Ceremonial > Religious Items > Prayer Cards",
  "Religious & Ceremonial > Religious Items > Religious Altars",
  "Religious & Ceremonial > Religious Items > Religious Veils",
  "Religious & Ceremonial > Religious Items > Tarot Cards",
  "Religious & Ceremonial > Wedding Ceremony Supplies",
  "Religious & Ceremonial > Wedding Ceremony Supplies > Aisle Runners",
  "Religious & Ceremonial > Wedding Ceremony Supplies > Flower Girl Baskets",
  "Religious & Ceremonial > Wedding Ceremony Supplies > Ring Pillows & Holders",
  "Software",
  "Software > Computer Software",
  "Software > Computer Software > Antivirus & Security Software",
  "Software > Computer Software > Business & Productivity Software",
  "Software > Computer Software > Compilers & Programming Tools",
  "Software > Computer Software > Computer Utilities & Maintenance Software",
  "Software > Computer Software > Dictionary & Translation Software",
  "Software > Computer Software > Educational Software",
  "Software > Computer Software > Financial, Tax & Accounting Software",
  "Software > Computer Software > GPS Map Data & Software",
  "Software > Computer Software > Handheld & PDA Software",
  "Software > Computer Software > Multimedia & Design Software",
  "Software > Computer Software > Network Software",
  "Software > Computer Software > Office Application Software",
  "Software > Computer Software > Operating Systems",
  "Software > Computer Software > Restore Disks",
  "Software > Digital Goods & Currency",
  "Software > Digital Goods & Currency > Computer Icons",
  "Software > Digital Goods & Currency > Desktop Wallpaper",
  "Software > Digital Goods & Currency > Digital Artwork",
  "Software > Digital Goods & Currency > Document Templates",
  "Software > Digital Goods & Currency > Fonts",
  "Software > Digital Goods & Currency > Stock Photographs & Video Footage",
  "Software > Digital Goods & Currency > Virtual Currency",
  "Software > Video Game Software",
  "Sporting Goods",
  "Sporting Goods > Athletics",
  "Sporting Goods > Athletics > American Football",
  "Sporting Goods > Athletics > Baseball & Softball",
  "Sporting Goods > Athletics > Basketball",
  "Sporting Goods > Athletics > Boxing & Martial Arts",
  "Sporting Goods > Athletics > Broomball Equipment",
  "Sporting Goods > Athletics > Cheerleading",
  "Sporting Goods > Athletics > Coaching & Officiating",
  "Sporting Goods > Athletics > Cricket",
  "Sporting Goods > Athletics > Dancing",
  "Sporting Goods > Athletics > Fencing",
  "Sporting Goods > Athletics > Field Hockey & Lacrosse",
  "Sporting Goods > Athletics > Figure Skating & Hockey",
  "Sporting Goods > Athletics > General Purpose Athletic Equipment",
  "Sporting Goods > Athletics > Gymnastics",
  "Sporting Goods > Athletics > Racquetball & Squash",
  "Sporting Goods > Athletics > Rounders",
  "Sporting Goods > Athletics > Rugby",
  "Sporting Goods > Athletics > Soccer",
  "Sporting Goods > Athletics > Team Handball",
  "Sporting Goods > Athletics > Tennis",
  "Sporting Goods > Athletics > Track & Field",
  "Sporting Goods > Athletics > Volleyball",
  "Sporting Goods > Athletics > Wallyball Equipment",
  "Sporting Goods > Athletics > Water Polo",
  "Sporting Goods > Athletics > Wrestling",
  "Sporting Goods > Exercise & Fitness",
  "Sporting Goods > Exercise & Fitness > Ab Wheels & Rollers",
  "Sporting Goods > Exercise & Fitness > Aerobic Steps",
  "Sporting Goods > Exercise & Fitness > Balance Trainers",
  "Sporting Goods > Exercise & Fitness > Cardio",
  "Sporting Goods > Exercise & Fitness > Exercise Balls",
  "Sporting Goods > Exercise & Fitness > Exercise Bands",
  "Sporting Goods > Exercise & Fitness > Exercise Benches",
  "Sporting Goods > Exercise & Fitness > Exercise Equipment Mats",
  "Sporting Goods > Exercise & Fitness > Exercise Machine & Equipment Sets",
  "Sporting Goods > Exercise & Fitness > Exercise Wedges",
  "Sporting Goods > Exercise & Fitness > Foam Roller Accessories",
  "Sporting Goods > Exercise & Fitness > Foam Rollers",
  "Sporting Goods > Exercise & Fitness > Hand Exercisers",
  "Sporting Goods > Exercise & Fitness > Inversion Tables & Systems",
  "Sporting Goods > Exercise & Fitness > Medicine Balls",
  "Sporting Goods > Exercise & Fitness > Power Towers",
  "Sporting Goods > Exercise & Fitness > Push Up & Pull Up Bars",
  "Sporting Goods > Exercise & Fitness > Reaction Balls",
  "Sporting Goods > Exercise & Fitness > Speed & Resistance Parachutes",
  "Sporting Goods > Exercise & Fitness > Sport Safety Lights & Reflectors",
  "Sporting Goods > Exercise & Fitness > Stopwatches",
  "Sporting Goods > Exercise & Fitness > Suspension Trainers",
  "Sporting Goods > Exercise & Fitness > Vibration Exercise Machines",
  "Sporting Goods > Exercise & Fitness > Weight Lifting",
  "Sporting Goods > Exercise & Fitness > Weighted Clothing",
  "Sporting Goods > Exercise & Fitness > Yoga & Pilates",
  "Sporting Goods > Indoor Games",
  "Sporting Goods > Indoor Games > Air Hockey",
  "Sporting Goods > Indoor Games > Billiards",
  "Sporting Goods > Indoor Games > Bowling",
  "Sporting Goods > Indoor Games > Foosball",
  "Sporting Goods > Indoor Games > Multi-Game Tables",
  "Sporting Goods > Indoor Games > Ping Pong",
  "Sporting Goods > Indoor Games > Table Shuffleboard",
  "Sporting Goods > Indoor Games > Throwing Darts",
  "Sporting Goods > Outdoor Recreation",
  "Sporting Goods > Outdoor Recreation > Boating & Water Sports",
  "Sporting Goods > Outdoor Recreation > Camping & Hiking",
  "Sporting Goods > Outdoor Recreation > Climbing",
  "Sporting Goods > Outdoor Recreation > Cycling",
  "Sporting Goods > Outdoor Recreation > Equestrian",
  "Sporting Goods > Outdoor Recreation > Fishing",
  "Sporting Goods > Outdoor Recreation > Golf",
  "Sporting Goods > Outdoor Recreation > Hang Gliding & Skydiving",
  "Sporting Goods > Outdoor Recreation > Hunting & Shooting",
  "Sporting Goods > Outdoor Recreation > Hydration System Accessories",
  "Sporting Goods > Outdoor Recreation > Hydration Systems",
  "Sporting Goods > Outdoor Recreation > Inline & Roller Skating",
  "Sporting Goods > Outdoor Recreation > Kite Buggying",
  "Sporting Goods > Outdoor Recreation > Outdoor Games",
  "Sporting Goods > Outdoor Recreation > Riding Scooters",
  "Sporting Goods > Outdoor Recreation > Skateboarding",
  "Sporting Goods > Outdoor Recreation > Winter Sports & Activities",
  "Toys & Games",
  "Toys & Games > Game Timers",
  "Toys & Games > Games",
  "Toys & Games > Games > Battle Top Accessories",
  "Toys & Games > Games > Battle Tops",
  "Toys & Games > Games > Bingo Sets",
  "Toys & Games > Games > Blackjack & Craps Sets",
  "Toys & Games > Games > Board Games",
  "Toys & Games > Games > Card Game Accessories",
  "Toys & Games > Games > Card Games",
  "Toys & Games > Games > Dexterity Games",
  "Toys & Games > Games > Dice Sets & Games",
  "Toys & Games > Games > Poker Chip Accessories",
  "Toys & Games > Games > Poker Chips & Sets",
  "Toys & Games > Games > Portable Electronic Games",
  "Toys & Games > Games > Roulette Wheels & Sets",
  "Toys & Games > Games > Slot Machines",
  "Toys & Games > Games > Tile Games",
  "Toys & Games > Outdoor Play Equipment",
  "Toys & Games > Outdoor Play Equipment > Inflatable Bouncer Accessories",
  "Toys & Games > Outdoor Play Equipment > Inflatable Bouncers",
  "Toys & Games > Outdoor Play Equipment > Play Swings",
  "Toys & Games > Outdoor Play Equipment > Play Tents & Tunnels",
  "Toys & Games > Outdoor Play Equipment > Playhouses",
  "Toys & Games > Outdoor Play Equipment > Pogo Sticks",
  "Toys & Games > Outdoor Play Equipment > Sandboxes",
  "Toys & Games > Outdoor Play Equipment > See Saws",
  "Toys & Games > Outdoor Play Equipment > Slides",
  "Toys & Games > Outdoor Play Equipment > Stilts",
  "Toys & Games > Outdoor Play Equipment > Swing Set & Playset Accessories",
  "Toys & Games > Outdoor Play Equipment > Swing Sets & Playsets",
  "Toys & Games > Outdoor Play Equipment > Trampoline Accessories",
  "Toys & Games > Outdoor Play Equipment > Trampolines",
  "Toys & Games > Outdoor Play Equipment > Water Play Equipment",
  "Toys & Games > Puzzles",
  "Toys & Games > Puzzles > Jigsaw Puzzle Accessories",
  "Toys & Games > Puzzles > Jigsaw Puzzles",
  "Toys & Games > Puzzles > Mechanical Puzzles",
  "Toys & Games > Puzzles > Wooden & Pegged Puzzles",
  "Toys & Games > Toys",
  "Toys & Games > Toys > Activity Toys",
  "Toys & Games > Toys > Art & Drawing Toys",
  "Toys & Games > Toys > Ball Pit Accessories",
  "Toys & Games > Toys > Ball Pits",
  "Toys & Games > Toys > Bath Toys",
  "Toys & Games > Toys > Beach & Sand Toys",
  "Toys & Games > Toys > Building Toys",
  "Toys & Games > Toys > Dolls, Playsets & Toy Figures",
  "Toys & Games > Toys > Educational Toys",
  "Toys & Games > Toys > Executive Toys",
  "Toys & Games > Toys > Flying Toy Accessories",
  "Toys & Games > Toys > Flying Toys",
  "Toys & Games > Toys > Musical Toys",
  "Toys & Games > Toys > Play Vehicle Accessories",
  "Toys & Games > Toys > Play Vehicles",
  "Toys & Games > Toys > Pretend Play",
  "Toys & Games > Toys > Remote Control Toy Accessories",
  "Toys & Games > Toys > Remote Control Toys",
  "Toys & Games > Toys > Riding Toy Accessories",
  "Toys & Games > Toys > Riding Toys",
  "Toys & Games > Toys > Robotic Toys",
  "Toys & Games > Toys > Sports Toy Accessories",
  "Toys & Games > Toys > Sports Toys",
  "Toys & Games > Toys > Toy Gift Baskets",
  "Toys & Games > Toys > Toy Weapon & Gadget Accessories",
  "Toys & Games > Toys > Toy Weapons & Gadgets",
  "Toys & Games > Toys > Visual Toys",
  "Toys & Games > Toys > Wind-Up Toys",
  "Vehicles & Parts",
  "Vehicles & Parts > Vehicle Parts & Accessories",
  "Vehicles & Parts > Vehicle Parts & Accessories > Aircraft Parts & Accessories",
  "Vehicles & Parts > Vehicle Parts & Accessories > Motor Vehicle Electronics",
  "Vehicles & Parts > Vehicle Parts & Accessories > Motor Vehicle Parts",
  "Vehicles & Parts > Vehicle Parts & Accessories > Vehicle Maintenance, Care & Decor",
  "Vehicles & Parts > Vehicle Parts & Accessories > Vehicle Safety & Security",
  "Vehicles & Parts > Vehicle Parts & Accessories > Vehicle Storage & Cargo",
  "Vehicles & Parts > Vehicle Parts & Accessories > Watercraft Parts & Accessories",
  "Vehicles & Parts > Vehicles",
  "Vehicles & Parts > Vehicles > Aircraft",
  "Vehicles & Parts > Vehicles > Motor Vehicles",
  "Vehicles & Parts > Vehicles > Watercraft",
];

export const taxonomySelectOptions = taxonomies.map((item) => ({
  id: item,
  label: item,
}));
