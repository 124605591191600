import type { GameRound } from "api/types";

import { useEffect, useState } from "react";
import { LeaderBoard } from "./Game/LeaderBoard";

import { useLogin } from "./useLogin";
import { getCurrentGameRound } from "./api/penguinInTheLoop";
import { Card, CardDivider, CardContents, Center } from "./components/Card";
import { Logo } from "./components/Logo";
import CountdownTimer from "./components/CountDown";

const PenguinInTheLoop = () => {
  const [gameRound, setGameRound] = useState<GameRound>();
  const credential = useLogin();

  useEffect(() => {
    const loadGameRound = async () => {
      const gameRound = await getCurrentGameRound(credential);
      setGameRound(gameRound);
    };
    loadGameRound();
  }, [credential]);

  return (
    <>
      <Center $style={{ textAlign: `center` }}>
        <div>
          <Logo />
        </div>
      </Center>
      <Card>
        <CardDivider />
        <CardContents>
          {gameRound && <CountdownTimer date={gameRound.end} />}
          <LeaderBoard gameRound={gameRound ? gameRound.id : ""} />
        </CardContents>
      </Card>
    </>
  );
};

export default PenguinInTheLoop;
