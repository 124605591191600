import type { FC } from "react";

import { useState } from "react";
import {
  EditableDetailContainer,
  CurrentValueLabel,
  Separator,
  SuggestedValueLabel,
  SuggestedValue,
  DetailValue,
  DetailButton,
} from "./cms.styled";

import { nullToNotAvailable } from "./cms.utils";

type EditableDetailProps = {
  value?: any;
  labelText: string;
  suggestedValue?: any;
  onEdit: (newValue: any) => void;
};

export const EditableDetail: FC<EditableDetailProps> = ({ value, suggestedValue, labelText, onEdit }) => {
  const [newValue, setNewValue] = useState(suggestedValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleClick = () => {
    onEdit(newValue);
  };

  return (
    <EditableDetailContainer>
      <CurrentValueLabel>Current value: </CurrentValueLabel>
      <DetailValue>{nullToNotAvailable(value)}</DetailValue>
      <Separator />
      {suggestedValue ? (
        <>
          <SuggestedValueLabel>{labelText}</SuggestedValueLabel>
          <SuggestedValue type="number" value={newValue} onChange={handleChange}></SuggestedValue>
        </>
      ) : (
        <>
          <SuggestedValueLabel>New value</SuggestedValueLabel>
          <SuggestedValue type="number" onChange={handleChange}></SuggestedValue>
        </>
      )}
      <DetailButton onClick={handleClick}>Overwrite</DetailButton>
    </EditableDetailContainer>
  );
};
