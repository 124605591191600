import type { ValidationError } from "api/errors";
import type { FieldErrors } from "react-hook-form";
import type { RegisterUserFormData } from "./UserRegistrationForm";

const errorMessages: Record<string, string> = {
  required: "Is required",
  email: "Must be a valid email address",
  min: "Must be at least 2 characters",
};

export const validateFields = (formData: RegisterUserFormData): [boolean, FieldErrors] => {
  const errors: FieldErrors<RegisterUserFormData> = {};
  let hasErrors = false;
  if (formData.Name.length < 2) {
    errors.Name = {
      type: "min",
      message: errorMessages.min,
    };
    hasErrors = true;
  }
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.Email)) {
    errors.Email = {
      type: "email",
      message: errorMessages.email,
    };
    hasErrors = true;
  }
  return [hasErrors, errors];
};

export const parseErrors = (e: ValidationError): FieldErrors<RegisterUserFormData> => {
  const fieldErrors: FieldErrors<RegisterUserFormData> = {};
  for (const [field, message] of Object.entries(e.invalidFields)) {
    let type = "invalid";
    switch (message) {
      case "field is required":
        type = "required";
    }
    fieldErrors[field as keyof RegisterUserFormData] = {
      message,
      type,
    };
  }
  return fieldErrors;
};
