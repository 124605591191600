import { styled } from "styletron-react";
import { Center } from "./components/Card";
import { Logo } from "./components/Logo";
import { Button, ButtonOverrides } from "baseui/button";
import { useNavigate } from "react-router-dom";

const overrides: ButtonOverrides = {
  BaseButton: {
    style: () => ({
      backgroundColor: "#015597",
      color: "white",
      width: "50vw",
      ":hover": {
        backgroundColor: "#014488",
        color: "white",
      },
    }),
  },
};

const ButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  height: "40vh",
});

export const Landing = () => {
  const navigate = useNavigate();

  return (
    <>
      <Center $style={{ textAlign: `center` }}>
        <div>
          <Logo />
        </div>
      </Center>
      <ButtonContainer>
        <Button
          overrides={overrides}
          onClick={() => {
            navigate("/penguin-in-the-loop");
          }}
        >
          Penguin in the Loop
        </Button>
        <Button
          overrides={overrides}
          onClick={() => {
            navigate("/productsLookup");
          }}
        >
          Copilot
        </Button>
      </ButtonContainer>
    </>
  );
};
