import type { FC } from "react";

import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { Modal, ModalBody } from "components/Modal";
import { useLogin } from "useLogin";
import { FileIcon } from "components/Icons";
import { ImportTutorial } from "./ImportTutorial";

import { DropZone } from "./imports.styled";
import { APIError } from "api/errors";
import { createImport } from "api/imports";

interface NewImportProps {
  organizationId: string;
  fetchImports: () => Promise<void>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UploadState {
  state: "idle" | "failed" | "succes";
  message: string;
}

const CsvDropzone: FC<NewImportProps> = ({ organizationId, fetchImports, setModalState }) => {
  const credentials = useLogin();
  const [file, setFile] = useState<File | null>(null);
  const [fileData, setFileData] = useState<string>("");
  const [uploadState, setUploadState] = useState<UploadState>({
    state: "idle",
    message: "",
  });

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          setFileData(e.target.result as string);
        }
      };
      reader.readAsText(file);
    }
  }, [file]);

  useEffect(() => {
    if (!fileData || fileData === "") {
      return;
    }
    const uploadCsv = async () => {
      try {
        await createImport(credentials, organizationId, fileData);

        setUploadState({
          state: "succes",
          message: "File uploaded",
        });
        await fetchImports();
        setModalState(false);
      } catch (error: any) {
        console.error(error);
        if (error instanceof APIError) {
          setUploadState({
            state: "failed",
            message: error.message,
          });
        }
        return;
      }
    };
    uploadCsv();
  }, [fileData]);

  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].type !== "text/csv") {
        setUploadState({
          state: "failed",
          message: "Invalid file type",
        });
        return;
      }
      setFile(acceptedFiles[0]);
      setUploadState({
        state: "succes",
        message: "File uploaded",
      });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropZone {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <FileIcon />
      ) : uploadState.state === "idle" ? (
        <p>Drag the CSV file here, or click to select a file</p>
      ) : uploadState.state === "failed" ? (
        <p>Upload failed: {uploadState.message}</p>
      ) : uploadState.state === "succes" ? (
        <p>Upload succesful</p>
      ) : null}
    </DropZone>
  );
};

export const NewImport: FC<NewImportProps> = ({ organizationId, fetchImports, setModalState }) => {
  return (
    <Modal
      onClose={() => setModalState(false)}
      closeable
      isOpen={true}
      animate
      autoFocus
      size={"responsive"}
      role={"dialog"}
      overrides={{
        Close: {
          style: {
            display: "none",
          },
        },
        Dialog: {
          style: {
            fontWeight: 500,
          },
        },
      }}
    >
      <ModalBody>
        <ImportTutorial />
        <CsvDropzone organizationId={organizationId} fetchImports={fetchImports} setModalState={setModalState} />
      </ModalBody>
    </Modal>
  );
};
