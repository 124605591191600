import React, { FC, useState, useEffect } from "react";
import { useLogin } from "useLogin";
import { updateProductTaxonomy, approveProductTaxonomy } from "api/penguinInTheLoop";
import { styled } from "styletron-react";
import { taxonomySelectOptions } from "../taxonomies";
import { Button } from "baseui/button";
import { Select, StyledDropdownListItem } from "baseui/select";
import { withStyle } from "baseui";
import { StyledList, StyledEmptyState, OptionListProps } from "baseui/menu";
import { FixedSizeList } from "react-window";
import { ToasterContainer, toaster } from "baseui/toast";

const LIST_ITEM_HEIGHT = 36;
const EMPTY_LIST_HEIGHT = 72;
const MAX_LIST_HEIGHT = 500;

const ListItem = withStyle(StyledDropdownListItem, {
  paddingTop: 0,
  paddingBottom: 0,
  display: "flex",
  alignItems: "center",
});
const FixedSizeListItem = ({
  data,
  index,
  style,
}: {
  data: { props: OptionListProps }[];
  index: number;
  style: React.CSSProperties;
}) => {
  const { item, ...restChildProps } = data[index].props;
  return (
    <ListItem
      key={item.id}
      style={{
        boxSizing: "border-box",
        ...style,
      }}
      {...restChildProps}
    >
      {item.id}
    </ListItem>
  );
};

const VirtualDropdown = React.forwardRef<HTMLUListElement, any>((props: any, ref) => {
  const children = React.Children.toArray(props.children);
  if (!children[0] || !(children[0] as React.ReactElement).props.item) {
    return (
      <StyledList $style={{ height: EMPTY_LIST_HEIGHT + "px" }} ref={ref}>
        <StyledEmptyState {...(children[0] as React.ReactElement).props} />
      </StyledList>
    );
  }
  const height = Math.min(MAX_LIST_HEIGHT, children.length * LIST_ITEM_HEIGHT);
  return (
    <StyledList ref={ref}>
      <FixedSizeList
        width="100%"
        height={height}
        itemCount={children.length}
        itemData={children as any}
        itemKey={(index: number, data: { props: OptionListProps }[]) => data[index].props.item.id}
        itemSize={LIST_ITEM_HEIGHT}
      >
        {FixedSizeListItem}
      </FixedSizeList>
    </StyledList>
  );
});

VirtualDropdown.displayName = "VirtualDropdown";

const options = taxonomySelectOptions;

const TaxonomyUpdaterContainer = styled("form", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "10px",
  maxWidth: "50vw",
});

interface TaxonomyUpdaterProps {
  roundID: string;
  productId: string;
  taxonomy: string;
  approved: boolean;
}

export const TaxonomyUpdater: FC<TaxonomyUpdaterProps> = ({ roundID, productId, taxonomy, approved }) => {
  const credential = useLogin();
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<string>(taxonomy);
  const [isUpdated, setIsUpdated] = useState<boolean>(true);
  const [isApproved, setIsApproved] = useState<boolean>(approved);

  useEffect(() => {
    setSelectedTaxonomy(taxonomy);
    setIsUpdated(true);
  }, [taxonomy]);

  const onApproveOrUpdate = async (action: "Update" | "Approve", result: boolean) => {
    if (result) {
      toaster.positive(`${action}d taxonomy`, {});
    } else {
      toaster.negative(`Failed to ${action}`, {});
    }
  };

  return (
    <TaxonomyUpdaterContainer
      onSubmit={async (event: any) => {
        event.preventDefault();
        try {
          await updateProductTaxonomy(credential, productId, selectedTaxonomy, roundID);
          setIsUpdated(true);
        } catch (e) {
          console.error(e);
          onApproveOrUpdate("Update", false);
          return;
        }
        onApproveOrUpdate("Update", true);
      }}
    >
      <Select
        options={options}
        labelKey="id"
        valueKey="label"
        overrides={{ Dropdown: { component: VirtualDropdown }, Root: { style: { width: "70%" } } }}
        onChange={({ value }) => {
          const newSubject = value[0]?.id as string;
          setSelectedTaxonomy(newSubject);
          setIsUpdated(false);
        }}
        value={[{ id: selectedTaxonomy, label: selectedTaxonomy }]}
      />
      <Button disabled={isUpdated} type="submit">
        Update
      </Button>
      <Button
        disabled={!isUpdated || isApproved}
        type="button"
        onClick={async () => {
          try {
            await approveProductTaxonomy(credential, productId, roundID);
            setIsUpdated(true);
            setIsApproved(true);
          } catch (e) {
            console.error(e);
            onApproveOrUpdate("Approve", false);
            return;
          }
          onApproveOrUpdate("Approve", true);
        }}
      >
        Approve
      </Button>
      <ToasterContainer autoHideDuration={3000} placement={"bottomRight"}></ToasterContainer>
    </TaxonomyUpdaterContainer>
  );
};
