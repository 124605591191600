import { styled } from "styletron-react";
import Select from "react-select";

export const OrganizationSelect = styled(Select, {
  width: "800px",
});

export const ProductExportLink = styled("a", {
  paddingTop: "10px",
});
