import { styled } from "styletron-react";

export const ModalHeader = styled("h2", {
  color: "#015597",
  alignItems: "center",
  marginBottom: "30px",
});

export const ModalItemsContainer = styled("div", {
  alignItems: "left",
});

export const ModalItemContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  paddingBottom: "20px",
});

export const ModalItemLabel = styled("div", {
  fontSize: "16px",
  fontWeight: 600,
});

export const ModalItemText = styled("div", {
  fontSize: "16px",
});

export const OrganizationsTopBar = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  position: "relative",
  padding: "10px",
  borderBottom: "1px solid #ddd",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  backgroundColor: "#f8f8f8",
  gap: "10px",
});

export const OrgSectionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

export const OrgSectionHeader = styled("h3", {
  gap: "10px",
  paddingBottom: "5px",
});

export const InputError = styled(`p`, {
  color: `#f00`,
  position: `absolute`,
  marginTop: `40px`,
  fontWeight: 500,
  fontSize: `12px`,
});
