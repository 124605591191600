import { styled } from "styletron-react";

export const Tooltip = styled("div", ({ $visible }: { $visible: boolean }) => ({
  position: "absolute",
  top: "-20px",
  background: "#fff",
  boxShadow: "0 0 5px #d4d4d4",
  width: "max-content",
  padding: "5px",
  borderRadius: "4px",
  opacity: $visible ? 1 : 0,
  transition: "opacity 0.3s ease",
}));
