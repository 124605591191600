import { useState } from "react";
import { styled, useStyletron } from "styletron-react";
import { Block } from "baseui/block";
import { ArrowLeft, ArrowRight, ArrowDown } from "baseui/icon";

const InstructionsContainer = styled("div", {
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "100%",
});

const Instruction = styled("div", {
  display: "flex",
  alignItems: "center",
});

const Instructions = () => {
  const [css] = useStyletron();
  const [isVisible, setIsVisible] = useState(false);

  const handleTouch = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Block
      className={css({
        position: "fixed",
        bottom: "0",
        left: "5px",
        padding: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        borderRadius: "5px 5px 0 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        maxWidth: "350px",
        zIndex: "999",
        transition: "transform 0.3s ease-in-out",
        "@media screen and (max-width: 768px)": {
          display: "none",
        },
      })}
      onTouchStart={handleTouch}
    >
      <InstructionsContainer>
        <Instruction>
          <ArrowLeft size={30} color="#d00" /> <b style={{ color: "#d00", margin: "auto" }}>Disapprove</b>
        </Instruction>
        <Instruction>
          <ArrowRight size={30} color="#0b0" /> <b style={{ color: "#0b0" }}>Approve</b>
        </Instruction>
        <Instruction>
          <ArrowDown size={30} color="#00b" /> <b style={{ color: "#00b" }}>Skip</b>
        </Instruction>
      </InstructionsContainer>
    </Block>
  );
};

export default Instructions;
