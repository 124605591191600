import type { FC } from "react";
import type { Coverage, CoverageLevel } from "api/types";

import { Typography } from "antd";
import { withStyle, StyletronComponent, useStyletron } from "styletron-react";
import { Card, CardContents, CardDivider } from "./components/Card";
import { useLogin } from "./useLogin";
import { useState, useEffect } from "react";
import { TabContainer, Tab, InlineTab } from "./components/Tabs";
import Table from "./components/Table";
import { Description } from "./CoverageDescription";
import { TaxonomyProducts } from "./TaxonomyProducts";
import { useNavigate } from "react-router-dom";
import {
  getMainCategoryStats,
  getCategoryLevelStats,
  getCategoryProductStats,
  getCategoryWithEFStats,
} from "api/coverage";

const TableCard: StyletronComponent<"div", any> = withStyle(Card, {
  maxWidth: "max-content !important",
  float: "left",
  margin: "12px !important",
});

const formatEmissionFactor = (key: string, value: any) => {
  if (key !== "EmissionFactor") {
    return String(value);
  }
  if (value === "category") {
    return `✅`;
  } else if (value === "average-category") {
    return `⚠️`;
  } else {
    return `❌`;
  }
};

export const CoverageOverview: FC = () => {
  const credential = useLogin();
  const [css] = useStyletron();
  const [coverageByLevels, setCoverageByLevels] = useState<CoverageLevel[]>([]);
  const [coverageMainCategories, setCoverageMainCategories] = useState<Coverage[]>([]);
  const [coverageTaxWithEf, setCoverageTaxWithEf] = useState<Coverage[]>([]);
  const [coverageAllCategories, setCoverageAllTaxonmies] = useState<Coverage[]>([]);
  const [activeTab, setActiveTab] = useState<string>("topLevel");
  const [loadedStats, setLoadedStats] = useState<string[]>([]);
  const [taxonomyProductModalOpen, setTaxonomyProductModalOpen] = useState<boolean>(false);
  const [taxonomy, setTaxonomy] = useState<string>("");
  const navigate = useNavigate();

  const loadTopLevel = async () => {
    const coverage = await getMainCategoryStats(credential);
    setCoverageMainCategories(coverage);
  };

  const loadCategoryLevel = async () => {
    const coverage = await getCategoryLevelStats(credential, 6);
    setCoverageByLevels(coverage);
  };

  const loadCategoryWithEF = async () => {
    const coverage = await getCategoryWithEFStats(credential);
    setCoverageTaxWithEf(coverage);
  };

  const loadCategoryProduct = async () => {
    const coverage = await getCategoryProductStats(credential);
    setCoverageAllTaxonmies(coverage);
  };

  const loadFuncs: Record<string, () => Promise<void>> = {
    topLevel: loadTopLevel,
    categoryLevel: loadCategoryLevel,
    categoryWithEF: loadCategoryWithEF,
    categoryProduct: loadCategoryProduct,
  };

  useEffect(() => {
    const main = async () => {
      await loadTopLevel();
      loadedStats.push("topLevel");
    };
    main();
  }, []);

  const getTaxonomyLevel = (taxonomy: string): number => {
    return (taxonomy.match(/ > /g) || []).length + 1;
  };

  const onClick = (tabName: string) => {
    return async () => {
      setActiveTab(tabName);
      if (!loadedStats.includes(tabName)) {
        await loadFuncs[tabName]();
        loadedStats.push(tabName);
        setLoadedStats(loadedStats);
      }
    };
  };

  const onCategoryTableRowClicked = (record: Record<string, any>) => {
    return () => {
      if (getTaxonomyLevel(record.Category) < 3) {
        alert(
          "This category is too general to show products. Please select a more specific category that has at least 3 levels."
        );
      } else {
        setTaxonomy(record.Category);
        setTaxonomyProductModalOpen(true);
      }
    };
  };

  const { Text, Title } = Typography;
  const Loading = () => (
    <Text strong className={css({ padding: "10px 0px 0px 0px" })}>
      🏗️ LOADING
    </Text>
  );
  const emptyMessage = "No data available :(";

  return (
    <TabContainer>
      <Tab>
        <InlineTab $isActive={activeTab === "topLevel"} onClick={onClick("topLevel")}>
          Top Level Category Distribution
        </InlineTab>
        <InlineTab $isActive={activeTab === "categoryLevel"} onClick={onClick("categoryLevel")}>
          Category level Distribution
        </InlineTab>
        <InlineTab $isActive={activeTab === "categoryWithEF"} onClick={onClick("categoryWithEF")}>
          Categories with Emission Factor Distribution
        </InlineTab>
        <InlineTab $isActive={activeTab === "categoryProduct"} onClick={onClick("categoryProduct")}>
          Per Category Product Distribution
        </InlineTab>
        <InlineTab
          $isActive={activeTab === "categoryProduct"}
          onClick={() => {
            navigate("/productsLookup");
          }}
        >
          Back
        </InlineTab>
      </Tab>
      <CardDivider />
      {activeTab === "topLevel" ? (
        <Tab>
          <TableCard>
            <CardContents>
              <Title level={4}>Top Level Category Distribution</Title>
              <Description title="Number of products for the top level categories" />
              {loadedStats.includes("topLevel") ? (
                <Table data={coverageMainCategories} omitColumns={["EmissionFactor"]} emptyMessage={emptyMessage} />
              ) : (
                <Loading />
              )}
            </CardContents>
          </TableCard>
        </Tab>
      ) : null}
      {activeTab === "categoryLevel" ? (
        <Tab>
          <TableCard>
            <CardContents>
              <Title level={4}>Category Level Distribution</Title>
              <Description
                title="Number of products per each category level."
                explanation={[
                  `Given the product category "Home & Garden > Decor > Clocks", if a product was only matched to "Home & Garden", it has category level 1.`,
                  `If matched to "Home & Garden > Decor" it has level 2, etc`,
                ]}
              />
              {loadedStats.includes("categoryLevel") ? (
                <Table data={coverageByLevels} emptyMessage={emptyMessage} />
              ) : (
                <Loading />
              )}
            </CardContents>
          </TableCard>
        </Tab>
      ) : null}
      {activeTab === "categoryWithEF" ? (
        <Tab>
          <TableCard>
            <CardContents>
              <Title level={4}>Categories with Emission Factor Distribution</Title>
              <Description title="Number of products that belong to categories for which we have an emission factor" />
              {loadedStats.includes("categoryWithEF") ? (
                <Table data={coverageTaxWithEf} omitColumns={["EmissionFactor"]} emptyMessage={emptyMessage} />
              ) : (
                <Loading />
              )}
            </CardContents>
          </TableCard>
        </Tab>
      ) : null}
      {activeTab === "categoryProduct" ? (
        <Tab>
          <TableCard>
            <CardContents>
              <Title level={4}>Per Category Product Distribution</Title>
              <Description
                title="Number of products for all categories and subcategories"
                explanation={[`Click on a category with at least level 3 to see the products that belong to it.`]}
              />
              {loadedStats.includes("categoryProduct") ? (
                <Table
                  data={coverageAllCategories}
                  cellFormatter={formatEmissionFactor}
                  emptyMessage={emptyMessage}
                  onBodyClick={onCategoryTableRowClicked}
                  filterColumn="Category"
                />
              ) : (
                <Loading />
              )}
            </CardContents>
          </TableCard>
          {taxonomyProductModalOpen && (
            <TaxonomyProducts setModalState={setTaxonomyProductModalOpen} taxonomy={taxonomy} />
          )}
        </Tab>
      ) : null}
    </TabContainer>
  );
};
