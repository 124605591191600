import { getConfig } from "config";
import { ValidationError, APIError, NotAuthorizedError } from "./errors";

interface ErrorResponse {
  message: string;
  invalidFields?: Record<string, string>;
}

export const makeCopilotRequest = async (
  endpoint: string,
  method: string,
  credentials: string,
  body?: any
): Promise<any> => {
  const { API_URL } = getConfig();

  const path = encodeURI(`copilot/${endpoint}`);
  const response = await fetch(`${API_URL}/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${credentials}`,
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) {
    const errBody = (await response.json()) as ErrorResponse;
    if (response.status >= 400 && response.status < 500) {
      if (response.status === 401) {
        throw new NotAuthorizedError();
      }
      if (response.status === 400 && errBody?.message === "invalid request data") {
        throw new ValidationError(errBody?.message, errBody?.invalidFields || {});
      }
      throw new APIError(errBody?.message);
    }
    throw new APIError(errBody.message);
  }
  // check for no-content before attempting to parse the response
  if (response.status === 204) {
    return;
  }
  return await response.json();
};
