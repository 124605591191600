import { FC } from "react";
import { styled } from "styletron-react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonOverrides } from "baseui/button";

const overrides: ButtonOverrides = {
  BaseButton: {
    style: () => ({
      minWidth: "200px",
    }),
  },
};

const GameOverContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  height: "60vh",
  maxWidth: "60vw",
  margin: "auto",
});

interface GameOverProps {
  error: boolean;
  round: boolean;
}

const getText = (error: boolean, round: boolean) => {
  if (error) {
    return {
      title: "Oops!",
      text: "Something went wrong. Please try again later.",
    };
  } else if (round) {
    return {
      title: "Round complete!",
      text: "There are no more products to review in this round! If you want to play more, you can review products from the the entire catalogue.",
    };
  }
  return {
    title: "All done!",
    text: "There are no more products to review! If you want to see how you did compared to other players, you can check the leaderboard.",
  };
};

const GameOver: FC<GameOverProps> = ({ error, round }) => {
  const navigate = useNavigate();
  const { title, text } = getText(error, round);

  return (
    <GameOverContainer>
      <h1>{title}</h1>
      <p>{text} If you want to see how you did compared to other players, you can check the leaderboard.</p>
      <Button
        onClick={() => {
          navigate("/penguin-in-the-loop");
        }}
        overrides={overrides}
      >
        {" "}
        Leaderboard
      </Button>
      {round && (
        <Button
          onClick={() => {
            window.location.href = "/penguin-in-the-loop/default";
          }}
          overrides={overrides}
        >
          Play more
        </Button>
      )}
    </GameOverContainer>
  );
};

export default GameOver;
