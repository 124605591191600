import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { BaseProvider } from "baseui";
import { ClimateClickTheme } from "./theme";

const debug = process.env.NODE_ENV === "production" ? void 0 : new DebugEngine();

const engine = new Styletron();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <StyletronProvider value={engine} debug={debug} debugAfterHydration>
      <BaseProvider theme={ClimateClickTheme}>
        <App />
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
);
