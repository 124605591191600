import { styled, StyleObject } from "styletron-react";

export type ButtonType = "primary" | "secondary";
export type ButtonSize = "small" | "medium";

const buttonTypes: Record<ButtonType, StyleObject> = {
  primary: {
    transition: `background 0.2s ease-in-out`,
    background: `#015597`,
    ":hover": {
      background: `#002B4D`,
    },
    ":active": {
      transform: `scale(0.99)`,
    },
  },
  secondary: {
    transition: `color 0.2s ease-in-out`,
    background: 0,
    color: `#015597`,
    ":hover:enabled": {
      color: `#657eff`,
    },
    ":disabled": {
      cursor: `not-allowed`,
    },
  },
};

export const Button = styled<any, { $type: ButtonType; $size?: ButtonSize }>(`button`, (props) => ({
  color: `#FFF`,
  borderStyle: `none`,
  borderRadius: `3px`,
  fontSize: props.$size === "small" ? `14px` : `18px`,
  height: props.$size === "small" ? `30px` : `40px`,
  fontWeight: 600,
  cursor: `pointer`,
  padding: `0 20px`,
  ...buttonTypes[props.$type],
}));
