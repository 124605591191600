import { styled } from "styletron-react";
import { useState } from "react";
import { Tooltip } from "./Tooltip";

const CopyIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 5C1.34315 5 0 6.34315 0 8V13C0 14.6569 1.34315 16 3 16H8C9.65685 16 11 14.6569 11 13H9C9 13.5523 8.55228 14 8 14H3C2.44772 14 2 13.5523 2 13V8C2 7.44772 2.44772 7 3 7V5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C6.34315 0 5 1.34315 5 3V8C5 9.65685 6.34315 11 8 11H13C14.6569 11 16 9.65685 16 8V3C16 1.34315 14.6569 0 13 0H8ZM8 2C7.44772 2 7 2.44772 7 3V8C7 8.55228 7.44772 9 8 9H13C13.5523 9 14 8.55229 14 8V3C14 2.44772 13.5523 2 13 2H8Z"
        fill="currentColor"
      />
    </svg>
  );
};

const CopyIconContainer = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  height: "100%",
  padding: "0 5px",
  cursor: "pointer",
});

interface CopyButtonProps {
  onClick: (e: React.MouseEvent) => void;
}

export const CopyButton = ({ onClick }: CopyButtonProps) => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    onClick(e);
    setShowToolTip(true);

    setTimeout(() => {
      setShowToolTip(false);
    }, 1000);
  };

  return (
    <CopyIconContainer onClick={handleClick}>
      <Tooltip $visible={showToolTip}>Copied to clipboard</Tooltip>
      <CopyIcon />
    </CopyIconContainer>
  );
};
