import type { ProductFootprint } from "api/types";

import { Typography } from "antd";
import { useStyletron } from "styletron-react";
import { Card, CardContents, CardDivider } from "./components/Card";
import { searchProduct } from "api/products";
import { useLogin } from "./useLogin";
import { useState } from "react";
import { SearchContainer, SearchInput } from "./components/SearchContainer";
import { Button } from "./components/Button";
import React from "react";
import { Table } from "baseui/table-semantic";
import { HomeButton } from "./components/HomeButton";
import { Link } from "react-router-dom";
import { Tabs } from "./components/Tabs";

const COLUMNS = [
  "Product name",
  "Taxonomy",
  "Emission Factor (kgCo2e)",
  "Formula",
  "Category used",
  "Weight (kg)",
  "Footprint (KgCo2e)",
  "Contribution (EUR)",
  "...",
];

export const Products = () => {
  const [css] = useStyletron();
  const credential = useLogin();
  const [products, setProducts] = useState<(string | number | React.ReactNode)[][]>();
  const [message, setMessage] = useState("");
  const newData: (string | number | React.ReactNode)[][] = [];

  const searchHandler = async () => {
    setProducts([]);
    try {
      const selectedProducts = await searchProduct(credential, message);

      selectedProducts.products.forEach((element: ProductFootprint, index: number) => {
        newData.push([
          element.ProductName,
          element.Taxonomy,
          Number(element.EmissionFactorKgCo2.toFixed(3)),
          element.Formula,
          element.CategoryUsed,
          element.ProductWeight / 1000,
          element.Footprint,
          element.ContributionInEurCents / 100,
          <Link key={index} to={`/product/${element.Id}`}>
            Edit
          </Link>,
        ]);
      });
      setProducts(newData);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };
  return (
    <Card
      className={css({
        maxWidth: "max-content !important",
      })}
    >
      <HomeButton />
      <Tabs />
      <CardDivider />
      <CardContents>
        <Typography.Title level={3}>Search by product name</Typography.Title>

        <SearchContainer>
          <SearchInput
            type="text"
            id="message"
            name="message"
            onChange={handleChange}
            placeholder="Enter your search"
          />

          <Button onClick={searchHandler} $type="primary">
            Search
          </Button>
        </SearchContainer>
        <Table columns={COLUMNS} data={products} />
      </CardContents>
    </Card>
  );
};
