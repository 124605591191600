import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Drawer } from "baseui/drawer";

import { useLogin } from "useLogin";
import { Response } from "components/Response";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Table } from "baseui/table";
import { ImportDetails, ImportsList } from "./import";
import { Organization } from "api/types";
import { getDashboardURL, getOrganization } from "api/organizations";

import { OrganizationsTopBar } from "../demo/organizations.styled";
import { OrganizationDetailsContainer, OrganizationDetailsTitle } from "./organizationDetails.styled";

export const OrganizationDetails: FC = () => {
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [importId, setImportId] = useState<string>("");
  const credentials = useLogin();
  const { organizationId } = useParams() as { organizationId: string };
  const [organization, setOrganization] = useState<Organization>({} as Organization);
  const navigate = useNavigate();

  const returnToOrganizationList = () => {
    navigate("/organizations");
  };

  const openDrawer = (id: string) => () => {
    setImportId(id);
    setDrawerState(true);
  };

  const openDashboard = async () => {
    try {
      const url = await getDashboardURL(organization.ApiKey);
      window.open(url, "_blank", "noreferrer");
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    const main = async () => {
      try {
        const org = await getOrganization(credentials, organizationId);
        setOrganization(org);
      } catch (error: any) {
        console.error(error);
      }
    };
    main();
  }, [organizationId]);

  return (
    <Card
      $style={{
        maxWidth: "800px !important",
        width: "100% !important",
      }}
    >
      <OrganizationsTopBar>
        <Button
          $type="secondary"
          onClick={returnToOrganizationList}
          $style={{
            boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
          }}
        >
          Back
        </Button>
        <OrganizationDetailsTitle>{organization.Name}</OrganizationDetailsTitle>
      </OrganizationsTopBar>

      <OrganizationDetailsContainer>
        <p>
          <a target="_blank" rel="noreferrer" href={organization.Website}>
            {organization.Website}
          </a>
        </p>
        <Button $type="primary" onClick={openDashboard} $style={{ marginBottom: "30px", marginTop: "15px" }}>
          Open Dashboard
        </Button>
        <Response response={organization.ApiKey} title={"API Key"}></Response>
        <h3>API Scopes</h3>
        <Table
          columns={["Name", "Description"]}
          data={(organization.Scopes || []).map((scope: { name: any; description: any }) => {
            return [scope.name, scope.description];
          })}
        />
        {organization.Scopes && organization.Scopes.some((scope) => scope.name === "PRODUCT_IMPORT_V2") && (
          <div>
            <h3>Imports</h3>
            <ImportsList organizationId={organization.OrganizationId} openDrawer={openDrawer} />
          </div>
        )}
      </OrganizationDetailsContainer>
      <Drawer onClose={() => setDrawerState(false)} isOpen={drawerState} size={"full"}>
        <ImportDetails
          importId={importId}
          organizationId={organizationId}
          isOpen={drawerState}
          onClose={() => {
            setDrawerState(false);
          }}
        ></ImportDetails>
      </Drawer>
    </Card>
  );
};
