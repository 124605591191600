import { useState, type FC } from "react";
import { Button } from "components/Button";
import Table from "components/Table";
import { OrgSectionContainer, OrgSectionHeader } from "./organizations.styled";
import { camelCaseToTitleCase } from "stringFormatters";
import { CreateRetirementRequest, Retirement } from "api/types";
import { NewRetirementFormData, OrganizationRetirementForm } from "./OrganizationRetirementForm";
import { FieldError, FieldErrors, useForm } from "react-hook-form";
import { parseErrors } from "./inputValidation";
import { ValidationError } from "api/errors";
import { ToasterContainer, toaster } from "baseui/toast";

interface OrganizationRetirementsProps {
  orgID: string;
  allRetirements: Retirement[];
  addRetirement: (orgID: string, retirement: CreateRetirementRequest) => Promise<void>;
}

export const OrganizationRetirements: FC<OrganizationRetirementsProps> = ({ orgID, allRetirements, addRetirement }) => {
  const [retirementFormOpen, setRetirementFormOpen] = useState<boolean>(false);

  const {
    register,
    formState: { errors },
    setError,
  } = useForm<NewRetirementFormData>();

  const setErrors = (errors: FieldErrors<NewRetirementFormData>) => {
    for (const [key, value] of Object.entries(errors)) {
      setError(key as keyof NewRetirementFormData, value as FieldError);
    }
  };

  const onSubmit = async (formData: NewRetirementFormData) => {
    try {
      await addRetirement(orgID, formData);
      toaster.positive("New retirement added successfully, please refresh the page to see the changes.");
    } catch (e) {
      if (e instanceof ValidationError) {
        setErrors(parseErrors(e));
        return;
      }
      console.error(e);
      toaster.negative("Failed to add retirement");
      return;
    }

    setRetirementFormOpen(false);
  };

  return (
    <OrgSectionContainer>
      <OrgSectionHeader>Retirements</OrgSectionHeader>
      <Button $type="primary" $size="small" onClick={() => setRetirementFormOpen(true)}>
        Add retirement
      </Button>
      {retirementFormOpen && (
        <OrganizationRetirementForm
          onSubmit={onSubmit}
          register={register}
          setModalState={setRetirementFormOpen}
          errors={errors}
        />
      )}
      <Table
        data={allRetirements}
        emptyMessage="No retirements for this organization"
        omitColumns={["ID", "organizationId"]}
        headerFormatter={camelCaseToTitleCase}
      />
      <ToasterContainer autoHideDuration={3000} placement={"top"} />
    </OrgSectionContainer>
  );
};
