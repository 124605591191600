import { styled } from "styletron-react";

export const OrganizationDetailsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "left",
  width: "100%",
  padding: "10px",
});

export const OrganizationDetailsTitle = styled("div", {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  fontWeight: "bold",
  fontSize: "25px",
  textAlign: "center",
  color: "#015597",
});
