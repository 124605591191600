import type { Taxonomy, Category } from "../cms.types";
import type {
  Category as APICategory,
  TaxonomyResponse,
  MaterialCompositionResponse,
  ProductMinMaxWeightsResponse,
} from "api/cms/types";

import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLogin } from "useLogin";
import { Card } from "components/Card";
import { HomeButton } from "components/HomeButton";
import PaginatedTable from "components/PaginatedTable";
import {
  DetailsContainer,
  DetailField,
  DetailLabel,
  DetailValue,
  DetailValueLink,
  DetailValueList,
  ErrorField,
} from "../cms.styled";
import { nullToNotAvailable } from "../cms.utils";

import { getTaxonomy, updateTaxonomyWeight, getTaxonomyProductsMinMaxWeight } from "api/cms/taxonomies";
import { getCategories } from "api/cms/categories";
import { getCustomerProducts, getCustomerProductCount } from "api/cms/taxonomies";
import { getMaterialCompositions } from "api/cms/material_compositions";
import { APIError, ValidationError } from "api/errors";

import { EditableDetail } from "../common";

import { camelCaseToTitleCase } from "stringFormatters";

const mapTaxonomyData = (response: TaxonomyResponse): Taxonomy => ({
  id: response.id,
  name: response.name,
  fullName: response.fullName,
  parentTaxonomy: response.parentTaxonomy
    ? response.parentTaxonomy
    : response.fullName.split(" > ").slice(0, -1).join(" > "),
  parentTaxonomyID: response.parentTaxonomyID,
  level: response.level,
  minWeight: response.minWeight,
  maxWeight: response.maxWeight,
  allowedMaterials: response.allowedMaterials ? response.allowedMaterials : "n/a",
  conservativeEF: response.conservativeEF,
});

const mapCategoryData = (response: APICategory): Category => ({
  id: response.id,
  name: response.name,
  taxonomyID: response.taxonomy.id,
  taxonomyName: response.taxonomy.name,
  emissionFactor: response.emissionFactor,
  source: response.source,
  sourceID: response.sourceID,
  countryCode: response.countryCode,
  region: response.region,
});

export const TaxonomyDetails = () => {
  const pageSize = 20;
  const { taxonomyID } = useParams() as { taxonomyID: string };
  const credential = useLogin();
  const [taxonomy, setTaxonomy] = useState<Taxonomy>();
  const [categories, setCategories] = useState<Category[]>();
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [minMaxWeights, setMinMaxWeights] = useState<ProductMinMaxWeightsResponse>();
  const [updateError, setUpdateError] = useState<string>();
  const [materialCompositions, setMaterialCompositions] = useState<MaterialCompositionResponse[]>();
  const navigate = useNavigate();

  const getValueElement = (key: string, value: any) => {
    if (key === "parentTaxonomyID") {
      if (value === 0) {
        return (
          <DetailValueLink
            onClick={() => {
              navigate(`/cms/taxonomies`);
            }}
          >
            None
          </DetailValueLink>
        );
      }
      return (
        <DetailValueLink
          onClick={() => {
            navigate(`/cms/taxonomies/${value}`);
          }}
        >
          {value}
        </DetailValueLink>
      );
    }
    return <DetailValue>{nullToNotAvailable(value)}</DetailValue>;
  };

  useEffect(() => {
    const main = async () => {
      try {
        const response = await getTaxonomy(credential, taxonomyID);
        setTaxonomy(mapTaxonomyData(response));
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [credential, taxonomyID]);

  useEffect(() => {
    const main = async () => {
      try {
        const response = await getCustomerProductCount(credential, taxonomyID);
        setTotalProducts(response.count);
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [credential, taxonomyID]);

  useEffect(() => {
    const main = async () => {
      try {
        const response = await getCategories(credential, taxonomyID, 200);
        setCategories(response.map(mapCategoryData));
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [credential, taxonomyID]);

  useEffect(() => {
    const main = async () => {
      try {
        const response = await getMaterialCompositions(credential, taxonomyID);
        setMaterialCompositions(response);
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [credential, taxonomyID]);

  useEffect(() => {
    const main = async () => {
      if (!taxonomy || taxonomy.level < 3) {
        return;
      }
      try {
        const response = await getTaxonomyProductsMinMaxWeight(credential, taxonomyID);
        setMinMaxWeights(response);
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [credential, taxonomy]);

  const fetchCustomerProducts = async (page: number) => {
    const offset = page * pageSize;
    try {
      const response = await getCustomerProducts(credential, taxonomyID, offset, pageSize);
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const handleWeightEdit = (field: string) => {
    return async (newValue: any) => {
      const newWeight = parseInt(newValue);
      setUpdateError(undefined);
      try {
        await updateTaxonomyWeight(credential, taxonomyID, field, newWeight);
      } catch (e) {
        if (e instanceof APIError) {
          setUpdateError(e.message);
          return;
        }
        if (e instanceof ValidationError) {
          setUpdateError("Invalid weight value: ${e.message}");
          console.error(e.invalidFields);
          return;
        }
      }
      setTaxonomy((prev) => {
        return {
          ...prev,
          [field]: newWeight,
        } as Taxonomy;
      });
    };
  };

  return (
    <Card>
      <DetailsContainer>
        <HomeButton text="Taxonomies Home" navigateTo="/cms/taxonomies" />
        <h1>Taxonomy Details</h1>
        {taxonomy && (
          <>
            {Object.entries(taxonomy)
              .filter(([label]) => {
                return label !== "minWeight" && label !== "maxWeight";
              })
              .map(([label, value]) => {
                return (
                  <DetailField key={label}>
                    <DetailLabel>{camelCaseToTitleCase(label)}:</DetailLabel>
                    {getValueElement(label, value)}
                  </DetailField>
                );
              })}
            {minMaxWeights && (
              <>
                <DetailField>
                  <DetailLabel>Min Weight in grams:</DetailLabel>
                  <EditableDetail
                    labelText="Calculated from customer products:"
                    value={taxonomy.minWeight}
                    suggestedValue={minMaxWeights.minProductWeight}
                    onEdit={handleWeightEdit("minWeight")}
                  />
                </DetailField>
                <DetailField>
                  <DetailLabel>Max Weight in grams:</DetailLabel>
                  <EditableDetail
                    labelText="Calculated from customer products:"
                    value={taxonomy.maxWeight}
                    suggestedValue={minMaxWeights.maxProductWeight}
                    onEdit={handleWeightEdit("maxWeight")}
                  />
                </DetailField>
                {updateError && <ErrorField>{updateError}</ErrorField>}
              </>
            )}
          </>
        )}
        {materialCompositions && (
          <DetailField>
            <DetailLabel>Material Compositions:</DetailLabel>
            <DetailValueList>
              {materialCompositions.map((materialComposition: MaterialCompositionResponse) => {
                return (
                  <DetailValueLink
                    key={materialComposition.id}
                    onClick={() => {
                      navigate(`/cms/materialComposition/${materialComposition.id}`);
                    }}
                  >
                    {materialComposition.items.map((item) => `${item.material.name} - ${item.percentage}%`).join(", ")}
                  </DetailValueLink>
                );
              })}
            </DetailValueList>
          </DetailField>
        )}
        {categories && (
          <DetailField>
            <DetailLabel>Categories:</DetailLabel>
            <DetailValueList>
              {categories.map((category: Category) => {
                return (
                  <DetailValueLink
                    key={category.id}
                    onClick={() => {
                      navigate(`/cms/category/${category.id}`);
                    }}
                  >
                    {category.name} | {category.source ? category.source : "No source"}
                  </DetailValueLink>
                );
              })}
            </DetailValueList>
          </DetailField>
        )}
      </DetailsContainer>

      <PaginatedTable
        total={totalProducts}
        pageSize={pageSize}
        getData={fetchCustomerProducts}
        dataContentName="products"
        omitColumns={[
          "organizationId",
          "approvedAt",
          "customerMaterialComposition",
          "validatedProduct",
          "taxonomy",
          "category",
          "unitPriceInCents",
          "currency",
          "importDate",
          "brand",
          "userCategory",
          "approvedAccuracyLevel",
        ]}
        filterColumn="name"
        headerFormatter={camelCaseToTitleCase}
        emptyMessage={"No products for this taxonomy yet"}
      />
    </Card>
  );
};
