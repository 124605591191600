import type { Retirement, CreateRetirementRequest } from "./types";
import { makeCopilotRequest } from "./common";

export const getOrganizationRetirements = async (
  credentials: string,
  organizationId: string
): Promise<Retirement[] | undefined> => {
  const response = await makeCopilotRequest(`organization/${organizationId}/retirements`, "GET", credentials);
  return response as Retirement[];
};

export const addOrganizationRetirement = async (
  credentials: string,
  organizationId: string,
  data: CreateRetirementRequest
): Promise<any | undefined> => {
  return await makeCopilotRequest(`organization/${organizationId}/retirements`, "POST", credentials, data);
};
