import { FC, useEffect, useState } from "react";
import { useStyletron } from "styletron-react";
import GameOver from "./GameOver";
import { Loading } from "../components/Loading";
import { Product } from "./Game";
import { styled } from "styletron-react";

const HEIGHT = "55vh";

const ProductContainer = styled(`div`, {
  height: HEIGHT,
});

const fadeInAnimation = (name: string) => {
  const duration = name === "loading" ? 1 : 0.3;
  return `
    .${name} {
      animation: fade-${name} ${duration}s ease-in-out;
    }
    @keyframes fade-${name} {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;
};

interface GameProductProps {
  product: Product | undefined | null;
  isLoading: boolean;
}

const ImageWithFallback: FC<{ src: string }> = ({ src }) => {
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <>
      {!imageError && (
        <img
          src={src}
          alt={"Product"}
          onError={handleImageError}
          style={{ borderRadius: `16px`, margin: `auto`, height: `100%` }}
        />
      )}
      {imageError && (
        <div style={{ margin: "auto" }}>
          Image not available at the moment. If you need more information about this product, please click here.
        </div>
      )}
    </>
  );
};

export const GameProduct: FC<GameProductProps> = ({ product, isLoading }) => {
  const [css] = useStyletron();
  const [productIsLoaded, setProductIsLoaded] = useState(product && !isLoading);
  const loadingClassName = "loading";
  const productClassName = "product";

  useEffect(() => {
    if (product && !isLoading) {
      setProductIsLoaded(true);
    } else {
      setProductIsLoaded(false);
    }
  }, [product, isLoading]);

  if (!productIsLoaded) {
    return (
      <div className={loadingClassName}>
        <style>{fadeInAnimation(loadingClassName)}</style>
        <Loading size={HEIGHT} />
      </div>
    );
  }

  if (!product) {
    return <GameOver round={true} error={true} />;
  }

  return (
    <ProductContainer className={productClassName} style={{ height: HEIGHT }}>
      <style>{fadeInAnimation(productClassName)}</style>
      <a href={product.url} target="_blank" className={css({ textDecoration: `none` })} rel="noreferrer">
        <div
          className={css({
            borderRadius: `16px`,
            overflow: `hidden`,
            textAlign: `center`,
            height: `65%`,
            width: `100%`,
            display: `flex`,
            justifyContent: `center`,
          })}
        >
          <ImageWithFallback src={product.imageUrl || "missing"} />
        </div>
      </a>

      <h2
        className={css({
          fontSize: `20px`,
          textAlign: `center`,
          margin: `10px auto 0px auto`,
          color: `#000`,
          textDecoration: `none`,
          "@media (min-width: 1024px)": {
            height: `24px`,
          },
        })}
      >
        {product.name}
        {product.currency && product.unitPriceInCents && (
          <span>
            {" "}
            (
            {new Intl.NumberFormat(navigator.language, {
              style: `currency`,
              currency: product.currency,
              currencyDisplay: `symbol`,
            }).format(product?.unitPriceInCents / 100)}
            {product.brand ? `, ${product.brand}` : ""})
          </span>
        )}
      </h2>
      {product.translatedName && (
        <p
          className={css({
            fontSize: `16px`,
            textDecoration: `italic`,
            textAlign: `center`,
            margin: `20px auto 0px auto`,
            "@media (min-width: 1024px)": {
              height: `36px`,
            },
          })}
        >
          {product.translatedName}
        </p>
      )}
      {product.category && (
        <p
          className={css({
            fontSize: `16px`,
            textDecoration: `italic`,
            textAlign: `center`,
            margin: `20px auto 0px auto`,
            "@media (min-width: 1024px)": {
              height: `36px`,
            },
          })}
        >
          {product.category}
        </p>
      )}
      <h2
        className={css({
          fontSize: `20px`,
          textAlign: `center`,
          margin: `20px auto 0px auto`,
          "@media (max-width: 380px)": {
            fontSize: `16px`,
          },
        })}
      >
        {product.taxonomy}
      </h2>
    </ProductContainer>
  );
};
