import type { SectorialScope } from "./types";

import { makeCopilotRequest } from "./common";

export const addSectorialScopes = async (credentials: string, organizationId: string, sectorialScopes: string[]) => {
  await makeCopilotRequest(`/organization/${organizationId}/sectorialScopes`, "POST", credentials, {
    sectorialScopes,
  });
};

export const getSectorialScopes = async (credentials: string): Promise<SectorialScope[]> => {
  const response = await makeCopilotRequest("SectorialScopes", "GET", credentials);
  return response as SectorialScope[];
};
