import type { Product, SearchProductResponse } from "./types";

import { makeCopilotRequest } from "./common";

export const getProduct = async (credentials: string, productId: string): Promise<Product> => {
  const response = await makeCopilotRequest(`GetProduct?id=${productId}`, "GET", credentials);
  return response as Product;
};

export const searchProduct = async (credentials: string, productName: string): Promise<SearchProductResponse> => {
  const response = await makeCopilotRequest(`SearchProduct?name=${productName}`, "GET", credentials);
  return response as SearchProductResponse;
};
