export const snakeCaseToTitleCase = (str: string): string =>
  (str || "not_set")
    .toLowerCase()
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(" ");

export const camelCaseToTitleCase = (str: string): string =>
  (str || "notSet")
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
    .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize the first letter

export const formatDateTime = (timestamp: string) => {
  const date = new Date(timestamp);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const formatCurrency = (price: number, currencyCode = "EUR") => {
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "symbol",
  }).format(price / 100);
};

export const formatEmissions = (emissions: number) => {
  if (emissions === undefined || emissions === null) return "";
  if (typeof emissions === "string") emissions = parseFloat(emissions);
  if (emissions > 0.01) return `${emissions.toFixed(2)}`;
  return `<0.01`;
};
