export class NotAuthorizedError extends Error {}

export class APIError extends Error {
  message: string;

  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export class ValidationError extends APIError {
  invalidFields: Record<string, string>;

  constructor(message: string, fields: Record<string, string>) {
    super(message);
    this.invalidFields = fields;
  }
}
