import type { FC } from "react";

import { styled } from "styletron-react";

const EmptyTableContainer = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  fontSize: "20px",
  fontWeight: "bold",
});

type EmptyTableProps = {
  message?: string;
};

const EmptyTable: FC<EmptyTableProps> = ({ message = "No data" }) => {
  return (
    <EmptyTableContainer>
      <p>{message}</p>
    </EmptyTableContainer>
  );
};

export default EmptyTable;
