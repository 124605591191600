import { useEffect, useState } from "react";

const CountdownTimer = ({ date }: { date: string }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const targetDate = new Date(date).getTime();
      const now = new Date().getTime();
      const timeLeft = targetDate - now;

      return timeLeft;
    };

    setTimeLeft(calculateTimeLeft());

    const timerId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId);
  }, [date]);

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  return (
    <div>
      <b>Time left in current round:</b> {`${days} Days, ${hours} Hours, ${minutes} Minutes, ${seconds} Seconds`}
    </div>
  );
};

export default CountdownTimer;
