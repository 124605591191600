import type { GameLeader } from "api/types";

import { useStyletron } from "styletron-react";
import { useEffect, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { useLogin } from "../useLogin";
import { Crown } from "../components/Crown";
import { Button } from "../components/Button";
import { getTopleaders } from "api/penguinInTheLoop";

interface LeaderBoardProps {
  gameRound: string;
}

export const LeaderBoard: FC<LeaderBoardProps> = ({ gameRound }) => {
  const [css] = useStyletron();
  const [leaders, setLeaders] = useState<GameLeader[]>();
  const credential = useLogin();
  const navigate = useNavigate();
  const [gameRoundID, setGameRoundID] = useState<string>();

  useEffect(() => {
    if (gameRound !== undefined && gameRound !== "") {
      setGameRoundID(gameRound);
    }
  }, [gameRound]);

  useEffect(() => {
    const loadLeaders = async () => {
      try {
        const leaders = await getTopleaders(credential, gameRoundID);
        setLeaders(leaders);
      } catch (e) {
        console.error(e);
      }
    };
    loadLeaders();
  }, [credential, gameRoundID]);
  const onPlay = () => {
    if (gameRound === "" || gameRound === undefined) {
      navigate(`/penguin-in-the-loop/default`);
    } else {
      navigate(`/penguin-in-the-loop/${gameRound}`);
    }
  };

  return (
    <>
      <h1>Penguin Pinnacle</h1>
      <p className={css({ fontSize: `18px`, lineHeight: `24px` })}>
        Meet this round's feathered carbon crusaders, paving the way for a greener world. Think you have what it takes
        to outshine these waddling wonders? Join the ranks and prove your prowess while training our AI in rectifying
        those pesky mismatches.
      </p>

      <Button onClick={onPlay} $type="primary">
        Play "Penguin in the Loop"
      </Button>

      <p
        className={css({
          fontSize: `16px`,
          lineHeight: `22px`,
          color: `#8B8B8B`,
        })}
      >
        Remember, when the round is over, a new champion will be hailed, and the scores shall reset. Get ready to dive
        into the competition and join the pinnacle!
      </p>

      <ul
        className={css({
          margin: `40px 0 0 0`,
          padding: `0`,
          listStyleType: `none`,
        })}
      >
        {leaders?.map((leader, index) => (
          <li key={leader.Name} className={css({ clear: `both`, height: `85px` })}>
            <div
              className={css({
                width: `74px`,
                height: `74px`,
                position: `relative`,
                float: `left`,
              })}
            >
              <img
                className={css({
                  position: `absolute`,
                  left: 0,
                  top: 0,
                  borderRadius: `37px`,
                  margin: `auto`,
                  height: `74px`,
                  width: `74px`,
                })}
                alt={leader.Name}
                src={leader.Picture}
              />
              {index === 0 && (
                <div
                  className={css({
                    position: `absolute`,
                    left: `5px`,
                    top: `-15px`,
                  })}
                >
                  <Crown />
                </div>
              )}
            </div>
            <div className={css({ marginLeft: `90px` })}>
              <div
                className={css({
                  fontSize: `18px`,
                  fontWeight: 600,
                  paddingTop: `15px`,
                })}
              >
                {leader.Name}
              </div>
              <div className={css({ color: `#8B8B8B` })}>{leader.Score.toLocaleString()} points</div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
