import { FC } from "react";
import { Divider, Typography } from "antd";
import { styled, useStyletron } from "styletron-react";

export interface DescriptionProps {
  title: string;
  explanation?: string[];
}

const DescriptionContainer = styled("div", {});
const Explanation = styled("div", {
  paddingLeft: "5px",
});

export const Description: FC<DescriptionProps> = ({ title, explanation }) => {
  const { Text } = Typography;
  const [css] = useStyletron();
  return (
    <DescriptionContainer>
      <Divider />
      <Text className={css({ paddingLeft: "5px" })}>ℹ️ {title}</Text>
      {explanation ? (
        <Explanation>
          <Divider />
          {explanation.map((line, idx) => {
            return (
              <div key={idx}>
                <Text italic>{(idx === 0 ? `❓ ` : "") + line}</Text>
                <br />
              </div>
            );
          })}
        </Explanation>
      ) : null}
      <Divider />
    </DescriptionContainer>
  );
};
