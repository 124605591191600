import { styled, StyleObject } from "styletron-react";

import type { ImportStatus } from "api/types";

export const Imports = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "left",
  width: "max-content",
  maxHeight: "50vh",
  overflowY: "auto",
  border: "1px solid #ddd",
  borderRadius: "5px",
  backgroundColor: "#f8f8f8",
  marginBottom: "10px",
});

export const importStatusStyles: Record<ImportStatus, StyleObject> = {
  pending: {
    color: "#015597",
  },
  pending_with_errors: {
    color: "#AA5597",
  },
  finished_with_errors: {
    color: "#AA0000",
  },
  success: {
    color: "#00AA00",
  },
  failed_to_start: {
    color: "#AA0000",
  },
};

export const ImportListItem = styled<any, { $status: ImportStatus }>("div", ({ $status }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  padding: "10px",
  borderTop: "1px solid #ddd",
  fontWeight: 500,
  backgroundColor: "#f8f8f8",
  ":hover": {
    cursor: "pointer",
    backgroundColor: "#eee",
  },
  ...importStatusStyles[$status],
}));

export const NewImportButton = styled("div", {
  position: "sticky",
  top: "0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  fontWeight: 600,
  width: "100%",
  backgroundColor: "#f8f8f8",
  transition: `color 0.2s ease-in-out`,
  background: 0,
  color: `#015597`,
  ":hover": {
    cursor: "pointer",
    backgroundColor: "#eee",
    color: `#657eff`,
  },
});

export const ImportTutorialList = styled("ol", {
  margin: `10px 10px 20px 5px`,
});

export const DropZone = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  minHeight: "100px",
  minWidth: "375px",
  border: "2px dashed #ccc",
  borderRadius: "5px",
  padding: "20px",
  ":hover": {
    cursor: "pointer",
  },
});

export const InputError = styled(`p`, {
  color: `#f00`,
  position: `absolute`,
  marginTop: `40px`,
  fontWeight: 500,
  fontSize: `12px`,
});
