import { makeCopilotRequest } from "api/common";
import { FootprintResponse } from "./types";

export const getFootprint = async (
  credentials: string,
  organizationID: string,
  productID: string
): Promise<FootprintResponse> => {
  const response = await makeCopilotRequest(
    `cms/Footprint/Calculate?organizationID=${organizationID}&productID=${productID}`,
    "GET",
    credentials
  );
  return response as FootprintResponse;
};
