import type { Product, ProductCountResponse } from "./types";

import { makeCopilotRequest } from "api/common";

export const getCustomerProducts = async (
  credentials: string,
  organizationID: string,
  offset = 0,
  limit = 0
): Promise<Product[]> => {
  const response = await makeCopilotRequest(
    `cms/CustomerProducts?offset=${offset}&limit=${limit}&organizationID=${organizationID}`,
    "GET",
    credentials
  );
  return response as Product[];
};

export const getCustomerProductCount = async (
  credentials: string,
  organizationID: string
): Promise<ProductCountResponse> => {
  const response = await makeCopilotRequest(`cms/CustomerProducts/${organizationID}/ProductCount`, "GET", credentials);
  return response as ProductCountResponse;
};
