import type { TaxonomyCoverage } from "api/cms/types";

import { useEffect } from "react";
import { getConservativeCoverage } from "api/cms/coverage";
import { useLogin } from "useLogin";
import { useState } from "react";
import Table from "components/Table";
import { HomeButton } from "components/HomeButton";
import { TableContainer } from "../cms.styled";
import { camelCaseToTitleCase } from "stringFormatters";

export const Coverage = () => {
  const credential = useLogin();
  const [conservativeCoverage, setConservativeCoverage] = useState<TaxonomyCoverage[]>();

  useEffect(() => {
    const main = async () => {
      try {
        const response = await getConservativeCoverage(credential);
        setConservativeCoverage(response);
      } catch (error) {
        console.error(error);
      }
    };
    main();
  }, [credential]);

  return (
    <TableContainer draggable={false}>
      <HomeButton text="CMS Home" navigateTo="/cms" />
      <div>
        <p>
          This overview shows the percentage of taxonomies withing a root taxonomy that have a conservative footprint
          available. Conservative footprint is calculated from the available data for taxonomy like validated products
          and material compositions. If those are not available for a taxonomies of a root taxonomy, coverage will be
          closer to zero.
        </p>
        <Table data={conservativeCoverage || []} headerFormatter={camelCaseToTitleCase} />
      </div>
    </TableContainer>
  );
};
