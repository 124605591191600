import { styled } from 'styletron-react';

export const SearchContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '500px',
  margin: '0 auto',
});

export const SearchInput = styled('input', {
  marginRight: '10px',
  padding: '10px',
  fontSize: '14px',
  flex: '1',
  borderRadius: '5px 5px 5px 5px',
  border: '1px solid #ccc',
  height: `32px`,
});
