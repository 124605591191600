import type { Product as APIProduct, TaxonomyRating } from "api/types";

import { useEffect, useState } from "react";
import { useStyletron } from "styletron-react";
import { Logo } from "../components/Logo";
import { useLogin } from "../useLogin";
import { useNavigate, useParams } from "react-router-dom";
import GameOver from "./GameOver";
import { GameProduct } from "./GameProduct";
import { toaster, ToasterContainer } from "baseui/toast";
import Instructions from "./Instructions";
import { getRandomProduct, rateProductTaxonomy } from "api/penguinInTheLoop";

export type Product = {
  id: string;
  name: string;
  category?: string;
  translatedName?: string;
  taxonomy: string;
  imageUrl: string;
  url: string;
  currency?: string;
  unitPriceInCents?: number;
  brand?: string;
};

const convertProduct = (product: APIProduct): Product => {
  return {
    id: product.Id,
    name: product.Name,
    translatedName: product.TranslatedName,
    taxonomy: product.Taxonomy,
    imageUrl: product.ImageUrls?.[0],
    url: product.Url,
    currency: product.Currency,
    unitPriceInCents: product.UnitPriceInCents,
    brand: product.Brand,
    category: product.Category,
  };
};

const timeOut = (ms: number) =>
  new Promise<APIProduct>((_, reject) => setTimeout(() => reject(new Error("Timeout")), ms));

const fetchRandomProduct = async (
  credential: string,
  setHasErrored: React.Dispatch<React.SetStateAction<boolean>>,
  gameRoundID?: string
) => {
  try {
    const product = await Promise.race([getRandomProduct(credential, gameRoundID), timeOut(10000)]);
    return product;
  } catch (e) {
    setHasErrored(true);
    return undefined;
  }
};

let retries = 0;
const RETRY_LIMIT = 10;

const fetchValidRandomProduct = async (
  credential: string,
  setHasErrored: React.Dispatch<React.SetStateAction<boolean>>,
  gameRoundID?: string
): Promise<APIProduct | null> => {
  const product = await fetchRandomProduct(credential, setHasErrored, gameRoundID);
  if (product && product.Taxonomy) {
    retries = 0;
    return product;
  } else if (retries < RETRY_LIMIT) {
    retries++;
    return fetchValidRandomProduct(credential, setHasErrored, gameRoundID);
  }
  return null;
};

export const Game = () => {
  const credential = useLogin();
  const navigate = useNavigate();
  const [css] = useStyletron();
  const [product, setProduct] = useState<Product | null>();
  const [productLoading, setProductLoading] = useState(true);
  const [gameFinished, setGameFinished] = useState(false);
  const [gameErrored, setGameErrored] = useState(false);
  const [lastToast, setLastToast] = useState<React.Key | undefined>(undefined);
  const [width] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const { gameRoundId } = useParams();
  const roundID = !gameRoundId || gameRoundId === "default" ? undefined : gameRoundId;

  useEffect(() => {
    const main = async () => {
      if (gameFinished) {
        return;
      }
      await loadRandomProduct(setProduct);
      setProductLoading(false);
    };
    main();
  }, []);

  useEffect(() => {
    if (!product && !productLoading) {
      setGameFinished(true);
    }
  }, [product]);

  useEffect(() => {
    if (gameErrored) {
      setGameFinished(true);
      setProductLoading(false);
    }
  }, [gameErrored]);

  const onRate = async (rating: TaxonomyRating) => {
    if (!(gameFinished || gameErrored) && rating !== undefined && product) {
      try {
        await rateProductTaxonomy(credential, product.id, rating, roundID);
      } catch (error) {
        console.error(error);
        return;
      }
    }
    if (isMobile) {
      toaster.clear(lastToast);
    }
    setTimeout(() => {
      showToast(rating);
    }, 200);
    updateProducts();
  };

  const showToast = (rating: TaxonomyRating, error = false) => {
    if (rating === true) {
      setLastToast(toaster.positive("Product approved!"));
    } else if (rating === false) {
      setLastToast(toaster.negative("Product disapproved!"));
    } else if (rating === "skip") {
      setLastToast(toaster.info("Product skipped!"));
    } else if (error) {
      setLastToast(toaster.warning("Something went wrong, please try again!"));
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        onRate(false);
      } else if (event.key === "ArrowRight") {
        onRate(true);
      } else if (event.key === "ArrowDown") {
        onRate("skip");
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  const loadRandomProduct = async (set: React.Dispatch<React.SetStateAction<Product | undefined | null>>) => {
    if (gameFinished) {
      return;
    }
    try {
      const data = await fetchValidRandomProduct(credential, setGameErrored, roundID);
      if (!data) {
        setGameFinished(true);
        return;
      }
      set(convertProduct(data));
    } catch (error) {
      console.error(error);
    }
  };

  const updateProducts = async () => {
    setProductLoading(true);
    await loadRandomProduct(setProduct);
    setProductLoading(false);
  };

  const onLeaderboard = () => {
    navigate(`/penguin-in-the-loop`);
  };

  if (gameFinished || gameErrored) {
    return <GameOver round={gameRoundId !== "default"} error={gameErrored} />;
  }

  return (
    <div className={css({ margin: `auto`, maxWidth: `600px` })}>
      <div
        className={css({
          margin: `10px`,
          textAlign: `center`,
          color: `#1A2E76`,
        })}
      >
        <div style={{ marginBottom: "10px" }}>
          <Logo />
        </div>
        <GameProduct product={product} isLoading={productLoading} />
      </div>
      <div
        className={css({
          display: `flex`,
          gap: `20px`,
          justifyContent: `center`,
          marginTop: `50px`,
        })}
      >
        <button
          onClick={() => {
            onRate(false);
          }}
          disabled={productLoading}
          className={css({
            width: `94px`,
            height: `94px`,
            background: `#CD3C00`,
            borderRadius: `47px`,
            borderStyle: `none`,
            cursor: `pointer`,
            transition: `opacity 0.6s ease-in-out`,
            ":hover:enabled": {
              background: `#742220`,
            },
            ":disabled": {
              opacity: 0.5,
              cursor: `not-allowed`,
            },
            "@media (max-width: 380px)": {
              width: `64px`,
              height: `64px`,
            },
          })}
        >
          <svg
            className={css({
              marginTop: `3px`,
              width: `52px`,
              height: `52px`,
              "@media (max-width: 380px)": {
                width: `40px`,
                height: `40px`,
              },
            })}
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M49 3L3 49" stroke="white" strokeWidth="6" strokeLinecap="round" />
            <path d="M49 49L3 3" stroke="white" strokeWidth="6" strokeLinecap="round" />
          </svg>
        </button>
        <button
          onClick={() => {
            onRate(true);
          }}
          disabled={productLoading}
          className={css({
            width: `94px`,
            height: `94px`,
            background: `#009F78`,
            borderRadius: `47px`,
            borderStyle: `none`,
            cursor: `pointer`,
            transition: `opacity 0.6s ease-in-out`,
            ":hover:enabled": {
              background: `#005F48`,
            },
            ":disabled": {
              opacity: 0.5,
              cursor: `not-allowed`,
            },
            "@media (max-width: 380px)": {
              width: `64px`,
              height: `64px`,
            },
          })}
        >
          <svg
            className={css({
              width: `52px`,
              height: `52px`,
              "@media (max-width: 380px)": {
                width: `40px`,
                height: `40px`,
              },
            })}
            viewBox="0 0 45 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M42.3379 17.8907H29.2105C29.1521 17.894 29.0914 17.897 29.0347 17.9007C28.2622 17.9063 27.7455 17.2116 27.9213 16.3815C28.1144 14.5877 28.953 8.86099 28.953 7.79106C28.953 6.58769 28.759 5.37406 28.5405 4.18356C28.3281 3.02255 27.8738 1.92805 27.0281 1.04672C26.1158 0.0971892 24.9928 -0.237397 23.7121 0.171325C22.4521 0.573116 21.6188 1.38732 21.552 2.74092C21.4604 4.58805 21.4272 6.43746 21.3512 8.28553C21.341 8.5321 21.2772 8.80216 21.1541 9.01464C19.2594 12.2853 17.3273 15.5353 15.4627 18.8218C15.0233 19.5966 14.5324 19.8021 13.5852 20.0168C12.6957 20.1168 11.0187 20.2532 10.1027 20.3409V39.2036C11.2165 39.2036 12.5918 39.9145 12.9661 40.1273C15.1397 41.3671 17.3225 42.5916 19.5087 43.8096C19.7031 43.9181 19.9484 43.9953 20.1703 43.9956C25.5231 44.0055 30.0747 43.9956 35.4273 43.9956C36.2049 43.9956 38.7487 43.6726 39.4018 41.0107C40.0589 38.3298 37.3907 37.5034 37.3907 37.5034C37.3907 37.5034 40.6951 37.8876 41.2402 34.9272C41.7518 32.1498 39.2423 31.3174 39.1293 31.2733C40.1502 31.1082 42.4707 30.631 42.9602 28.5694C43.4593 26.4665 41.9296 25.119 41.1027 24.5809C42.3516 24.5548 44.7569 23.8171 44.9771 21.4516C45.2335 18.6987 43.274 17.8914 42.3376 17.8914L42.3379 17.8907ZM0 40.6856H8.51136V18.86H0V40.6856Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className={css({ textAlign: `center` })}>
        <button
          onClick={() => {
            onRate("skip");
          }}
          className={css({
            display: `inline-block`,
            border: 0,
            background: 0,
            color: `#737373`,
            fontSize: `14px`,
            margin: `10px auto auto auto`,
            cursor: `pointer`,
            ":hover": {
              textDecoration: `underline`,
            },
            "@media (min-width: 1024px)": {
              fontSize: `18px`,
            },
          })}
        >
          I don't know
        </button>
        &#x2022;
        <button
          onClick={onLeaderboard}
          className={css({
            display: `inline-block`,
            border: 0,
            background: 0,
            color: `#737373`,
            fontSize: `14px`,
            margin: `10px auto auto auto`,
            textAlign: `center`,
            cursor: `pointer`,
            ":hover": {
              textDecoration: `underline`,
            },
            "@media (min-width: 1024px)": {
              fontSize: `18px`,
            },
          })}
        >
          Back to leaderboard
        </button>
        <p
          className={css({
            textAlign: `center`,
            color: `#999`,
            margin: `10px auto 0px auto`,
            lineHeight: `18px`,
            fontSize: `14px`,
            maxWidth: `300px`,
            "@media (min-width: 1024px)": {
              lineHeight: `24px`,
              fontSize: `16px`,
            },
          })}
        >
          Does the shown product belong to the category? If you are not sure, you can skip the product by clicking on "I
          don't know".
        </p>
      </div>
      <ToasterContainer autoHideDuration={3000} placement={"bottomRight"}></ToasterContainer>
      <Instructions />
    </div>
  );
};
