export const Crown = () => {
  return (
    <svg
      width="38"
      height="27"
      viewBox="0 0 38 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.4861 0.101061C33.3169 0.0633164 33.142 0.125603 33.0348 0.261654L28.801 5.63134L22.6329 2.67955C22.4432 2.58885 22.2156 2.64174 22.0854 2.80681L17.8516 8.17649L11.6835 5.22471C11.4938 5.13401 11.2662 5.1869 11.136 5.35197L6.90218 10.7216L0.73407 7.76987C0.577828 7.69506 0.392184 7.7168 0.258153 7.82481C0.123694 7.93293 0.0627231 8.10895 0.101845 8.27725L4.17406 25.7961C4.23028 26.038 4.47175 26.1886 4.71384 26.1323L37.562 18.4968C37.8041 18.4405 37.9544 18.1989 37.8982 17.957L33.826 0.43817C33.7869 0.269868 33.6545 0.13879 33.4861 0.101061Z"
        fill="white"
      />
      <path
        d="M33.4861 0.101061C33.3169 0.0633163 33.142 0.125603 33.0348 0.261654L28.801 5.63134L22.6329 2.67955C22.4432 2.58885 22.2156 2.64174 22.0854 2.80681L17.8516 8.17649L11.6835 5.22471C11.4938 5.13401 11.2662 5.1869 11.136 5.35197L6.90218 10.7216L0.73407 7.76987C0.577828 7.69506 0.392184 7.7168 0.258153 7.82481C0.123694 7.93293 0.0627231 8.10895 0.101845 8.27725L4.17406 25.7961C4.23028 26.038 4.47175 26.1886 4.71384 26.1323L37.562 18.4968C37.8041 18.4405 37.9544 18.1989 37.8982 17.957L33.826 0.43817C33.7869 0.269868 33.6545 0.13879 33.4861 0.101061ZM12.5014 16.9359C12.8663 18.5056 11.886 20.0796 10.3163 20.4445C8.74663 20.8093 7.17262 19.8291 6.80775 18.2594C6.44288 16.6897 7.42316 15.1157 8.99286 14.7508C10.5626 14.386 12.1366 15.3662 12.5014 16.9359ZM16.6622 15.9687C16.2973 14.3991 17.2776 12.8251 18.8473 12.4602C20.417 12.0953 21.991 13.0756 22.3559 14.6453C22.7208 16.2149 21.7405 17.789 20.1708 18.1538C18.6011 18.5187 17.0271 17.5384 16.6622 15.9687ZM30.0252 15.8632C28.4555 16.2281 26.8815 15.2478 26.5167 13.6781C26.1518 12.1084 27.1321 10.5344 28.7018 10.1695C30.2715 9.80468 31.8455 10.7849 32.2103 12.3546C32.5752 13.9243 31.5949 15.4983 30.0252 15.8632Z"
        fill="#FFC83A"
      />
    </svg>
  );
};
