import type { Organization } from "../cms.types";
import type { Product } from "api/cms/types";

import { useEffect, useState } from "react";
import { getCustomerProductCount, getCustomerProducts } from "api/cms/customer_products";
import { useLogin } from "useLogin";
import { HomeButton } from "components/HomeButton";
import { TableContainer } from "../cms.styled";
import { camelCaseToTitleCase } from "stringFormatters";
import { useNavigate } from "react-router-dom";
import { OrganizationSelect } from "./product.styled";
import { ProductsExport } from "./ProductsExport";
import { getOrganizationsProductScope } from "api/organizations";
import PaginatedTable from "components/PaginatedTable";

export const ProductOverview = () => {
  const credential = useLogin();
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [organizationSelected, setOrganizationSelected] = useState<string>();
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const itemsPerPage = 50;

  useEffect(() => {
    if (!organizationSelected) {
      return;
    }
    const main = async () => {
      try {
        const response = await getCustomerProductCount(credential, organizationSelected);
        setTotalProducts(response.count);
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [credential, organizationSelected]);

  useEffect(() => {
    const organizations = sessionStorage.getItem("organizations");
    if (organizations) {
      setOrganizations(JSON.parse(organizations));
      return;
    }

    const main = async () => {
      try {
        const response = await getOrganizationsProductScope(credential);
        setOrganizations(response);
        sessionStorage.setItem("organizations", JSON.stringify(response));
      } catch (error) {
        console.error(error);
      }
    };
    main();
  }, [credential]);

  const handleNavigate = (product: Record<any, any>) => {
    return () => {
      navigate(`/cms/product`, { state: { product } });
    };
  };

  const fetchCustomerProducts = async (organizationID: string, page: number) => {
    const offset = page * itemsPerPage;
    try {
      const response = await getCustomerProducts(credential, organizationID, offset, itemsPerPage);
      for (const product of response) {
        product.taxonomyName = product.taxonomy.fullName;
      }
      setProducts(response);
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return (
    <TableContainer>
      <HomeButton text="CMS Home" navigateTo="/cms" />

      <OrganizationSelect
        required
        placeholder="Filter by Organizations"
        onChange={(e: any) => setOrganizationSelected(e?.value)}
        options={organizations.map((org) => ({ label: org.Name, value: org.OrganizationId }))}
      />
      <p>
        This overview shows the customer products for an specific organization. Select one item to see the product
        details
      </p>

      {organizationSelected && (
        <PaginatedTable
          total={totalProducts}
          pageSize={itemsPerPage}
          data={products}
          getData={(page) => fetchCustomerProducts(organizationSelected, page)}
          dataContentName="products"
          omitColumns={[
            "organizationID",
            "approvedAt",
            "materialComposition",
            "customerMaterialComposition",
            "validatedProduct",
            "taxonomy",
            "category",
            "importDate",
          ]}
          onBodyClick={handleNavigate}
          filterColumn="name"
          headerFormatter={camelCaseToTitleCase}
          emptyMessage={"No products for this organization yet"}
        />
      )}
      {products.length > 0 && <ProductsExport data={products} />}
    </TableContainer>
  );
};
