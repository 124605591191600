import type { FC } from "react";
import type { Organization, Users, CreateUserRequest, Retirement, CreateRetirementRequest } from "api/types";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/Button";
import { CardDivider } from "components/Card";
import { Modal } from "components/Modal";
import { Response } from "components/Response";
import { formatDateTime } from "stringFormatters";
import { OrganizationUsers } from "./OrganizationUsers";
import { getOrganizationUsers, deleteOrganizationUser, addOrganizationUser } from "api/organizations";
import { NotAuthorizedError } from "api/errors";
import { OrganizationRetirements } from "./OrganizationRetirements";
import { useLogin } from "useLogin";

import {
  ModalItemContainer,
  ModalItemLabel,
  ModalItemText,
  ModalHeader,
  ModalItemsContainer,
} from "./organizations.styled";
import { ImportDetails, ImportsList } from "../details/import";
import { Drawer } from "baseui/drawer";
import { addOrganizationRetirement, getOrganizationRetirements } from "api/retirements";

interface OrganizationDetailProps {
  closeModal: () => void;
  organization: Organization;
}

const ModalItem: FC<{ text: string; label: string }> = ({ text, label }) => {
  return (
    <ModalItemContainer>
      <ModalItemLabel>{label}: </ModalItemLabel>
      <ModalItemText>{text}</ModalItemText>
    </ModalItemContainer>
  );
};

export const OrganizationDetailsModal: FC<OrganizationDetailProps> = ({ closeModal, organization }) => {
  const navigate = useNavigate();
  const credential = useLogin();
  const [users, setUsers] = useState<Users>();
  const [retirements, setRetirements] = useState<Retirement[]>([]);

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [importId, setImportId] = useState<string>("");

  const navigateToInvoice = (organizationId: string) => {
    navigate(`/invoicing?organizationId=${organizationId}`);
  };

  const openDrawer = (id: string) => () => {
    setImportId(id);
    setDrawerState(true);
  };

  useEffect(() => {
    (async () => {
      const users = await getOrganizationUsers(credential, organization.OrganizationId);
      setUsers(users);
    })();
  }, [credential, organization]);

  useEffect(() => {
    (async () => {
      const retirements = await getOrganizationRetirements(credential, organization.OrganizationId);
      if (!retirements) {
        return;
      }
      setRetirements(retirements);
    })();
  }, [credential, organization]);

  const removeUser = async (orgID: string, userID: string) => {
    try {
      await deleteOrganizationUser(credential, orgID, userID);
      const usrs = await getOrganizationUsers(credential, orgID);
      setUsers(usrs);
    } catch (e) {
      if (e instanceof NotAuthorizedError) {
        alert("You're not authorized to do this");
        throw e;
      }
      throw e;
    }
  };

  const addUser = async (orgID: string, data: CreateUserRequest) => {
    await addOrganizationUser(credential, orgID, data);
    const usrs = await getOrganizationUsers(credential, orgID);
    setUsers(usrs);
  };

  const addRetirement = async (orgID: string, data: CreateRetirementRequest) => {
    await addOrganizationRetirement(credential, orgID, data);
  };

  return (
    <Modal onClose={closeModal} closeable isOpen={true} animate autoFocus size={"responsive"} role={"dialog"}>
      <ModalHeader>Organization Details</ModalHeader>
      <ModalItemsContainer>
        <ModalItem label="Name" text={organization.Name} />
        <ModalItem label="Email" text={organization.Email} />
        <ModalItem label="Website" text={organization.Website} />
        <ModalItem label="Compensation Strategy" text={organization.CompensationStrategy} />
        <ModalItem label="Enabled" text={organization.IsEnabled.toString()} />
        <ModalItem label="Test" text={organization.IsTest.toString()} />
        <ModalItem label="Platform" text={organization.Platform} />
        <ModalItem label="OrganizationID" text={organization.OrganizationId} />
        <ModalItem label="Creation date" text={formatDateTime(organization.CreatedAt)} />
        <CardDivider style={{ marginBottom: "10px" }} />
        <ModalItem label="Scopes" text={organization.Scopes.map((scope) => scope.name).join(", ")} />
        {users && (
          <div>
            <CardDivider style={{ marginBottom: "10px" }} />
            <OrganizationUsers
              orgID={organization.OrganizationId}
              users={users}
              removeUser={removeUser}
              addUser={addUser}
            />
          </div>
        )}
        <CardDivider style={{ marginBottom: "10px" }} />
        <OrganizationRetirements
          orgID={organization.OrganizationId}
          allRetirements={retirements}
          addRetirement={addRetirement}
        />
        <CardDivider style={{ marginBottom: "15px" }} />
        {organization.Scopes && organization.Scopes.some((scope) => scope.name === "PRODUCT_IMPORT_V2") && (
          <div>
            <h3>Imports</h3>
            <ImportsList organizationId={organization.OrganizationId} openDrawer={openDrawer} />
          </div>
        )}
        <Response response={organization.ApiKey} title={"API Key"} />
        <Button $type="primary" $size="small" onClick={() => navigateToInvoice(organization.OrganizationId)}>
          Invoice
        </Button>
      </ModalItemsContainer>

      <Drawer onClose={() => setDrawerState(false)} isOpen={drawerState} size={"full"}>
        <ImportDetails
          importId={importId}
          organizationId={organization.OrganizationId}
          isOpen={drawerState}
          onClose={() => {
            setDrawerState(false);
          }}
        ></ImportDetails>
      </Drawer>
    </Modal>
  );
};
