import { useRef } from "react";
import { styled } from "styletron-react";
import { CopyButton } from "./CopyButton";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  justifyContent: "flex-end",
  gap: "10px",
  maxWidth: "max-content",
  paddingBottom: "20px",
});

const ResponseHeader = styled("h3", {
  fontSize: "16px",
  fontWeight: 600,
  margin: "0 0 3px 0",
  padding: "0",
});

const ResponseTextArea = styled("textarea", {
  resize: "none",
  fontSize: "14px",
  width: "100%",
});

export const Response = ({ title, response }: Record<string, string>) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const copyToClipboard = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (textAreaRef.current) {
      try {
        await navigator.clipboard.writeText(textAreaRef.current.value);
      } catch {
        // do nothing
      }
    }
  };

  return (
    <Container>
      <ResponseHeader>{title}</ResponseHeader>
      <div style={{ display: "flex", gap: "10px" }}>
        <ResponseTextArea
          ref={textAreaRef}
          defaultValue={response}
          disabled={true}
          cols={31}
          rows={1}
        ></ResponseTextArea>
        <CopyButton onClick={copyToClipboard}></CopyButton>
      </div>
    </Container>
  );
};
