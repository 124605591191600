import type { RegisterOrganizationRequest } from "api/types";

import { FieldError, FieldErrors } from "react-hook-form";

const errorMessages: Record<string, string> = {
  required: "Is required",
  uri: "Must be a valid URL",
  email: "Must be a valid email address",
  min: "Must be at least 2 characters",
  atLeastOne: "At least one scope is required",
};

export const validateFields = (formData: RegisterOrganizationRequest): [boolean, FieldErrors] => {
  const errors: FieldErrors<RegisterOrganizationRequest> = {};
  let hasErrors = false;
  if (formData.Name.length < 2) {
    errors.Name = {
      type: "min",
      message: errorMessages.min,
    };
    hasErrors = true;
  }
  if (!formData.Website.startsWith("http")) {
    errors.Website = {
      type: "uri",
      message: errorMessages.uri,
    };
    hasErrors = true;
  }
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.Email)) {
    errors.Email = {
      type: "email",
      message: errorMessages.email,
    };
    hasErrors = true;
  }
  if (formData.Scopes.length === 0) {
    errors.Scopes = {
      type: "atLeastOne",
      message: errorMessages.atLeastOne,
    };
    hasErrors = true;
  }
  return [hasErrors, errors];
};

export const parseErrors = (errorMessage: string): FieldErrors<RegisterOrganizationRequest> => {
  const errors = errorMessage.split("Key: ").slice(1);
  const fieldErrors: FieldErrors<RegisterOrganizationRequest> = {};
  errors.forEach((error) => {
    const fieldMatch = error.match(/Field validation for '(.+?)' failed/);
    const tagMatch = error.match(/on the '(.+?)' tag/);

    if (fieldMatch && tagMatch) {
      const fieldName = fieldMatch[1] as keyof RegisterOrganizationRequest;
      const type = tagMatch[1] as keyof RegisterOrganizationRequest;
      const error: FieldError = {
        type: type,
        message: errorMessages[type] || `Invalid: ${type}`,
      };
      fieldErrors[fieldName] = error;
    }
  });
  return fieldErrors;
};
