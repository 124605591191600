import type { CoverageResponse, Coverage, CoverageLevel } from "./types";

import { makeCopilotRequest } from "./common";

const getStats = async (credentials: string, statName: string): Promise<Coverage[] | CoverageLevel[]> => {
  const response = await makeCopilotRequest(statName, "GET", credentials);
  const data = response as CoverageResponse;
  data.coverage.forEach(function (value: Coverage | CoverageLevel) {
    value.Percentage = Number(value.Percentage.toFixed(2));
  });
  return data.coverage;
};

export const getMainCategoryStats = async (credentials: string): Promise<Coverage[]> => {
  return (await getStats(credentials, "GetCoverageOfMainCategories")) as Coverage[];
};

export const getCategoryLevelStats = async (credentials: string, level: number): Promise<CoverageLevel[]> => {
  return (await getStats(credentials, `GetCoverageByLevels?maxLevel=${level}`)) as CoverageLevel[];
};

export const getCategoryWithEFStats = async (credentials: string): Promise<Coverage[]> => {
  return (await getStats(credentials, "GetCoverageOfCategoriesWithEmissionFactor")) as Coverage[];
};

export const getCategoryProductStats = async (credentials: string): Promise<Coverage[]> => {
  return (await getStats(credentials, "GetCoverageOfAllCategories")) as Coverage[];
};
