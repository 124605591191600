import { useState, useMemo, FC } from "react";
import { styled, StyleObject } from "styletron-react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import EmptyTable from "./EmptyTable";

const formatCell = (_key: string, value: any, _row: Row) => {
  const valueString = String(value);
  return valueString;
};

export type Row = Record<string, any>;

const DefaultCell = styled("div", {
  ":hover": {
    cursor: "pointer",
  },
});

const TableHeader = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const FilterContainer = styled("div", {
  display: "flex",
  maxWidth: "500px",
  width: "30%",
  margin: "0px 0px 20px 0px",
});

const FilterInput = styled("input", {
  borderRadius: "5px",
  margin: "0px 0px 0px 10px",
  padding: "5px",
  fontSize: "14px",
  flex: "1",
  border: "1px solid #ccc",
  height: `32px`,
});

const TableContainer = styled("div", {
  width: "90vw",
  overflowX: "auto",
});

const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={24} height={32} viewBox="0 0 16 16">
      <title>Filter category</title>
      <path d="M 10.5 1 C 8.019531 1 6 3.019531 6 5.5 C 6 6.558594 6.382813 7.523438 7 8.292969 L 2.023438 13.269531 L 2.726563 13.980469 L 7.707031 9 C 8.476563 9.617188 9.441406 10 10.5 10 C 12.980469 10 15 7.980469 15 5.5 C 15 3.019531 12.980469 1 10.5 1 Z M 10.5 2 C 12.4375 2 14 3.5625 14 5.5 C 14 7.4375 12.4375 9 10.5 9 C 8.5625 9 7 7.4375 7 5.5 C 7 3.5625 8.5625 2 10.5 2 Z"></path>
    </svg>
  );
};

export interface TableProps {
  data: Row[]; //Data to display in the table
  emptyMessage?: string; //Text to display when data is empty
  omitColumns?: string[]; //Columns to omit from the table
  cellStyler?: (key: string, value: any) => StyleObject; //Function to add styles to a cell
  cellFormatter?: (key: string, value: any, record: Record<string, any>) => string | React.ReactNode; //Function to format a cell's text
  headerFormatter?: (header: string) => string; //Function to format a header's text
  onBodyClick?: (record: Record<string, any>) => () => void; //Function to handle a click on a row
  filterColumn?: string; //Column to filter on
  displayItemCount?: boolean; //Whether to display the number of items in the table
}

const Table: FC<TableProps> = ({
  data,
  emptyMessage = "",
  omitColumns,
  cellStyler = (_, __) => ({}),
  cellFormatter = formatCell,
  headerFormatter = (s) => s,
  onBodyClick = (_) => () => {
    // Do nothing
  },
  filterColumn,
  displayItemCount = false,
}) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortAsc, setSortAsc] = useState(true);
  const [filter, setFilter] = useState("");

  const filteredData = useMemo(() => {
    if (!filterColumn || filter.length < 5) {
      return data;
    }

    return data.filter((row) => {
      const rowVal = row[filterColumn as keyof Row] as string;
      return rowVal.toLowerCase().includes(filter.toLowerCase());
    });
  }, [filterColumn, filter, data]);

  const sortedData = useMemo(() => {
    return filteredData.slice().sort((a, b) => {
      const left = sortAsc ? a : b;
      const right = sortAsc ? b : a;
      const leftValue = String(left[sortColumn as keyof Row]);
      const rightValue = String(right[sortColumn as keyof Row]);
      return leftValue.localeCompare(rightValue, "en", {
        numeric: true,
        sensitivity: "base",
      });
    });
  }, [sortColumn, sortAsc, filteredData]);

  const handleSort = (id: string) => {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  if (data.length === 0) {
    return <EmptyTable message={emptyMessage} />;
  }

  return (
    <TableContainer>
      <TableHeader>
        {filterColumn && (
          <FilterContainer>
            <FilterIcon />
            <FilterInput type="text" onChange={handleFilter} />
          </FilterContainer>
        )}
        {displayItemCount && <div>Number of items: {sortedData.length}</div>}
      </TableHeader>
      <TableBuilder
        overrides={{
          Root: {
            style: {
              borderBottomRightRadius: "5px",
              borderBottomLeftRadius: "5px",
            },
          },
        }}
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? "ASC" : "DESC"}
        onSort={handleSort}
      >
        {Object.entries(data[0])
          .filter(([key, _]) => !omitColumns?.includes(key))
          .map(([key, _]) => (
            <TableBuilderColumn key={key} id={key} header={headerFormatter(key)} sortable>
              {(row: Row) => {
                return (
                  <DefaultCell $style={cellStyler(key, row[key])} onClick={onBodyClick(row)}>
                    {cellFormatter(key, row[key], row)}
                  </DefaultCell>
                );
              }}
            </TableBuilderColumn>
          ))}
      </TableBuilder>
    </TableContainer>
  );
};

export default Table;
