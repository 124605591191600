import { LightTheme } from "baseui";

const primaryBlue = "#2648F5";
const lighterBlue = "#657EFF";
const darkerBlue = "#00328a";
const darkestBlue = "#001649";

const textPrimary = "#6F6F6F";

const borderPrimary = "#E2E2E2";

const secondary = "#DEDEDE";

const primaryFontFamily = "DM Sans";

const customizedTheme = {
  ...LightTheme,
  typography: {
    ...LightTheme.typography,
    primaryFontFamily,
  },
  colors: {
    ...LightTheme.colors,
    contentPrimary: textPrimary,
    primaryA: lighterBlue,
    buttonPrimaryFill: primaryBlue,
    buttonPrimaryHover: darkerBlue,
    buttonPrimaryActive: darkestBlue,
    borderSelected: primaryBlue,
    borderOpaque: secondary,
    inputBorder: borderPrimary,
    inputFill: "#FAFAFA",
  },
  borders: {
    ...LightTheme.borders,
    inputBorderRadius: "4px",
  },
  grid: {
    ...LightTheme.grid,
    margins: [0, 0, 0],
  },
};

export const ClimateClickTheme = customizedTheme;
