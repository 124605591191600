import type { FC } from "react";
import type { ImportStatus, DemoImportProduct, GetImportResponse } from "api/types";
import type { ImportDetailsProps } from "./importTypes";

import { useState, useEffect } from "react";
import { Drawer } from "baseui/drawer";

import { useLogin } from "useLogin";
import Table from "components/Table";

import { snakeCaseToTitleCase, formatDateTime, camelCaseToTitleCase } from "stringFormatters";

import { ModalBody, ModalFooter } from "components/Modal";
import { DownloadCSV } from "components/DownloadCSV";
import { getImport } from "api/imports";

export const ImportDetails: FC<ImportDetailsProps> = ({ importId, isOpen, onClose }) => {
  const credentials = useLogin();
  const [products, setProducts] = useState<DemoImportProduct[]>([]);
  const [productImport, setProductImport] = useState<GetImportResponse>();
  const [status, setStatus] = useState<ImportStatus>();

  useEffect(() => {
    const fetchImport = async () => {
      try {
        const data = await getImport(credentials, importId);

        if (data.version === 2) {
          setProducts(data.products);
        } else {
          console.error(`Unsupported import version: ${data.version}`);
          return;
        }

        setStatus(data.importStatus);
        setProductImport(data);
      } catch (error: any) {
        console.error(error);
      }
    };
    fetchImport();
  }, [importId]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
      <ModalBody>
        {status === "success" && (
          <div>
            <Table data={products} headerFormatter={camelCaseToTitleCase} />
            <DownloadCSV data={products} fileName="import" />
          </div>
        )}
        {productImport !== undefined && (
          <ModalFooter>
            <div>
              <b>Import Status:</b> {snakeCaseToTitleCase(productImport.importStatus)}
            </div>
            <div>
              <b>Imported At:</b> {formatDateTime(productImport.createdAt)}
            </div>
          </ModalFooter>
        )}
      </ModalBody>
    </Drawer>
  );
};
