import type { GameRound, TaxonomyChangeReport } from "api/types";

import { Typography } from "antd";
import { useStyletron } from "styletron-react";
import { Card, CardContents, CardDivider } from "./components/Card";
import { getTaxonomyReport } from "api/penguinInTheLoop";
import { useLogin } from "./useLogin";
import { getGameRounds } from "./api/penguinInTheLoop";
import { useEffect, useState } from "react";
import { Select } from "baseui/select";
import React from "react";
import { HomeButton } from "./components/HomeButton";
import { Tabs } from "./components/Tabs";
import CsvDownloadButton from "react-json-to-csv";
import { Table } from "baseui/table-semantic";

const COLUMNS = ["Product ID", "Product name", "Taxonomy Before", "Taxonomy After", "Secondary Taxonomy"];
const COLUMNS_CHILD = ["Category", "Score"];

export const Report = () => {
  const [css] = useStyletron();
  const credential = useLogin();
  const [gameRounds, setGameRounds] = useState<GameRound[]>();
  const [currentGameRound, setCurrentGameRound] = useState<GameRound | undefined>(undefined);
  const [report, setReport] = useState<(string | number | React.ReactNode)[][]>();
  const newData: (string | number | React.ReactNode)[][] = [];

  const [jsonData, setjsonData] = useState<TaxonomyChangeReport[]>([]);

  useEffect(() => {
    const main = async () => {
      const rounds = await getGameRounds(credential);
      setGameRounds(rounds);
    };
    main();
  }, [credential]);

  useEffect(() => {
    if (gameRounds) {
      setCurrentGameRound(gameRounds[gameRounds.length - 1]);
    }
  }, [gameRounds]);

  useEffect(() => {
    if (!currentGameRound) {
      return;
    }

    const main = async () => {
      try {
        const results = await getTaxonomyReport(credential, currentGameRound.id);
        results.forEach((element: TaxonomyChangeReport) => {
          newData.push([
            element.ProductID,
            element.ProductName,
            element.TaxonomyBefore,
            element.TaxonomyAfter,
            <Table key={1} columns={COLUMNS_CHILD} data={formatSecondaryTaxonomy(element.SecondaryTaxonomy)} />,
          ]);
        });
        setjsonData(results);
        setReport(newData);
      } catch (e) {
        console.error(e);
      }
    };
    main();
  }, [currentGameRound, credential]);

  return (
    <Card
      className={css({
        maxWidth: "max-content !important",
      })}
    >
      <HomeButton />
      <Tabs />
      <CardDivider />
      <CardContents>
        <Select
          options={gameRounds
            ?.map((round) => ({
              id: round.id,
              label: `${round.name} | ${round.start.slice(0, 10)} - ${round.end.slice(0, 10)}`,
            }))
            .concat([{ id: "default", label: "Default" }])}
          placeholder="Select game round"
          value={
            currentGameRound
              ? [{ id: currentGameRound.id, label: currentGameRound.name }]
              : [{ id: "Default", label: "Default" }]
          }
          onChange={(params) => {
            setCurrentGameRound(gameRounds?.find((round) => round.id === params.value[0].id));
          }}
        />
        <CsvDownloadButton
          data={jsonData}
          filename="taxonomy_report"
          style={{
            background: "#015597",
            backgroundColor: "#015597",
            display: "inline-block",
            cursor: "pointer",
            color: "#ffffff",
            fontSize: "15px",
            fontWeight: "bold",
            padding: "10px 10px",
            marginTop: 10,
            textDecoration: "none",
          }}
        />
        <Typography.Title level={3}>Taxonomy report for round {currentGameRound?.name}</Typography.Title>

        <Table columns={COLUMNS} data={report} />
      </CardContents>
    </Card>
  );
};

function formatSecondaryTaxonomy(secondaryTaxonomy: any) {
  const parts: (string | number | React.ReactNode)[][] = [];

  for (const key in secondaryTaxonomy) {
    const value = secondaryTaxonomy[key];
    parts.push([key, value]);
  }
  return parts;
}
