import { styled } from "styletron-react";

export const CMSHomeContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px",
});

export const TableContainer = styled("div", {
  width: "100%",
  height: "100vh",
  paddingTop: "5vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
});

export const DetailsContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "5px",
  minWidth: "400px",
  maxWidth: "max-content",
  padding: "0 25px 25px 25px",
});

export const ErrorField = styled("div", {
  width: "100%",
  color: "red",
});

export const DetailField = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "50px",
});

export const DetailLabel = styled("div", {
  fontWeight: "bold",
  width: "180px",
});

export const DetailValue = styled("div", {
  textAlign: "left",
});

export const DetailValueLink = styled(DetailValue, {
  color: "blue",
  cursor: "pointer",
});

export const DetailValueList = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
});

export const CurrentValueLabel = styled("div", {
  textAlign: "left",
  color: "#408f60",
  fontWeight: "525",
});

export const SuggestedValue = styled("input", {
  flex: "1",
  borderRadius: "5px",
  maxWidth: "75px",
  border: "1px solid #ccc",
});

export const SuggestedValueLabel = styled("div", {
  textAlign: "left",
  color: "#d9600b",
  fontWeight: "525",
});

export const EditableDetailContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  textAlign: "left",
  gap: "5px",
});

export const Separator = styled("div", {
  borderLeft: "3px solid #ccc",
  left: "50%",
});

export const DetailButton = styled("button", {
  borderStyle: `none`,
  borderRadius: `3px`,
  background: `#015597`,
  transition: `background 0.2s ease-in-out`,
  marginLeft: `5px`,
  color: `#FFF`,
  ":hover": {
    background: `#002B4D`,
  },
  ":active": {
    transform: `scale(0.99)`,
  },
});
