import type { Category as CategoryResponse } from "api/cms/types";
import type { Category } from "../cms.types";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLogin } from "useLogin";
import { getCategory } from "api/cms/categories";

import { Card } from "components/Card";
import { DetailsContainer, DetailField, DetailLabel, DetailValue, DetailValueLink } from "../cms.styled";
import { HomeButton } from "components/HomeButton";
import { camelCaseToTitleCase } from "stringFormatters";
import { nullToNotAvailable } from "../cms.utils";

const mapCategoryData = (response: CategoryResponse): Category => ({
  id: response.id,
  name: response.name,
  taxonomyName: response.taxonomy.name,
  taxonomyID: response.taxonomy.id,
  emissionFactor: response.emissionFactor,
  source: response.source,
  sourceID: response.sourceID,
  countryCode: response.countryCode,
  region: response.region,
});

export const CategoryDetails = () => {
  const credential = useLogin();
  const { categoryID } = useParams() as { categoryID: string };
  const [category, setCategory] = useState<Category>();
  const navigate = useNavigate();

  const getValueElement = (key: string, value: any) => {
    if (key === "TaxonomyID") {
      if (value === 0) {
        return <DetailValue>None</DetailValue>;
      }
      return (
        <DetailValueLink
          onClick={() => {
            navigate(`/cms/taxonomies/${value}`);
          }}
        >
          {value}
        </DetailValueLink>
      );
    }
    return <DetailValue>{nullToNotAvailable(value)}</DetailValue>;
  };

  useEffect(() => {
    const main = async () => {
      try {
        const response = await getCategory(credential, categoryID);
        setCategory(mapCategoryData(response));
      } catch (error) {
        console.error(error);
      }
    };
    main();
  }, [credential, categoryID]);

  return (
    <Card>
      <HomeButton text="Categories Home" navigateTo="/cms/categories" />
      <DetailsContainer>
        <h1>Category Details</h1>
        {category &&
          Object.entries(category).map(([label, value]) => {
            return (
              <DetailField key={label}>
                <DetailLabel>{camelCaseToTitleCase(label)}:</DetailLabel>
                {getValueElement(label, value)}
              </DetailField>
            );
          })}
      </DetailsContainer>
    </Card>
  );
};
