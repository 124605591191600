import { styled } from "styletron-react";

export const OrganizationsTopBar = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  padding: "10px",
  borderBottom: "1px solid #ddd",
  borderTopRightRadius: "15px",
  borderTopLeftRadius: "15px",
  backgroundColor: "#f8f8f8",
});

export const InputError = styled(`p`, {
  color: `#f00`,
  position: `absolute`,
  marginTop: `40px`,
  fontWeight: 500,
  fontSize: `12px`,
});
