import type { FC } from "react";
import { ImportTutorialList } from "./imports.styled";

export const ImportTutorial: FC = () => {
  return (
    <ImportTutorialList>
      <li>
        Navigate to the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.google.com/spreadsheets/d/1cLmfXcv0TYvkP7wCb1eRcGf3oCwk8v-NABVV8jR6JC4/edit"
        >
          Google Sheet template
        </a>
      </li>
      <li>Make a copy of the sheet</li>
      <li>Fill in the sheet with the products. There is already one example product to demonstrate the input format</li>
      <li>Download the sheet as a CSV file</li>
      <li>Upload it below</li>
    </ImportTutorialList>
  );
};
