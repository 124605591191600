import type { FC } from "react";
import type { Organization } from "api/types";

import { useEffect, useState } from "react";

import Table from "components/Table";
import { Card, CardDivider, CardFooter } from "components/Card";
import { HomeButton } from "components/HomeButton";
import { camelCaseToTitleCase } from "stringFormatters";
import { Tabs } from "components/Tabs";
import { OrganizationDetailsModal } from "./OrganizationDetails";
import { Select, Input, Pagination } from "antd";
import { getOrganizations } from "api/organizations";
import { useLogin } from "useLogin";

import { OrganizationsTopBar } from "./organizations.styled";

export const AllOrganizations: FC = () => {
  const defaultPageSize = 10;
  const credential = useLogin();
  const [organizations, setOrganizations] = useState<Organization[] | undefined>([]);
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);
  const [nameFilter, setNameFilter] = useState("");
  const [isEnabledFilter, setIsEnabledFilter] = useState<boolean | undefined>(undefined);
  const [isTestFilter, setIsTestFilter] = useState<boolean | undefined>(undefined);
  const [compensationStrategyFilter, setCompensationStrategyFilter] = useState<string | undefined>(undefined);
  const [platformFilter, setPlatformFilter] = useState<string | undefined>(undefined);
  const [orgDetail, setOrgDetail] = useState<Organization | undefined>(undefined);
  const [pageItems, setPageItems] = useState<Organization[]>([]);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [page, setPage] = useState(1);
  const [allPlatforms, setAllPlatforms] = useState<string[]>([]);

  const openModal = (record: Record<string, any>) => () => {
    const orgDetail = organizations?.find((org) => org.OrganizationId === record.OrganizationId);
    setOrgDetail(orgDetail);
  };

  const closeModal = () => {
    setOrgDetail(undefined);
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getOrganizations(credential);
        setOrganizations(data || []);
        setOrganizationList(data || []);
        const platforms = Array.from(new Set<string>(data?.map((org: Organization) => org.Platform)));
        setAllPlatforms(platforms);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [credential]);

  useEffect(() => {
    let filteredList = organizations || [];
    if (isEnabledFilter !== undefined) {
      filteredList = filteredList.filter((org) => org.IsEnabled === isEnabledFilter);
    }
    if (isTestFilter !== undefined) {
      filteredList = filteredList.filter((org) => org.IsTest === isTestFilter);
    }
    if (compensationStrategyFilter !== undefined) {
      filteredList = filteredList.filter((org) => org.CompensationStrategy === compensationStrategyFilter);
    }
    if (platformFilter !== undefined) {
      filteredList = filteredList.filter((org) => org.Platform === platformFilter);
    }
    if (nameFilter.length >= 3) {
      filteredList = filteredList.filter((org) => org.Name.toLowerCase().includes(nameFilter.toLowerCase()));
    }
    setOrganizationList(filteredList);
  }, [nameFilter, isEnabledFilter, isTestFilter, compensationStrategyFilter, platformFilter, organizations]);

  useEffect(() => {
    setPageItems(organizationList.slice(0 + (page - 1) * pageSize, pageSize * page));
  }, [page, pageSize, organizationList]);

  const BooleanFilterOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];

  const CompensationStrategyFilterOptions = [
    {
      label: "CUSTOMER_PAYING",
      value: "CUSTOMER_PAYING",
    },
    {
      label: "MERCHANT_PAYING",
      value: "MERCHANT_PAYING",
    },
  ];

  return (
    <Card>
      <HomeButton />
      <Tabs />
      <OrganizationsTopBar>
        <div>
          <Input allowClear placeholder="Filter by name" onChange={(e) => setNameFilter(e.target.value)} />
        </div>
        <div>
          <Select
            allowClear
            placeholder="Filter by Platform"
            onChange={(e) => setPlatformFilter(e)}
            options={allPlatforms.map((platform) => ({
              label: platform,
              value: platform,
            }))}
          />
        </div>
        <div>
          <Select
            allowClear
            placeholder="Filter Enabled"
            onChange={(e) => setIsEnabledFilter(e)}
            options={BooleanFilterOptions}
          />
        </div>
        <div>
          <Select
            allowClear
            placeholder="Filter Test"
            onChange={(e) => setIsTestFilter(e)}
            options={BooleanFilterOptions}
          />
        </div>
        <div>
          <Select
            allowClear
            placeholder="Filter Compensation Strategy"
            onChange={(e) => setCompensationStrategyFilter(e)}
            options={CompensationStrategyFilterOptions}
          />
        </div>
      </OrganizationsTopBar>
      {orgDetail && <OrganizationDetailsModal closeModal={closeModal} organization={orgDetail} />}
      <Table
        data={pageItems}
        emptyMessage="No organizations found"
        omitColumns={["OrganizationId", "Email", "Website", "ApiKey", "CreatedAt", "Scopes"]}
        headerFormatter={camelCaseToTitleCase}
        onBodyClick={openModal}
      />
      <CardDivider />
      <CardFooter>
        <Pagination
          total={organizationList.length}
          showSizeChanger
          pageSize={pageSize}
          showTotal={(total) => `Showing ${pageItems.length} out of ${total} items`}
          onChange={(currentPage, currentPageSize) => {
            setPage(currentPage);
            setPageSize(currentPageSize || defaultPageSize);
          }}
          current={page}
          pageSizeOptions={[5, 10, 20, 50, 100]}
        />
      </CardFooter>
    </Card>
  );
};
