import React, { ReactNode } from "react";
import { styled } from "styletron-react";

const FormItemContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100% ",
  padding: "10px",
  ":nth-child(even)": {
    backgroundColor: "#E3E3E3",
  },
  ":nth-child(odd)": {
    backgroundColor: "#f6f6f6",
  },
});

const FormItemTitle = styled("h3", {
  fontSize: "14px",
  fontWeight: 600,
  paddingLeft: "10px",
});

const LabelDescription = styled("p", {
  color: `#8B8B8B`,
  fontSize: `14px`,
  lineHeight: `20px`,
  fontWeight: 400,
  margin: `0`,
  paddingLeft: `10px`,
});

const InputContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  gap: "10px",
  margin: "10px 0",
  width: "60%",
});

interface FormItemProps {
  title: string;
  description?: string;
  children: ReactNode;
}

export const FormItem: React.FC<FormItemProps> = ({ title, description, children }) => {
  return (
    <FormItemContainer>
      <div
        style={{
          width: "30%",
        }}
      >
        <FormItemTitle>{title}:</FormItemTitle>
        <LabelDescription>{description}</LabelDescription>
      </div>
      <InputContainer>{children}</InputContainer>
    </FormItemContainer>
  );
};
