import { forwardRef, InputHTMLAttributes, useState } from "react";
import { styled } from "styletron-react";
import { Tooltip } from "./Tooltip";

const CheckBoxLabel = styled(`label`, {
  position: `relative`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `space-around`,
  gap: `10px`,
  fontSize: `13px`,
});

const CheckboxInput = styled(`input`, {
  accentColor: `#015597`,
});

const CheckboxTooltip = styled(`div`, {
  position: `relative`,
});

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  description: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <CheckBoxLabel>
      {props.title}
      <CheckboxInput {...props} type="checkbox" ref={ref} />
      <CheckboxTooltip>
        <Tooltip $visible={showTooltip}>{props.description}</Tooltip>
        <a
          target="_blank"
          rel="noreferer"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          [?]
        </a>
      </CheckboxTooltip>
    </CheckBoxLabel>
  );
});

Checkbox.displayName = "Checkbox";
