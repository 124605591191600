import type { FC } from "react";
import type { ImportStatus, ImportReference } from "api/types";
import type { ImportsListProps } from "./importTypes";

import { useEffect, useState } from "react";
import { NewImport } from "./NewImport";
import { useLogin } from "useLogin";
import { formatDateTime, snakeCaseToTitleCase } from "stringFormatters";

import { Imports, NewImportButton, ImportListItem } from "./imports.styled";
import { SingleImportModal } from "./SingleProductImportForm";
import { getImports } from "api/imports";

export const ImportsList: FC<ImportsListProps> = ({ organizationId, openDrawer }) => {
  const credentials = useLogin();
  const [imports, setImports] = useState<ImportReference[]>([]);
  const [newImportModalOpen, setNewImportModalOpen] = useState<boolean>(false);
  const [newSingleImportOpen, setNewSingleImportOpen] = useState<boolean>(false);

  const fetchImports = async () => {
    try {
      const data = await getImports(credentials, organizationId);
      setImports(data.imports.sort((a: ImportReference, b: ImportReference) => (a.createdAt < b.createdAt ? 1 : -1)));
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchImports();
  }, []);

  return (
    <Imports>
      {newImportModalOpen && (
        <NewImport organizationId={organizationId} fetchImports={fetchImports} setModalState={setNewImportModalOpen} />
      )}
      {newSingleImportOpen && (
        <SingleImportModal
          organizationId={organizationId}
          fetchImports={fetchImports}
          setModalState={setNewSingleImportOpen}
        />
      )}
      <NewImportButton onClick={() => setNewImportModalOpen(true)}>+ New import</NewImportButton>
      <NewImportButton onClick={() => setNewSingleImportOpen(true)}>+ Import single product</NewImportButton>
      {imports.map((importReference, i) => (
        <ImportListItem
          $status={importReference.status as ImportStatus}
          onClick={openDrawer(importReference.id)}
          key={importReference.id}
        >
          <div>{i + 1}</div>
          <div>{snakeCaseToTitleCase(importReference.status)}</div>
          <div>{formatDateTime(importReference.createdAt)}</div>
        </ImportListItem>
      ))}
    </Imports>
  );
};
