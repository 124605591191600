import type { Product, GameRound, TaxonomyChangeReport, TaxonomyRating, GameLeader } from "./types";

import { makeCopilotRequest } from "./common";

export const getDisapprovals = async (credentials: string, gameRoundID?: string): Promise<Product[] | undefined> => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  const response = await makeCopilotRequest(`${endpointPrefix}Disapprovals`, "GET", credentials);
  return response as Product[] | undefined;
};

export const updateProductTaxonomy = async (
  credentials: string,
  productId: string,
  taxonomy: string,
  gameRoundID?: string
) => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  return await makeCopilotRequest(`${endpointPrefix}UpdateTaxonomy`, "PUT", credentials, { productId, taxonomy });
};

export const approveProductTaxonomy = async (credentials: string, productId: string, gameRoundID?: string) => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  return await makeCopilotRequest(`${endpointPrefix}ApproveTaxonomy`, "PUT", credentials, { productId });
};

export const getTaxonomyReport = async (credentials: string, gameRoundID?: string): Promise<TaxonomyChangeReport[]> => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  const response = await makeCopilotRequest(`${endpointPrefix}TaxonomyReport`, "GET", credentials);
  return response as TaxonomyChangeReport[];
};

export const rateProductTaxonomy = async (
  credentials: string,
  productID: string,
  rating: TaxonomyRating,
  gameRoundID?: string
) => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  await makeCopilotRequest(`${endpointPrefix}RateProductTaxonomy?id=${productID}&score=${rating}`, "PUT", credentials);
};

export const getGameRounds = async (credentials: string): Promise<GameRound[]> => {
  const response = await makeCopilotRequest("GameRounds", "GET", credentials);
  return response as GameRound[];
};

export const getCurrentGameRound = async (credentials: string): Promise<GameRound> => {
  const response = await makeCopilotRequest("GameRound", "GET", credentials);
  return response as GameRound;
};

export const getRandomProduct = async (credentials: string, gameRoundID?: string): Promise<Product> => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  const response = await makeCopilotRequest(`${endpointPrefix}RandomProduct`, "GET", credentials);
  return response as Product;
};

export const getTopleaders = async (credentials: string, gameRoundID?: string): Promise<GameLeader[]> => {
  const endpointPrefix = gameRoundID ? `GameRound/${gameRoundID}/` : "";
  const response = await makeCopilotRequest(`${endpointPrefix}LeaderBoard`, "GET", credentials);
  return response as GameLeader[];
};
