import type { Category as CategoryResponse } from "api/cms/types";
import type { Category } from "../cms.types";

import { useEffect } from "react";
import { useLogin } from "useLogin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "components/Table";
import { HomeButton } from "components/HomeButton";
import { TableContainer } from "../cms.styled";

import { camelCaseToTitleCase } from "stringFormatters";
import { getCategories } from "api/cms/categories";

const mapCategoryData = (response: CategoryResponse): Category => ({
  id: response.id,
  name: response.name,
  taxonomyName: response.taxonomy.name,
  taxonomyID: response.taxonomy.id,
  emissionFactor: response.emissionFactor,
  source: response.source,
  sourceID: response.sourceID,
});

const getCategoriesFromSessionStorage = (): Category[] | undefined => {
  const categories = sessionStorage.getItem("categories");
  if (categories) {
    return JSON.parse(categories);
  }
  return undefined;
};

export const Categories = () => {
  const credential = useLogin();
  const navigate = useNavigate();
  const [categories, setCategories] = useState<Category[] | undefined>(getCategoriesFromSessionStorage());

  useEffect(() => {
    const main = async () => {
      const storedCategories = sessionStorage.getItem("categories");
      if (storedCategories) {
        setCategories(JSON.parse(storedCategories));
      } else {
        const categoriesResponse = await getCategories(credential);
        if (!categoriesResponse || categoriesResponse.length === 0) {
          setCategories([]);
        } else {
          const categoriesMapped = categoriesResponse.map(mapCategoryData);
          setCategories(categoriesMapped);
          sessionStorage.setItem("categories", JSON.stringify(categoriesMapped));
        }
      }
    };
    main();
  }, [credential]);

  const handleNavigate = (category: Record<any, any>) => {
    return () => {
      navigate(`/cms/category/${category.id}`);
    };
  };

  return (
    <TableContainer>
      <HomeButton text="CMS Home" navigateTo="/cms" />
      <div>
        <Table data={categories || []} onBodyClick={handleNavigate} headerFormatter={camelCaseToTitleCase} />
      </div>
    </TableContainer>
  );
};
