import { FC } from "react";
import {Button} from "./Button";

const convertToCSV = (data: Record<string, any>[]): string => {
  if (data.length === 0) {
    return "";
  }
  const headers = Object.keys(data[0]);
  const rows = data.map((row) => headers.map((header) => row[header]));
  return [headers, ...rows].map((row) => row.join(";")).join("\n");
};

export type DisplayType = "link" | "button";

export interface DownloadCSVProps {
  data: Record<string, any>[];
  displayType?: DisplayType;
  fileName?: string;
}

export const DownloadCSV: FC<DownloadCSVProps> = ({
  data,
  displayType = "link",
  fileName = "data.csv",
}) => {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const onButtonClick = () => {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  };

  return displayType === "link" ? (
      <a href={url} download={`${fileName}.csv`}>
        Download CSV
      </a>
    ) : (
      <Button
        $type="primary"
        $size="small"
        onClick={onButtonClick}>
          Download CSV
      </Button>
    );
};
