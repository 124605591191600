import type { CreateImportResponse, GetImportResponse, GetImportsResponse } from "./types";

import { makeCopilotRequest } from "./common";

export const createImport = async (
  credentials: string,
  organizationId: string,
  payload: string
): Promise<CreateImportResponse> => {
  const resp = await makeCopilotRequest("Import/Create", "POST", credentials, {
    organizationId,
    payload,
  });
  return resp as CreateImportResponse;
};

export const getImport = async (credentials: string, importId: string): Promise<GetImportResponse> => {
  const response = await makeCopilotRequest(`Import/${importId}`, "GET", credentials);
  return response as GetImportResponse;
};

export const getImports = async (credentials: string, organizationId: string): Promise<GetImportsResponse> => {
  const response = await makeCopilotRequest(`Import/Organization/${organizationId}`, "GET", credentials);
  return response as GetImportsResponse;
};
