import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Game } from "Game/Game";
import { Landing } from "Landing";
import { LoginProvider } from "LoginProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getConfig } from "config";
import { ProductDetailPage } from "ProductDetailPage";
import { Disapprovals } from "Disapprovals";
import { Products } from "ProductsLookup";

import { Report } from "TaxonomyReport";
import { CoverageOverview } from "CoverageOverview";
import PenguinInTheLoop from "PenguinInTheLoop";
import Taxonomies, { TaxonomyDetails } from "features/cms/Taxonomies";
import Categories, { CategoryDetails } from "features/cms/Categories";
import { MaterialCompositionDetails } from "features/cms/MaterialComposition";
import CMS from "features/cms";
import Coverage from "features/cms/Coverage";
import Invoicing from "features/invoicing";
import AllOrganizations from "features/organizations/all";
import Organizations from "features/organizations/demo";
import ProductOverview, { ProductDetails } from "./features/cms/Product";
import SectorialScopes from "features/SectorialScopes";
import OrganizationDetails from "features/organizations/details";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/penguin-in-the-loop",
    element: <PenguinInTheLoop />,
  },
  {
    path: "/penguin-in-the-loop/:gameRoundId",
    element: <Game />,
  },
  {
    path: "/product/:productId",
    element: <ProductDetailPage />,
  },
  {
    path: "/disapprovals",
    element: <Disapprovals />,
  },
  {
    path: "/taxonomyReport",
    element: <Report />,
  },
  {
    path: "/productsLookup",
    element: <Products />,
  },
  {
    path: "/coverage",
    element: <CoverageOverview />,
  },
  {
    path: "/organizations",
    element: <Organizations />,
  },
  {
    path: "/organizations/:organizationId",
    element: <OrganizationDetails />,
  },
  {
    path: "/allOrganizations",
    element: <AllOrganizations />,
  },
  {
    path: "/invoicing",
    element: <Invoicing />,
  },
  {
    path: "/sectorialScopes",
    element: <SectorialScopes />,
  },
  {
    path: "/cms",
    element: <CMS />,
  },
  {
    path: "/cms/taxonomies",
    element: <Taxonomies />,
  },
  {
    path: "/cms/taxonomies/:taxonomyID",
    element: <TaxonomyDetails />,
  },
  {
    path: "/cms/categories",
    element: <Categories />,
  },
  {
    path: "/cms/category/:categoryID",
    element: <CategoryDetails />,
  },
  {
    path: "/cms/materialComposition/:materialCompositionID",
    element: <MaterialCompositionDetails />,
  },
  {
    path: "/cms/coverage",
    element: <Coverage />,
  },
  {
    path: "/cms/customerProducts",
    element: <ProductOverview />,
  },
  {
    path: "/cms/product",
    element: <ProductDetails />,
  },
]);

function App() {
  const { OAUTH_CLIENT_ID } = getConfig();

  return (
    <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
      <LoginProvider>
        <RouterProvider router={router} />
      </LoginProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
