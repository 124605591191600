import type { Category } from "./types";

import { makeCopilotRequest } from "api/common";

export const getCategories = async (credentials: string, taxonomyID?: string, limit?: number): Promise<Category[]> => {
  const opts: Record<string, any> = {};
  if (limit) {
    opts.limit = limit;
  }
  if (taxonomyID) {
    opts.taxonomyID = taxonomyID;
  }
  const params = new URLSearchParams(opts).toString();
  const response = await makeCopilotRequest(`cms/Categories?${params}`, "GET", credentials);
  return response as Category[];
};

export const getCategory = async (credentials: string, categoryID: string): Promise<Category> => {
  const response = await makeCopilotRequest(`cms/Categories/${categoryID}`, "GET", credentials);
  return response as Category;
};
